import { Avatar, Card, CardBody, Chip } from "@heroui/react";

import Text from '@/lib/ui/text.tsx';
import useToggle from '@/hooks/useToggle.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import HeaderPage from '@/app/app/_shared/components/HeaderPage.tsx';
import AppContainer from '@/app/app/_shared/components/AppContainer.tsx';
import { useGetCoachById } from '@/app/app/coaches/store/query/coaches.query.tsx';
import { useParams } from 'react-router-dom';
import Loading from '@/lib/ui/loading.tsx';
import { Table } from '@/lib/ui/table.tsx';
import ShortId from '@/lib/ui/short-id.tsx';
import { SessionFragmentFragment } from '@/__generated__/graphql.ts';
import NewOrEditCoach from '@/app/app/coaches/components/NewOrEditCoach';
import usePagination from '@/hooks/usePagination.ts';
import { useEffect } from 'react';
import DateCell from '@/lib/ui/date-cell.tsx';
import useGetDurationInDays from '@/hooks/useGetDurationInDays.ts';
import useSessionStatus from '@/hooks/useSessionStatus.ts';

export default function CoachDetailPage() {
  const { t } = useTranslation();
  const { getDurationInTwoDates } = useGetDurationInDays();
  const { id } = useParams<{ id: string }>();
  const getStatusResult = useSessionStatus();

  const { serverPagination, frontPagination, setTotalCount } = usePagination();
  const { coach, sessions, totalSessionCount, loadingCoach, loadingSession } =
    useGetCoachById({
      coachId: id as string,
      ...serverPagination
    });

  const [isOpen, actionOpen] = useToggle();

  useEffect(() => {
    if (!loadingSession) {
      setTotalCount(totalSessionCount);
    }
  }, [totalSessionCount, setTotalCount, loadingSession]);

  return (
    <AppContainer>
      <HeaderPage
        title={t('coach.title_details')}
        primaryAction={{
          text: t('coach.edit_coach'),
          onClick: actionOpen.onVisible
        }}
      />

      {loadingCoach ? (
        <div className="text-center mt-5">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col gap-10">
          <Card>
            <CardBody className="flex-row justify-between items-center gap-5 flex-wrap p-10">
              <div className="flex items-center gap-5">
                <Avatar
                  src={coach?.avatar?.url}
                  name={coach?.fullName ?? ''}
                  className="w-24 h-24 text-2xl"
                />
                <div className="flex gap-2 justify-between items-center">
                  <Text className="font-bold text-2xl">{coach?.fullName}</Text>
                  <Chip size="sm" radius="sm" color="success" variant="flat">
                    {coach?.status}
                  </Chip>
                </div>
              </div>

              <ul className="flex flex-col gap-4">
                <li>
                  <strong>{t('form.first_name')}</strong>: {coach?.firstName}
                </li>
                <li>
                  <strong>{t('form.last_name')}</strong>: {coach?.lastName}
                </li>
                <li>
                  <strong>{t('form.email')}</strong>: {coach?.email}
                </li>
              </ul>
            </CardBody>
          </Card>

          <div className="pb-52">
            <Text className="text-3xl font-bold mb-5">
              {t('sessions.title')}
            </Text>
            <Table<SessionFragmentFragment>
              rows={sessions}
              isLoading={loadingSession}
              pagination={frontPagination}
              columns={[
                {
                  field: 'index',
                  headerName: 'No.',
                  renderCell({ rowIndex }) {
                    return serverPagination?.skip + rowIndex + 1;
                  }
                },
                {
                  field: 'id',
                  headerName: 'ID',
                  renderCell({ row }) {
                    return <ShortId>{row?.id}</ShortId>;
                  }
                },
                {
                  field: 'name',
                  headerName: t('name')
                },
                {
                  field: 'limitQuote',
                  headerName: t('form.limitQuote')
                },
                {
                  field: 'subscriptionsCount',
                  headerName: t('assistants')
                },
                {
                  field: 'startedAt',
                  headerName: t('date'),
                  renderCell({ row }) {
                    return <DateCell format="datetime" date={row?.startedAt} />;
                  }
                },
                {
                  field: 'finishedAt',
                  headerName: t('duration'),
                  renderCell({ row }) {
                    return getDurationInTwoDates(
                      row?.startedAt,
                      row?.finishedAt
                    );
                  }
                },
                {
                  field: 'status',
                  headerName: t('status'),
                  renderCell({ row }) {
                    const status = getStatusResult.get(row?.status);

                    return (
                      <Chip size="sm" variant="flat" color={status?.color}>
                        {status?.label}
                      </Chip>
                    );
                  }
                }
              ]}
            />
          </div>
        </div>
      )}

      <NewOrEditCoach
        isOpen={isOpen}
        editRecord={coach}
        onClose={actionOpen.onHidden}
      />
    </AppContainer>
  );
}
