import { useForm } from 'react-hook-form';
import Form, { FormItem } from 'reactivity-hook-form';
import Select from '@/lib/ui/select.tsx';
import Modal from '@/lib/ui/modal.tsx';
import { PlusIcon } from 'lucide-react';
import { Button } from "@heroui/react";
import useToggle from '@/hooks/useToggle.ts';
import { useAddOrRemoveBadgeClient } from '@/app/app/clients/store/mutation/clients.mutation.ts';
import { useGetBadges } from '@/app/app/settings/store/query/badge.query.ts';
import { BadgeFragmentFragment } from '@/__generated__/graphql.ts';

const FORM_NAME = 'add-badge-to-client';

type AddBadgeToClientForm = {
  category: string | null;
  badge: string | null;
};

export default function AddBadgeToClient({ clientId }: { clientId: string }) {
  const form = useForm<AddBadgeToClientForm>({
    defaultValues: {
      badge: null,
      category: null
    }
  });
  const [isOpen, actionOpen] = useToggle();
  const { onUpdateBadgeClient, loading } = useAddOrRemoveBadgeClient(clientId);
  const { data, loading: bLoading } = useGetBadges();

  const options =
    data?.badges?.map?.((_item) => {
      const item = _item as BadgeFragmentFragment;
      return {
        label: item.name,
        value: item.id,
        items: item?.items
      };
    }) || [];

  const category = form.watch('category');
  const findOption = options.find((item) => item.value === category);

  const onClose = () => {
    form.reset();
    actionOpen.onHidden();
  };

  const onSubmit = async (values: AddBadgeToClientForm) => {
    const { badge } = values;
    await onUpdateBadgeClient({
      badges: {
        connect: {
          id: badge
        }
      }
    });
    onClose();
  };

  return (
    <>
      <Button
        size="sm"
        variant="light"
        className="px-1"
        onClick={actionOpen.onVisible}
        startContent={<PlusIcon size={18} />}
      >
        Add Badge
      </Button>

      <Modal
        title="Add Badge"
        isOpen={isOpen}
        onClose={onClose}
        actions={[
          {
            label: 'Close',
            disabled: loading,
            onClick: onClose
          },
          {
            label: 'Add',
            type: 'submit',
            color: 'primary',
            form: FORM_NAME,
            isLoading: loading
          }
        ]}
      >
        <Form<AddBadgeToClientForm>
          id={FORM_NAME}
          formContext={form}
          onSubmit={onSubmit}
          validations={{
            badge: {
              required: 'Badge is required'
            }
          }}
          dependencies={[
            {
              dependencies: ['category'],
              callback(values) {
                const category = values?.category;
                if (!category) {
                  form.setValue('badge', null);
                }
              }
            }
          ]}
        >
          <FormItem name="category">
            <Select isLoading={bLoading} label="Category" options={options} />
          </FormItem>

          <FormItem name="badge">
            <Select
              label="Badge"
              isLoading={bLoading}
              helperText={!findOption ? 'Select a category first' : undefined}
              options={
                findOption?.items?.map?.((item) => ({
                  label: item.name,
                  value: item.id,
                  startAdornment: item.icon
                })) || []
              }
            />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
}
