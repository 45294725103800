import { cn, formatMoney } from '@/lib/utils.ts';
import RadioGroup from '@/app/app/clients/components/CreditsCard/RadioGroup.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { RadioGroupProps } from '@heroui/react';
import { useGetPlansQuery } from '@/app/auth/_store/query/plan.query.ts';
import Text from '@/lib/ui/text.tsx';
import Loading from '@/lib/ui/loading.tsx';

export default function PlanRadio(props: Omit<RadioGroupProps, 'children'>) {
  const { t } = useTranslation();
  const { data, isLoading } = useGetPlansQuery();

  if (isLoading) return <Loading />;

  return (
    <RadioGroup
      {...props}
      className={cn('flex-row', props.className)}
      classNames={{
        wrapper: 'flex flex-row gap-2',
      }}
      items={(data || [])
        ?.filter?.((item) => !!item?.default_price)
        .sort(
          (a, b) =>
            Number(a?.default_price?.unit_amount || 0) -
            Number(b?.default_price?.unit_amount || 0)
        )
        ?.map?.((plan) => ({
          description: [
            t(`plans_names.${plan.name}` as LanguageKeys),
            ' - ',
            formatMoney(Number(plan?.default_price?.unit_amount || 0) / 100)
            // plan?.[`price_desc_${lang}`]
          ].join(''),
          label: (
            <div className="flex flex-col gap-2">
              {plan?.description && (
                <Text className="font-bold">
                  {t(
                    `${plan?.description.replace('.', '')}` as LanguageKeys
                  )}
                </Text>
              )}

              <div className="flex flex-col gap-2 mt-3">
                {(plan?.marketing_features || []).map((item) => (
                  <Text className="text-sm">
                    * {t(`plans_features.${item?.name}` as LanguageKeys)}
                  </Text>
                ))}
              </div>
            </div>
          ),
          value: plan.default_price?.id as string
        }))}
    />
  );
}
