import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';

import { Avatar } from "@heroui/react";
import { useAuth } from '../../../../../context/AuthContext';
import {
  ALL_ACTIVITY_QUERY,
  GET_UPCOMING_RENEWALS_QUERY,
  GET_VALID_RENEWALS_QUERY,
  TODAY_SESSIONS_QUERY,
  TOTAL_CUSTOMER_QUERY,
  TOTAL_REVENUE_QUERY
} from '@/app/app/dashboard/store/query/graphql/dashboard.graphql.ts';
import { useMemo } from 'react';
import { PieChartType } from '@/lib/ui/charts/pie-chart.tsx';
import { PaymentMethodEnum } from '@/app/app/clients/components/PaymentMethodSelect/PaymentMethodSelect.tsx';
import { AreaChartItemProps } from '@/lib/ui/charts/area-chart.tsx';
import {
  CoachFragmentFragment,
  GetUpcomingRenewalsQuery,
  UserFragmentFragment
} from '@/__generated__/graphql.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { useDashboardFilters } from '@/app/app/dashboard/context/filters.ts';
import { LinearChartProps } from '@/lib/ui/charts/linear-chart.tsx';
import { stringToHexColor } from '@/utils/colors.ts';
import { useGetCoachForSelect } from '@/app/app/coaches/store/query/coaches.query.tsx';

export function useGetTodaySessionsQuery() {
  const { activeSpace } = useAuth();
  const filters = useDashboardFilters();

  const query = useQuery(TODAY_SESSIONS_QUERY, {
    skip: !activeSpace?.space?.id,
    variables: {
      day: filters.now.toISOString(),
      spaceId: activeSpace?.space?.id as string
    }
  });

  return {
    ...query,
    fetching: query?.loading,
    loading: query?.loading && !query?.data
  };
}

export function useGetTotalCustomers() {
  const { activeSpace } = useAuth();
  const filters = useDashboardFilters();

  const query = useQuery(TOTAL_CUSTOMER_QUERY, {
    skip: !activeSpace?.space?.id,
    variables: {
      data: {
        spaceId: activeSpace?.space?.id as string,
        startDate: filters.startDate.toISOString(),
        endDate: filters.endDate.toISOString()
      }
    }
  });

  return {
    ...query,
    data: query?.data?.reportTotalClients,
    fetching: query?.loading,
    loading: query?.loading && !query?.data
  };
}

export function useGetTotalRevenue() {
  const { activeSpace } = useAuth();
  const filters = useDashboardFilters();

  const query = useQuery(TOTAL_REVENUE_QUERY, {
    skip: !activeSpace?.space?.id,
    variables: {
      data: {
        spaceId: activeSpace?.space?.id as string,
        startDate: filters.startDate.toISOString(),
        endDate: filters.endDate.toISOString()
      }
    }
  });

  return {
    ...query,
    data: query?.data?.reportTotalRevenue,
    fetching: query?.loading,
    loading: query?.loading && !query?.data
  };
}

const colors: Record<PaymentMethodEnum, string> = {
  [PaymentMethodEnum.CASH]: '#5AD1A5',
  [PaymentMethodEnum.BITCOIN]: '#FFC700',
  [PaymentMethodEnum.STRIPE]: '#7B95F1',
  [PaymentMethodEnum.TARGET]: '#FF495C',
  [PaymentMethodEnum.TRANSFER]: '#4D96FF',
  [PaymentMethodEnum.PAYMENT_LINK]: '#FF6B6B'
};

export function useGetPaymentMethod() {
  const query = useGetTotalRevenue();
  const { t } = useTranslation();

  const options = useMemo(() => {
    if (query?.data?.currentPayments) {
      const records: PieChartType['data'] = [
        {
          key: PaymentMethodEnum.BITCOIN,
          title: t(
            `methods_payment.${PaymentMethodEnum.BITCOIN}` as LanguageKeys
          ),
          color: colors[PaymentMethodEnum.BITCOIN],
          value: 0
        },
        {
          key: PaymentMethodEnum.TARGET,
          title: t(
            `methods_payment.${PaymentMethodEnum.TARGET}` as LanguageKeys
          ),
          color: colors[PaymentMethodEnum.TARGET],
          value: 0
        },
        {
          key: PaymentMethodEnum.TRANSFER,
          title: t(
            `methods_payment.${PaymentMethodEnum.TRANSFER}` as LanguageKeys
          ),
          color: colors[PaymentMethodEnum.TRANSFER],
          value: 0
        },
        {
          key: PaymentMethodEnum.STRIPE,
          title: t(
            `methods_payment.${PaymentMethodEnum.STRIPE}` as LanguageKeys
          ),
          color: colors[PaymentMethodEnum.STRIPE],
          value: 0
        },
        {
          key: PaymentMethodEnum.PAYMENT_LINK,
          title: t(
            `methods_payment.${PaymentMethodEnum.PAYMENT_LINK}` as LanguageKeys
          ),
          color: colors[PaymentMethodEnum.PAYMENT_LINK],
          value: 0
        },
        {
          key: PaymentMethodEnum.CASH,
          title: t(`methods_payment.${PaymentMethodEnum.CASH}` as LanguageKeys),
          color: colors[PaymentMethodEnum.CASH],
          value: 0
        }
      ];

      query.data.currentPayments.forEach((payment) => {
        const findIndex = records.findIndex(
          (item) => item.key === payment.method
        );
        if (findIndex !== -1) {
          records[findIndex].value += Number(payment?.amount || 0) || 0;
        } else {
          records.push({
            key: payment.method as PaymentMethodEnum,
            color: colors[payment.method as PaymentMethodEnum],
            title: t(
              `methods_payment.${payment.method}` as LanguageKeys
            ) as string,
            value: Number(payment?.amount || 0) || 0
          });
        }
      });

      return records;
    }

    return [];
  }, [t, query?.data]);

  return {
    ...query,
    options
  };
}

const palette = [
  '#FF495C',
  '#FF6B6B',
  '#5AD1A5',
  '#4D96FF',
  '#7B95F1',
  '#845EC2',
  '#FF9671',
  '#FFC700'
];

export function useGetPlans() {
  const query = useGetTotalRevenue();
  const { t } = useTranslation();

  const options = useMemo(() => {
    if (query?.data?.currentPayments) {
      const records: PieChartType['data'] = [];

      query.data.currentPayments.forEach((payment) => {
        const plan = payment.credit?.plan;
        if (!plan) return;

        const findIndex = records.findIndex((item) => item.key === plan.id);

        if (findIndex !== -1) {
          records[findIndex].value += 1;
        } else if (payment.credit?.id) {
          const planName = plan?.name || t('no_name');

          records.push({
            key: plan.id,
            color: stringToHexColor(planName, palette),
            title: planName,
            value: 1
          });
        }
      });

      return records;
    }

    return [];
  }, [t, query?.data]);

  return {
    ...query,
    options
  };
}

export function useGetActivity() {
  const filters = useDashboardFilters();
  const { activeSpace } = useAuth();

  const query = useQuery(ALL_ACTIVITY_QUERY, {
    skip: !activeSpace?.space?.id,
    variables: {
      date: filters.startDate.toISOString(),
      spaceId: activeSpace?.space?.id as string
    }
  });

  const activity = useMemo(() => {
    if (!query?.data?.space) return [];
    return query?.data?.space?.subscriptions?.reduce<Array<AreaChartItemProps>>(
      (acc: AreaChartItemProps[], credit) => {
        const date = dayjs(credit?.sessionForSubscribe?.startedAt || '');
        if (!credit || !date.isValid()) return acc;

        const createdAt = date.format('YYYY-MM-DD');
        const findIndex = acc.findIndex((item) => item.time === createdAt);

        if (findIndex !== -1) {
          acc[findIndex].value += 1;
        } else {
          acc.push({
            value: 1,
            time: createdAt,
            date: date.toISOString()
          });
        }
        return acc;
      },
      []
    );
  }, [query?.data]);

  return {
    ...query,
    activity,
    fetching: query?.loading,
    loading: query?.loading && !query?.data
  };
}

export function useGetActivityByWeek() {
  const query = useGetActivity();
  const { lang } = useTranslation();

  const activity = useMemo<Pick<LinearChartProps, 'dataKeys' | 'data'>>(() => {
    if (!query?.data?.space?.subscriptions)
      return {
        data: [],
        dataKeys: []
      };

    const DAYS_OF_WEEK: Record<string, dayjs.Dayjs> = {
      '0': dayjs().locale(lang).day(1),
      '1': dayjs().locale(lang).day(2),
      '2': dayjs().locale(lang).day(3),
      '3': dayjs().locale(lang).day(4),
      '4': dayjs().locale(lang).day(5),
      '5': dayjs().locale(lang).day(6),
      '6': dayjs().locale(lang).day(0)
    };

    const activityByDay = query?.data?.space?.subscriptions?.reduce<{
      [key: string]: number;
    }>(
      (acc, credit) => {
        const date = dayjs(credit?.sessionForSubscribe?.startedAt || '');
        if (!credit || !date.isValid()) return acc;

        const getIndexWeek = date.day() - 1;

        acc[getIndexWeek.toString()] += 1;
        return acc;
      },
      {
        '0': 0,
        '1': 0,
        '2': 0,
        '3': 0,
        '4': 0,
        '5': 0,
        '6': 0
      }
    );

    return {
      data: Object.entries(activityByDay || {}).map(([key, ac]) => ({
        key,
        value: ac,
        label: DAYS_OF_WEEK[key].format('dd'),
        labelTooltip: DAYS_OF_WEEK[key].format('dddd')
      })) as LinearChartProps['data'],
      dataKeys: [
        {
          key: 'value',
          color: '#7B95F1'
        }
      ] as LinearChartProps['dataKeys']
    };
  }, [lang, query?.data?.space?.subscriptions]);

  return {
    ...query,
    activity,
    fetching: query?.loading,
    loading: query?.loading && !query?.data
  };
}

export function useGetActivityByCoach() {
  const query = useGetActivity();
  const { t } = useTranslation();

  const queryCoach = useGetCoachForSelect();

  const activity = useMemo<Pick<LinearChartProps, 'dataKeys' | 'data'>>(() => {
    if (!query?.data?.space)
      return {
        data: [],
        dataKeys: []
      };

    const coaches: Record<string, number> = {};
    const dataKeys: LinearChartProps['dataKeys'] = [];

    const data = query?.data?.space?.subscriptions?.reduce<
      LinearChartProps['data']
    >((acc: LinearChartProps['data'], credit, index) => {
      if (!credit || !credit.sessionForSubscribe?.coach) {
        return acc;
      }

      const coach = credit.sessionForSubscribe.coach;
      const findIndex = coaches[coach.id];

      if (findIndex !== undefined) {
        acc[findIndex][coach.id] += 1;
      } else {
        coaches[coach?.id] = index;
        dataKeys.push({
          key: coach?.id,
          color: stringToHexColor(coach.fullName || '', palette),
          startContent: (
            <Avatar src={coach?.avatar?.url} name={coach?.fullName as string} />
          )
        });

        acc.push({
          [coach?.id]: 1,
          label: coach?.fullName || t('no_name')
        });
      }
      return acc;
    }, []);

    (queryCoach?.data?.coaches || []).forEach((_coachItem) => {
      const coach = _coachItem as CoachFragmentFragment;

      const findCoach = coaches?.[coach?.id];

      if (findCoach === undefined) {
        /* NOT PUSH Inactive User  */
        // dataKeys?.push?.({
        //   key: coach?.id,
        //   color: stringToHexColor(coach.fullName || '', palette),
        //   startContent: (
        //     <Avatar src={coach?.avatar?.url} name={coach?.fullName as string} />
        //   )
        // });
        data?.push?.({
          [coach?.id]: 0,
          label: coach?.fullName || t('no_name')
        });
      }
    });

    return {
      data: data || [],
      dataKeys
    };
  }, [query?.data?.space, queryCoach?.data?.coaches, t]);


  console.log(activity);
  return {
    ...query,
    activity,
    fetching: query?.loading,
    loading: query?.loading && !query?.data
  };
}

type CreditsType = DeepNonNullable<GetUpcomingRenewalsQuery>;

export function useGetUpcomingRenewals() {
  const { activeSpace } = useAuth();
  const filters = useDashboardFilters();

  const queryValid = useQuery(GET_VALID_RENEWALS_QUERY, {
    skip: !activeSpace?.space?.id,
    variables: {
      spaceId: activeSpace?.space?.id as string
    }
  });

  const query = useQuery(GET_UPCOMING_RENEWALS_QUERY, {
    skip: !activeSpace?.space?.id,
    variables: {
      spaceId: activeSpace?.space?.id as string
    }
  });

  const renewals = useMemo(() => {
    if (!query?.data?.space) return [];
    return query?.data?.space?.credits?.reduce<CreditsType['space']['credits']>(
      (acc, credit) => {
        const validIds =
          queryValid?.data?.space?.credits?.map?.((i) => i?.user?.id) || [];

        if (!validIds.includes((credit.user as UserFragmentFragment)?.id)) {
          return acc;
        }

        if (dayjs(credit?.expiredAt).diff(filters.now, 'day') > 15) {
          return acc;
        }

        const findIndex = acc?.findIndex?.(
          (item) => item.user?.id === (credit.user as UserFragmentFragment)?.id
        );

        if (findIndex === -1) {
          acc.push(credit as CreditsType['space']['credits'][0]);
        }
        return acc;
      },
      []
    );
  }, [filters.now, query?.data?.space, queryValid?.data?.space?.credits]);

  return {
    ...query,
    renewals,
    fetching: query?.loading,
    loading: query?.loading && !query?.data
  };
}
