import { useAuth } from '../../../../../context/AuthContext';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { useTheme } from '@/context/theme-provider.tsx';
import { Button, Image } from "@heroui/react";
import { QrCode } from 'lucide-react';
import Modal from '@/lib/ui/modal.tsx';
import useToggle from '@/hooks/useToggle.ts';
import { API_URL } from '@/config/env.ts';

export default function SharedSpace() {
  const { lang, t } = useTranslation();
  const { theme } = useTheme();
  const { activeSpace, user } = useAuth();
  const [isOpen, actionOpen] = useToggle();

  return (
    <>
      <Button
        isIconOnly
        size="sm"
        variant="light"
        onClick={actionOpen.onVisible}
        startContent={<QrCode size={18} />}
      />

      <Modal title={t('shared_space')} isOpen={isOpen} onClose={actionOpen.onHidden}>
        <div className="mt-10">
          <Image
            width="100%"
            height={500}
            className="object-contain"
            src={new URL(
              `space/${activeSpace?.space?.id}/shared?lang=${lang}&theme=${theme}&token=${user?.sessionToken}`,
              API_URL
            ).toString()}
          />
        </div>
      </Modal>
    </>
  );
}
