import { Avatar } from "@heroui/react";
import { useAuth } from '../../../../context/AuthContext';
import { cn } from '@/lib/utils.ts';

export default function AvatarSpace({
  className,
  ...props
}: {
  className?: string;
}) {
  const { activeSpace } = useAuth();

  return (
    <Avatar
      className={cn('h-[35px] w-[35px]', className)}
      {...props}
      src={activeSpace?.space?.thumbnail?.url}
      name={activeSpace?.space?.name as string}
    />
  );
}
