import { useNavigate } from 'react-router-dom';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@heroui/react";

import { useTranslation } from '@/hooks/useTranslation';
import Select, { SelectSingleProps } from '@/lib/ui/select';
import { useGetCoachForSelect } from '@/app/app/coaches/store/query/coaches.query';
import Text from '@/lib/ui/text.tsx';
import { COACHES_ROUTES } from '@/app/app/coaches/common/routes.ts';
import { useCreateOrEditSession } from '@/app/app/sessions/components/create-edit-session/context';

export default function SelectCoach(props: Partial<SelectSingleProps>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { options, loading } = useGetCoachForSelect();
  const { onClose } = useCreateOrEditSession();

  if (!loading && !options.length) {
    props?.onChange?.('');
    return (
      <Popover showArrow offset={10} placement="bottom">
        <PopoverTrigger>
          <div>
            <Select
              {...props}
              options={options}
              className="pointer-events-none"
              onClick={(e) => e.stopPropagation()}
              placeholder={t('form_placeholder.coach')}
            />
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-[240px]">
          <div className="px-1 py-2">
            <Text className="font-bold text-md">{t('couches_not_found')}</Text>
            <Text>{t('couches_not_found_desc')}</Text>
            <div className="flex justify-end mt-5">
              <Button
                color="primary"
                onClick={() => {
                  navigate(COACHES_ROUTES.HOME);
                  onClose();
                }}
              >
                {t('coach.new')}
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Select
      {...props}
      options={options}
      placeholder={t('form_placeholder.coach')}
    />
  );
}
