import { FormItem } from 'reactivity-hook-form';
import { CreateNotificationsInput } from '@/__generated__/graphql.ts';
import { Textarea } from '@/lib/ui/textarea.tsx';
import Input from '@/lib/ui/input.tsx';
import { Checkbox } from "@heroui/react";
import UserList from '@/app/app/notifications/components/user-list';
import { useTranslation } from '@/hooks/useTranslation.ts';

export default function NewNotification() {
  const { t } = useTranslation();

  return (
    <>
      <FormItem<CreateNotificationsInput> name="title">
        <Input label={t('notifications.title')} />
      </FormItem>
      <FormItem<CreateNotificationsInput> name="body">
        <Textarea label={t('notifications.message')} />
      </FormItem>
      <FormItem<CreateNotificationsInput> name="imageUrl">
        <Textarea label={t('notifications.image_form')} />
      </FormItem>
      <FormItem<CreateNotificationsInput>>
        {({ watch, setValue }) => (
          <Checkbox
            isSelected={watch('sendToEmail')}
            onChange={(event) =>
              setValue('sendToEmail', event?.target?.checked, {
                shouldValidate: true
              })
            }
          >
            <span className="text-sm">{t('notifications.send_to_email')}</span>
          </Checkbox>
        )}
      </FormItem>

      <UserList />
    </>
  );
}
