import Title from '@/lib/ui/title.tsx';
import { Table } from '@/lib/ui/table.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { formatDateComplete, formatMoney } from '@/lib/utils.ts';
import StatusColor from '@/lib/ui/status-color.tsx';
import Text from '@/lib/ui/text.tsx';
import { InvoiceResponseDto } from '@jogolabs/billing-sdk';
import { useRetryPayment } from '@/app/app/billing/store/mutation/billing.mutation.ts';
import { useBilling } from '@/app/app/billing/context/billing.context.tsx';
import { RotateCcw, SquareArrowOutUpRight } from 'lucide-react';

const STATUS_COLORS: Record<InvoiceResponseDto.status, ColorStatus> = {
  [InvoiceResponseDto.status.PAID]: 'success',
  [InvoiceResponseDto.status.OPEN]: 'warning',
  [InvoiceResponseDto.status.DRAFT]: 'warning',
  [InvoiceResponseDto.status.UNCOLLECTIBLE]: 'danger',
  [InvoiceResponseDto.status.VOID]: 'danger'
};

export default function PaymentHistory() {
  const { t } = useTranslation();
  const { paymentMethod, invoices } = useBilling();
  const retryPayment = useRetryPayment();

  return (
    <section className="mt-5 flex flex-col gap-5">
      <Title size="md">{t('billing_history')}</Title>
      <Table
        // hideHeader
        rows={invoices?.data || []}
        isLoading={invoices?.isLoading}
        columns={[
          {
            field: 'date',
            headerName: t('date'),
            renderCell({ row }) {
              return (
                <div className="w-[150px]">{formatDateComplete(row?.date)}</div>
              );
            }
          },
          {
            field: 'product',
            headerName: t('interval_time'),
            renderCell({ row }) {
              return (
                row?.products
                  ?.map?.((item) =>
                    t(`plans_names.${item.name}` as LanguageKeys)
                  )
                  ?.join?.(', ') || '-'
              );
            }
          },
          // {
          //   field: 'reason',
          //   headerName: t('reason'),
          //   renderCell({ row }) {
          //     return row?.reason || '-';
          //   }
          // },
          {
            field: 'amount',
            headerName: t('total_price'),
            renderCell({ row }) {
              return formatMoney(Number(row.amount) / 100);
            }
          },
          {
            field: 'status',
            headerName: t('status'),
            renderCell({ row }) {
              return (
                <StatusColor
                  severity={
                    STATUS_COLORS[row?.status as InvoiceResponseDto.status]
                  }
                  label={t(
                    (row?.status
                      ? `plans_status.${row?.status}`
                      : '-') as LanguageKeys
                  )}
                />
              );
            }
          },
          {
            field: 'action',
            headerName: '',
            renderCell({ row }) {
              if (!row?.hosted_invoice_url) {
                return (
                  <Text className="text-default-500 w-[100px]">
                    {t('no_available')}
                  </Text>
                );
              }

              const isRetryPayment =
                row.status === InvoiceResponseDto.status.OPEN && row?.due_date;

              return (
                <div className="flex gap-5">
                  <a
                    target="_blank"
                    href={row?.hosted_invoice_url}
                    className="cursor-pointer flex gap-1 items-center text-default-500 transition hover:text-primary hover:underline"
                  >
                    {t('view')} <SquareArrowOutUpRight size={14} />
                  </a>

                  {isRetryPayment && (
                    <a
                      onClick={() => {
                        if (!paymentMethod?.data) return null;
                        return retryPayment.mutateAsync({
                          paymentMethodId: paymentMethod.data?.id as string,
                          invoiceId: row.id as string
                        });
                      }}
                      className="cursor-pointer flex gap-1 items-center text-default-500 transition hover:text-primary hover:underline"
                    >
                      {t('retry')} <RotateCcw size={14} />
                    </a>
                  )}
                </div>
              );
            }
          }
        ]}
      />
    </section>
  );
}
