import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { PublicRotes } from '@/routes/public-rotes.ts';
import { PrivateRotes } from '@/routes/private-rotes.ts';
import InitPage from '@/app/page.tsx';
import PrivacyPage from '@/app/privacy';
import DeleteAccountPage from '@/app/delete-account';
import * as app from '@/app/app';
import * as auth from '@/app/auth';
import NotFound from '@/app/app/_shared/components/NotFound.tsx';

export default function Routes() {
  const appRoutes = app.useAppRoutes();

  const routes = createBrowserRouter([
    {
      path: PublicRotes.HOME,
      element: <InitPage />
    },
    {
      path: PublicRotes.PRIVACY,
      element: <PrivacyPage />
    },
    {
      path: PublicRotes.DELETE_ACCOUNT,
      element: <DeleteAccountPage />
    },
    {
      path: PublicRotes.AUTH,
      Component: auth.layout,
      children: auth.routes
    },
    {
      path: PrivateRotes.APP,
      Component: app.layout,
      children: appRoutes
    },
    {
      path: '*',
      element: <NotFound />
    }
  ]);

  return <RouterProvider router={routes} />;
}
