import { toast } from 'sonner';
import { useMutation } from '@apollo/client';
import { useAuth } from '../../../../../context/AuthContext';
import {
  POST_CREATE_PLAN,
  POST_UPDATE_PLAN
} from '@/app/app/settings/store/mutation/graphql/mutations.graphql.ts';
import { PostCreatePlanMutationVariables } from '@/__generated__/graphql.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export type NewPlanPayload = Omit<
  PostCreatePlanMutationVariables,
  'spaceId' | 'categories'
> & { categories: string[] };

export function useCreatePlan() {
  const { activeSpace } = useAuth();
  const { t } = useTranslation();

  const [mutation, actionMutation] = useMutation(POST_CREATE_PLAN, {
    update(cache, { data }) {
      const createPlan = data?.createPlan;
      if (createPlan) {
        cache.modify({
          fields: {
            plans(existingPlans = []) {
              return [...existingPlans, createPlan];
            }
          }
        });
      }
    }
  });

  const onCreatePlan = async (variables: NewPlanPayload) => {
    try {
      await mutation({
        variables: {
          ...variables,
          categories: {
            connect: variables.categories.map((id) => ({ id }))
          },
          points: Number(variables?.points?.toString?.()),
          duration: Number(variables?.duration?.toString?.()),
          spaceId: activeSpace?.space?.id as string
        }
      });
      toast.success(t('alert_successfully'));
    } catch (error) {
      console.error(error);
      toast.error(
        (error as Error)?.message || t('errors_message.unexpected_error')
      );
      throw error;
    }
  };

  return { onCreatePlan, ...actionMutation };
}

export function useUpdatePlan() {
  const { t } = useTranslation();
  const [mutation, actionMutation] = useMutation(POST_UPDATE_PLAN);

  const onUpdatePlan = async (id: string, data: Partial<NewPlanPayload>) => {
    try {
      await mutation({
        variables: {
          id,
          data: {
            name: data?.name,
            price: data?.price,
            categories: data?.categories?.length
              ? {
                  set: data?.categories?.map?.((id) => ({ id }))
                }
              : undefined,
            points: Number(data?.points?.toString?.()),
            duration: Number(data?.duration?.toString?.())
          }
        }
      });
      toast.success(t('alert_successfully'));
    } catch (error) {
      console.error(error);
      toast.error(
        (error as Error)?.message || t('errors_message.unexpected_error')
      );
      throw error;
    }
  };

  return { onUpdatePlan, ...actionMutation };
}
