import { Check, X, MessageSquareText, Smartphone, Clock } from 'lucide-react';
import { TableColumn } from '@/lib/ui/table.tsx';
import {
  DeviceResponseDto,
  NotificationResponseDto
} from '@jogolabs/notification-sdk';
import StatusColor from '@/lib/ui/status-color.tsx';
import Text from '@/lib/ui/text.tsx';
import { Avatar, Image, Tooltip } from "@heroui/react";
import useGetDurationInDays from '@/hooks/useGetDurationInDays.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { cn } from '@/lib/utils.ts';

export default function useColumns(): TableColumn<NotificationResponseDto> {
  const { getDurationInTwoDates } = useGetDurationInDays();
  const { t } = useTranslation();

  return [
    {
      field: '_id',
      headerName: '',
      renderCell({ row }) {
        const status = row?.status;

        return (
          <div className="relative w-fit">
            <MessageSquareText size="20px" className="text-foreground/60" />
            {status === NotificationResponseDto['status'].NEW && (
              <div className="w-[5px] h-[5px] bg-red-500 rounded-2xl absolute top-0 right-0" />
            )}
          </div>
        );
      }
    },
    {
      field: 'body.title',
      headerName: t('notifications.title'),
      renderCell({ row }) {
        return (
          <div>
            <Text className="font-bold text-xs">{row?.body?.title}</Text>
            <Text className="text-xs">{row?.body?.body}</Text>
          </div>
        );
      }
    },
    {
      field: 'body.imageUrl',
      headerName: t('notifications.image'),
      width: 75,
      renderCell({ row }) {
        const image = row?.body?.imageUrl;

        if (!image) return '-';
        return (
          <Tooltip
            content={
              <Image
                width={150}
                height={150}
                className="object-contain"
                src={row?.body?.imageUrl}
              />
            }
          >
            <Image
              width={35}
              height={35}
              className="object-cover"
              src={row?.body?.imageUrl}
            />
          </Tooltip>
        );
      }
    },
    {
      field: 'body.email_options.email',
      headerName: t('notifications.client'),
      renderCell({ row }) {
        return (
          <div className="flex items-center gap-2">
            <Avatar
              size="sm"
              src={row?.body?.data?.user_avatar}
              name={row?.body?.data?.user_fullName}
            />
            <div>
              <Text className="text-xs font-bold">
                {row?.body?.data?.user_fullName}
              </Text>
              <Text className="text-xs text-foreground/60">
                {row?.body?.email_options?.email}
              </Text>
            </div>
          </div>
        );
      }
    },
    {
      field: 'device',
      headerName: t('notifications.devices'),
      renderCell({ row }) {
        if (!row?.devices?.length) return '-';

        const results = row?.devices?.reduce(
          (acc, item) => {
            if (item.success) {
              acc.success.push(item);
            } else {
              acc.failed.push(item);
            }
            return acc;
          },
          {
            success: [] as DeviceResponseDto[],
            failed: [] as DeviceResponseDto[]
          }
        );

        return (
          <div className="flex flex-wrap">
            {!!results?.success?.length && (
              <Tooltip
                content={
                  <div className="flex flex-col gap-1">
                    {results.success.map((device) => (
                      <div key={device._id} className="flex items-center gap-2">
                        <span className="w-[5px] h-[5px] bg-foreground/80 rounded-xl" />

                        <div className="">
                          <Text>{device.device_name}</Text>
                          <Text className="text-xs">
                            <strong>{device.device_platform}</strong>
                          </Text>
                        </div>
                      </div>
                    ))}
                  </div>
                }
              >
                <div className="relative flex items-center">
                  <div
                    className={cn(
                      'absolute top-[-3px] right-[-2px] rounded p-[1px]',
                      {
                        'bg-success':
                          row?.status !==
                          NotificationResponseDto.status.PENDING,
                        'bg-warning-600':
                          row?.status === NotificationResponseDto.status.PENDING
                      }
                    )}
                  >
                    {row?.status === NotificationResponseDto.status.PENDING ? (
                      <Clock
                        size={7}
                        strokeWidth={3}
                        className="text-white"
                      />
                    ) : (
                      <Check className="text-white" size={7} strokeWidth={3} />
                    )}
                  </div>

                  <Smartphone size={18} />
                </div>
              </Tooltip>
            )}

            {!!results?.failed?.length && (
              <Tooltip
                content={
                  <div className="flex flex-col gap-1">
                    {results.failed.map((device) => (
                      <div key={device._id} className="flex items-center gap-2">
                        <span className="w-[5px] h-[5px] bg-foreground/80 rounded-xl" />

                        <div className="">
                          <Text>{device.device_name}</Text>
                          <Text className="text-xs">
                            <strong>{device.device_platform}</strong>
                          </Text>
                        </div>
                      </div>
                    ))}
                  </div>
                }
              >
                <div className="relative flex items-center">
                  <div
                    className={cn(
                      'absolute top-[-3px] right-[-2px] rounded p-[1px]',
                      {
                        'bg-danger':
                          row?.status !==
                          NotificationResponseDto.status.PENDING,
                        'bg-warning-600':
                          row?.status === NotificationResponseDto.status.PENDING
                      }
                    )}
                  >
                    {row?.status === NotificationResponseDto.status.PENDING ? (
                      <Clock size={7} strokeWidth={3} className="text-white" />
                    ) : (
                      <X className="text-white" size={7} strokeWidth={3} />
                    )}
                  </div>

                  <Smartphone size={18} />
                </div>
              </Tooltip>
            )}
          </div>
        );
      }
    },
    {
      field: 'updatedAt',
      headerName: t('notifications.created_ago'),
      renderCell({ row }) {
        return t('ago_date', getDurationInTwoDates(row?.createdAt), {
          date: getDurationInTwoDates(row?.createdAt)
        });
      }
    },
    {
      field: 'body.email_options.lang',
      headerName: t('notifications.template'),
      renderCell({ row }) {
        const lang = row?.body?.email_options?.lang;
        return (
          <StatusColor
            label={lang || '-'}
            severity={lang ? 'success' : 'default'}
          />
        );
      }
    }
  ];
}
