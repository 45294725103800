import { Link, NavLink } from 'react-router-dom';
import { DASHBOARD_ROUTES } from '@/app/app/dashboard/common/routes.ts';
import Container from '@/lib/ui/container.tsx';
import UserMenu from '@/app/app/_shared/components/navbar/user-menu.tsx';
import NavbarItems from '@/app/app/_shared/components/navbar/navbar-items.tsx';
import MobileMenuSvg from '@/app/app/_shared/components/navbar/mobile-menu-svg.tsx';
import { Alert, Button } from '@heroui/react';
import Drawer from '@/lib/ui/Drawer/drawer.tsx';
import useToggle from '@/hooks/useToggle.ts';
import SharedSpace from '@/app/app/_shared/components/navbar/shared-space.tsx';
import Text from '@/lib/ui/text.tsx';
import { TriangleAlert } from 'lucide-react';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { BILLING_ROUTES } from '@/app/app/billing';
import { useAuth } from '@/context/AuthContext.tsx';
import { useMemo } from 'react';
import { StatusSpaceEnum } from '@/app/auth/_store/mutation/graphql/mutation.graphql.ts';
import dayjs from 'dayjs';

export default function Navbar() {
  const [isOpen, toggleOpen] = useToggle();
  const { t } = useTranslation();
  const { stateAlert, activeSpace } = useAuth();

  const alertMessage = useMemo(() => {
    if (activeSpace?.space?.status === StatusSpaceEnum.ACTIVE) return '';
    if (activeSpace?.space?.status === StatusSpaceEnum.SUSPENDED) return t('banner_suspended_desc');
    if (stateAlert?.diffDaysTrial <= 0) {
      return t('trial_banner_end', '', {
        link: BILLING_ROUTES.HOME
      });
    }
    if (activeSpace?.space?.status === StatusSpaceEnum.TRIAL) {
      return t('trial_banner', '', {
        days: dayjs(activeSpace?.space?.trialEndAt).diff(dayjs(), 'days'),
        link: BILLING_ROUTES.HOME
      });
    }

    return '';
  }, [
    activeSpace?.space?.status,
    activeSpace?.space?.trialEndAt,
    stateAlert?.diffDaysTrial,
    t
  ]);

  return (
    <div
      id="navbar"
      className="fixed top-0 z-50 w-full border-b border-border/80 bg-background/85 backdrop-blur supports-[backdrop-filter]:bg-background/60"
    >
      {stateAlert?.open && alertMessage && (
        <Alert
          hideIcon
          isClosable
          color={stateAlert.type}
          onClose={stateAlert.onClose}
          className="py-0"
          title={
            <div className="flex justify-center items-center gap-2">
              <TriangleAlert size={18} />
              <Text
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html: alertMessage
                }}
              />
            </div>
          }
        />
      )}
      <Container className="flex h-16 items-center">
        <div className="mr-4 hidden md:flex gap-5">
          <Link to={DASHBOARD_ROUTES.DASHBOARD}>
            <img src="/icon.png" width={70} alt="jogo.png" />
          </Link>

          <NavbarItems />
        </div>

        <div className="inline-flex md:hidden items-center gap-3">
          <NavLink to={DASHBOARD_ROUTES.DASHBOARD}>
            <img src="/icon.png" width={70} alt="jogo.png" />
          </NavLink>
        </div>

        {/*<div className="flex flex-1 items-center justify-end mr-7 space-x-2 md:mr-0 md:justify-end">*/}
        {/*  <a*/}
        {/*    target="_blank"*/}
        {/*    rel="noreferrer"*/}
        {/*    href="https://twitter.com/shadcn"*/}
        {/*  >*/}
        {/*    <div*/}
        {/*      className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 py-2 w-9 px-0">*/}
        {/*      <svg*/}
        {/*        className="h-3 w-3 fill-current"*/}
        {/*        height="23"*/}
        {/*        viewBox="0 0 1200 1227"*/}
        {/*        width="23"*/}
        {/*        xmlns="http://www.w3.org/2000/svg"*/}
        {/*      >*/}
        {/*        <path*/}
        {/*          d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"></path>*/}
        {/*      </svg>*/}
        {/*      <span className="sr-only">Twitter</span>*/}
        {/*    </div>*/}
        {/*  </a>*/}
        {/*</div>*/}

        <div className="flex flex-1 justify-end md:hidden items-center gap-3">
          <Button
            isIconOnly
            className="inline-flex whitespace-nowrap font-medium focus-visible:outline-none focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 h-9 text-base focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0"
            variant="light"
            aria-haspopup="dialog"
            aria-expanded="false"
            aria-controls="radix-:R96la:"
            data-state="closed"
            onClick={toggleOpen.onVisible}
          >
            <MobileMenuSvg className="h-5 w-5" />
            <span className="sr-only">Toggle Menu</span>
          </Button>
        </div>

        <div className="flex items-center justify-end mr-2 space-x-2 md:flex-1 md:mr-0 md:justify-end">
          <SharedSpace />
          <UserMenu />
        </div>

        <Drawer isOpen={isOpen} onClose={toggleOpen.onHidden}>
          <div className="flex justify-center h-[80px]"></div>
          <NavbarItems className="flex-col" trigger={toggleOpen.onHidden} />
        </Drawer>
      </Container>
    </div>
  );
}
