import { useAuth } from '../../../../../../context/AuthContext';
import { ClassOfferedFragmentFragment } from '@/__generated__/graphql.ts';
import TabContainer from '@/app/app/settings/components/TabContainer';
import ClassCard from '@/app/app/settings/components/Tabs/ClassesOfferedTab/components/ClassCard.tsx';
import Grid from '@/lib/ui/grid';
import { Button } from "@heroui/react";
import { Plus } from 'lucide-react';
import useSelectEditItem from '@/hooks/useSelectEditItem.ts';
import useToggle from '@/hooks/useToggle.ts';
import NewOrUpdateClassModal from '@/app/app/settings/components/Tabs/ClassesOfferedTab/components/NewOrUpdateClassModal.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import EmptyState from '@/lib/ui/empty-state.tsx';

export default function ClassesOfferedTab() {
  const { t } = useTranslation();
  const { activeSpace } = useAuth();
  const [isOpen, openActions] = useToggle();
  const [editItem, onSetEdit] =
    useSelectEditItem<ClassOfferedFragmentFragment>(openActions);

  return (
    <TabContainer
      title={t('settings.class_offered')}
      action={
        <Button
          color="primary"
          endContent={<Plus />}
          onClick={() => onSetEdit(null, true)}
        >
          {t('new')}
        </Button>
      }
    >
      {!activeSpace?.space?.classOffered?.length && (
        <EmptyState className="mt-10" title={t('empty_table')} />
      )}

      <Grid gutter={[20, 20]}>
        {activeSpace?.space?.classOffered?.map?.((item) => (
          <Grid.Item key={item.id} xs={24} sm={12} md={8} lg={6}>
            <ClassCard {...item} onEdit={() => onSetEdit(item, true)} />
          </Grid.Item>
        ))}
      </Grid>

      <NewOrUpdateClassModal
        open={isOpen}
        item={editItem}
        onClose={() => onSetEdit(null, false)}
      />
    </TabContainer>
  );
}
