import { Card, CardBody, CardHeader } from "@heroui/react";
import { cn } from '@/lib/utils.ts';
import Text from '@/lib/ui/text.tsx';
import AreaChart from '@/lib/ui/charts/area-chart.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { useGetActivity } from '@/app/app/dashboard/store/query/dashboard.query.tsx';
import { TitleWithFilter } from '@/app/app/dashboard/components/TitleWithFilter.tsx';

export function ActivityGraph({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { activity, loading } = useGetActivity();

  return (
    <Card className={cn('relative block', className)}>
      <CardBody>
        <CardHeader className="flex-col items-start">
          <TitleWithFilter>{t('activity.title')}</TitleWithFilter>
          <Text className="mb-4">{t('activity.sub_title')}</Text>
        </CardHeader>

        <AreaChart
          loading={loading}
          name={t('assistants')}
          data={activity || []}
        />
      </CardBody>
    </Card>
  );
}
