import { useEffect } from 'react';
import { Button } from '@heroui/react';
import { Link } from 'react-router-dom';

import Text from '@/lib/ui/text.tsx';
import Title from '@/lib/ui/title.tsx';
import Container from '@/lib/ui/container.tsx';
import { BILLING_ROUTES } from '@/app/app/billing';
import { useAuth } from '@/context/AuthContext.tsx';
import AppLayout from '@/app/app/_shared/layout.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { AUTH_ROUTES } from '@/app/auth/_common/routes.ts';

export default function NotFound() {
  const { t } = useTranslation();
  const { user, activeSpace, isInitialLoading } = useAuth();

  useEffect(() => {
    if (activeSpace?.isSuspended && !isInitialLoading) {
      document.location.href = BILLING_ROUTES.HOME;
    }
  }, [activeSpace?.isSuspended, isInitialLoading]);

  if (user) {
    return (
      <AppLayout>
        <Container>
          <div className="grid place-content-center h-[calc(100vh-100px)] gap-5">
            <Title>{t('page_not_found')}</Title>
            <Text className="text-center">{t('page_not_found_desc')}</Text>

            <Button as={Link} to={AUTH_ROUTES.LOGIN} replace color="primary">
              {t('go_to_home')}
            </Button>
          </div>
        </Container>
      </AppLayout>
    );
  }

  return (
    <Container>
      <div className="grid place-content-center h-screen gap-5">
        <Title className="text-center">{t('page_not_found')}</Title>
        <Text className="text-center">{t('page_not_found_desc')}</Text>

        <Button as={Link} to={AUTH_ROUTES.LOGIN} replace color="primary">
          {t('go_to_home')}
        </Button>
      </div>
    </Container>
  );
}
