import NavbarItem from '@/app/app/_shared/components/navbar/navbar-item.tsx';
import { DASHBOARD_ROUTES } from '@/app/app/dashboard/common/routes.ts';
import { SESSION_ROUTES } from '@/app/app/sessions/common/routes.ts';
import { COACHES_ROUTES } from '@/app/app/coaches/common/routes.ts';
import { CLIENT_ROUTES } from '@/app/app/clients/common/routes.ts';
import { SETTINGS_ROUTES } from '@/app/app/settings/common/routes.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { cn } from '@/lib/utils.ts';
import { NOTIFICATIONS_ROUTES } from '@/app/app/notifications/common/routes.ts';
import { BILLING_ROUTES } from '@/app/app/billing';
import { useAuth } from '../../../../../context/AuthContext';
import { StatusSpaceEnum } from '@/app/auth/_store/mutation/graphql/mutation.graphql.ts';

export default function NavbarItems({
  className,
  trigger,
  ...restCommonProps
}: CommonComponentProps & { trigger?: () => void }) {
  const { t } = useTranslation();
  const { activeSpace } = useAuth();

  const isSuspended = activeSpace?.space?.status === StatusSpaceEnum.SUSPENDED;

  return (
    <nav
      className={cn('flex items-center gap-6 text-sm', className)}
      {...restCommonProps}
    >
      <NavbarItem
        trigger={trigger}
        href={DASHBOARD_ROUTES.DASHBOARD}
        isDisabled={isSuspended}
      >
        {t('nav.dashboard')}
      </NavbarItem>
      <NavbarItem
        trigger={trigger}
        href={SESSION_ROUTES.HOME}
        isDisabled={isSuspended}
      >
        {t('nav.sessions')}
      </NavbarItem>
      <NavbarItem
        trigger={trigger}
        href={COACHES_ROUTES.HOME}
        isDisabled={isSuspended}
      >
        {t('nav.coaches')}
      </NavbarItem>
      <NavbarItem
        trigger={trigger}
        href={CLIENT_ROUTES.HOME}
        isDisabled={isSuspended}
      >
        {t('nav.clients')}
      </NavbarItem>
      <NavbarItem
        trigger={trigger}
        href={NOTIFICATIONS_ROUTES.HOME}
        isDisabled={isSuspended}
      >
        {t('nav.notifications')}
      </NavbarItem>
      <NavbarItem
        trigger={trigger}
        isDisabled={isSuspended}
        href={SETTINGS_ROUTES.BASE}
        replace={{
          key: '/:tab',
          value: ''
        }}
      >
        {t(`nav.settings`)}
      </NavbarItem>

      {activeSpace?.isOwner && (
        <NavbarItem trigger={trigger} href={BILLING_ROUTES.HOME}>
          {t('nav.billing')}
        </NavbarItem>
      )}

      {/*<Dropdown*/}
      {/*  onOpenChange={setOpen}*/}
      {/*  items={Object.values(SETTINGS_TAB_ENUM).map((key) => ({*/}
      {/*    key,*/}
      {/*    children: (*/}
      {/*      <NavbarItem trigger={trigger} href={SETTINGS_ROUTES.TAB(key)}>*/}
      {/*        {t(`settings.${key}` as LanguageKeys)}*/}
      {/*      </NavbarItem>*/}
      {/*    )*/}
      {/*  }))}*/}
      {/*>*/}
      {/*  <div className="flex gap-1 items-center">*/}
      {/*    {t('nav.settings')}{' '}*/}
      {/*    {open ? (*/}
      {/*      <ChevronUp size={16} strokeWidth={3} />*/}
      {/*    ) : (*/}
      {/*      <ChevronDown size={16} strokeWidth={3} />*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*</Dropdown>*/}
    </nav>
  );
}
