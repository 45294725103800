import { PropsWithChildren } from 'react';
import { Copy, ListChecks, Pencil, Trash } from 'lucide-react';

import Text from '@/lib/ui/text';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Avatar,
  Chip
} from "@heroui/react";
import useToggle from '@/hooks/useToggle';
import { formatDate, formatTime } from '@/lib/utils';
import { EventSchedule } from '@/app/app/sessions/components/sessions-list/types';
import BookingUsers from '@/app/app/sessions/components/popover-event/booking-users.tsx';
import { copyToClipboard } from '@/utils';
import {
  STATUS_SUBSCRIPTION_OPTIONS,
  StatusItem
} from '@/app/app/sessions/common/constant/status-subscriptions-options-labels.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import EmptyState from '@/lib/ui/empty-state.tsx';
import { toast } from 'sonner';
import { useCreateOrEditSession } from '@/app/app/sessions/components/create-edit-session/context';
import { SessionFragmentFragment } from '@/__generated__/graphql.ts';
import {
  useCreateSession,
  useDeleteRepeatSession,
  useDeleteSession
} from '@/app/app/sessions/store/mutation/session.mutation.ts';
import { useDialog } from '@/lib/ui/Dialog/Provider.tsx';
import Alert from '@/lib/ui/alert.tsx';
import Title from '@/lib/ui/title.tsx';
import { CLASSES_STATUS_ENUM } from '@/types/classes.ts';
import { useAuth } from '../../../../../context/AuthContext';
import dayjs from 'dayjs';

export default function PopoverEvent(props: PropsWithChildren & EventSchedule) {
  const {
    children,
    name,
    description,
    id,
    status,
    startedAt,
    finishedAt,
    subscriptions
  } = props;
  const dialog = useDialog();
  const { t } = useTranslation();
  const { activeSpace } = useAuth();
  const [open, actionOpen] = useToggle();
  const { setSession } = useCreateOrEditSession();
  const [showPopover, actionPopover] = useToggle();

  // CREATE MUTATIONS
  const { onCreateFunction, loading } = useCreateSession();

  // DELETE MUTATIONS
  const { onDeleteRepeatSession } = useDeleteRepeatSession();
  const { onDeleteSession } = useDeleteSession();

  const onCopy = async () => {
    try {
      await copyToClipboard(id);
      toast(t('operation_successfully'));
    } catch (e) {
      console.error(e);
    }
  };

  const onDelete = async () => {
    actionPopover.onHidden();
    const session = props as SessionFragmentFragment;

    if (!session) return null;

    return dialog.open({
      title: t('delete_session_alert.title'),
      children: t('delete_session_alert.message'),
      actions: [
        {
          label: t('delete_session_alert.delete_this'),
          color: 'danger',
          variant: 'light',
          onClick: async () => {
            await onDeleteSession(session);
          }
        },
        {
          label: t('delete_session_alert.delete_all'),
          color: 'danger',
          onClick: async () => {
            await onDeleteRepeatSession(session);
          }
        }
      ]
    });
  };

  const onCreateFutureClass = async () => {
    const isBeforeNow = dayjs(finishedAt).isSameOrBefore(dayjs());

    try {
      await onCreateFunction({
        variables: {
          data: {
            color: props?.color as string,
            name: name as string,
            startedAt: startedAt as string,
            coach: {
              connect: {
                id: props?.coach?.id as string
              }
            },
            limitQuote: props?.limitQuote as number,
            finishedAt: finishedAt as string,
            description: description as string,
            cost: props?.cost as number,
            space: {
              connect: {
                id: activeSpace?.space?.id as string
              }
            },
            status: isBeforeNow
              ? CLASSES_STATUS_ENUM.FINISHED
              : CLASSES_STATUS_ENUM.OPEN,
            rootEvent: {
              connect: {
                id: props?.rootEvent?.id as string
              }
            }
          }
        }
      });

      actionPopover?.onHidden?.();
    } catch (error) {
      console.error(error);
    }
  };

  const isFuture = [CLASSES_STATUS_ENUM.NOT_CREATED].includes(
    status as CLASSES_STATUS_ENUM
  );

  return (
    <>
      <Popover
        shadow="md"
        isOpen={showPopover}
        onOpenChange={(open) =>
          open ? actionPopover.onVisible() : actionPopover.onHidden()
        }
      >
        <PopoverTrigger>
          <div className="cursor-pointer select-none">{children}</div>
        </PopoverTrigger>

        <PopoverContent className="w-[425px] max-w-full items-start p-5">
          <div className="grid gap-4 w-full">
            <div className="space-y-2">
              <div className="flex justify-between items-center gap-1 mb-1">
                <div className="flex flex-col gap-1">
                  <Text className="font-medium text-lg mb-0 leading-none">
                    {name}
                  </Text>

                  <Text className="text-xs text-gray-600 leading-none">
                    {formatDate(startedAt)} {formatTime(startedAt)} -{' '}
                    {formatTime(finishedAt)}
                  </Text>
                </div>

                {!isFuture && (
                  <div className="flex">
                    <Button
                      isIconOnly
                      size="sm"
                      variant="light"
                      onClick={onCopy}
                    >
                      <Copy size={14} />
                    </Button>
                    <Button
                      isIconOnly
                      size="sm"
                      variant="light"
                      className="text-current"
                      onClick={onDelete}
                    >
                      <Trash size={14} />
                    </Button>
                    <Button
                      isIconOnly
                      size="sm"
                      variant="light"
                      onClick={() => {
                        actionPopover.onHidden();
                        setSession(
                          props as unknown as DeepNonNullable<SessionFragmentFragment>
                        );
                      }}
                    >
                      <Pencil size={14} />
                    </Button>
                    <Button
                      isIconOnly
                      size="sm"
                      variant="light"
                      className="text-current"
                      onClick={() => {
                        actionPopover.onHidden();
                        actionOpen.onVisible();
                      }}
                    >
                      <ListChecks size={14} />
                    </Button>
                  </div>
                )}
              </div>

              <Text className="text-sm text-muted-foreground">
                {description}
              </Text>
            </div>

            <div className="flex flex-col gap-2">
              {!subscriptions?.length && !isFuture && (
                <EmptyState
                  className="max-w-[175px]"
                  title={t('empty_assistants')}
                />
              )}

              {isFuture && (
                <Alert severity="warning">
                  <Title size="xs" className="mb-0">
                    {t('class_not_created')}
                  </Title>
                  <Text>
                    {t('class_not_created_message')}{' '}
                    <Button
                      variant="light"
                      color="primary"
                      isLoading={loading}
                      onClick={onCreateFutureClass}
                      className="px-1 py-1 min-w-0 h-auto inline-block"
                    >
                      {t('create')}
                    </Button>
                  </Text>
                </Alert>
              )}

              {subscriptions?.map?.((subscription) => {
                const status =
                  STATUS_SUBSCRIPTION_OPTIONS?.[
                    subscription?.status as StatusItem
                  ];

                return (
                  <div
                    key={subscription?.id}
                    className="flex gap-2 items-center justify-between"
                  >
                    <div className="flex gap-2 items-center">
                      <Avatar
                        src={subscription?.user?.avatar?.url}
                        name={subscription?.user?.fullName ?? ''}
                      />

                      <Text className="text-gray-600">
                        {subscription?.user?.fullName}
                      </Text>
                    </div>

                    <Chip size="sm" color={status?.color} variant="flat">
                      {t(status?.label)}
                    </Chip>
                  </div>
                );
              })}
            </div>
          </div>
        </PopoverContent>
      </Popover>

      <BookingUsers open={open} onClose={actionOpen.onHidden} {...props} />
    </>
  );
}
