import { Button } from '@heroui/react';
import { BadgeCheck } from 'lucide-react';

import { useTranslation } from '@/hooks/useTranslation.ts';
import { useBilling } from '@/app/app/billing/context/billing.context.tsx';

export default function AddPaymentMethod() {
  const { t } = useTranslation();
  const { paymentMethod } = useBilling();

  return (
    <Button
      fullWidth
      variant="flat"
      color="primary"
      onPress={paymentMethod.onOpenEdit}
      startContent={<BadgeCheck size={18} />}
    >
      {t('add_payment_method')}
    </Button>
  );
}
