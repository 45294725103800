import { memo } from 'react';
import { Card, CardBody } from "@heroui/react";
import { useGetTodaySessionsQuery } from '@/app/app/dashboard/store/query/dashboard.query.tsx';
import { SessionBoardFragmentFragment } from '@/__generated__/graphql.ts';
import SessionCard from '@/app/app/sessions/components/session-card';
import EmptyState from '@/lib/ui/empty-state.tsx';
import Loading from '@/lib/ui/loading.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import Title from '@/lib/ui/title.tsx';

function TodaySessions({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { data, loading } = useGetTodaySessionsQuery();

  const sessions =
    data?.sessionsListBackoffice as SessionBoardFragmentFragment[];

  return (
    <div className={className}>
      <Card className="relative p-4 min-h-[220px]">
        <CardBody className="relative">
          <Title size="lg" className="mb-0">{t('today_sessions')}</Title>
          <div className="mt-4 flex flex-col gap-4">
            {loading ? (
              <Loading />
            ) : !sessions?.length ? (
              <EmptyState title={t('today_sessions_empty')} />
            ) : (
              sessions.map((session) => {
                return (
                  <SessionCard
                    key={session.id}
                    className="w-full"
                    {...session}
                  />
                );
              })
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default memo(TodaySessions);
