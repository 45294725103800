import AppContainer from '@/app/app/_shared/components/AppContainer.tsx';
import PaymentHistory from '@/app/app/billing/components/PaymentHistory.tsx';
import PaymentMethod from '@/app/app/billing/components/PaymentMethod.tsx';
import { BillingProvider } from '@/app/app/billing/context/billing.context.tsx';
import EmailBilling from '@/app/app/billing/components/EmailBilling.tsx';
import CurrentPlan from '@/app/app/billing/components/CurrentPlan.tsx';
import AddPlanCustomerModal from '@/app/app/billing/components/PlanCustomer.tsx';

function RenderPage() {
  // const { t } = useTranslation();

  return (
    <AppContainer
      showBreadcrumbs={false}
      layoutClassName="sm:px-3 flex flex-col gap-5"
    >
      {/*<Title>{t('nav.billing')}</Title>*/}

      <section className="flex flex-col gap-5">
        <div className="w-full xl:w-[100%]">
          <CurrentPlan />
        </div>

        <div className="w-full xl:w-[100%]">{/*<PlanCustomer />*/}</div>
      </section>

      <section className="flex flex-col md:flex-row gap-5">
        <PaymentMethod />
        <EmailBilling />
      </section>

      <PaymentHistory />

      {/* Modal */}
      <AddPlanCustomerModal />
      <div className="mb-10" />
    </AppContainer>
  );
}

export function BillingPage() {
  return (
    <BillingProvider>
      <RenderPage />
    </BillingProvider>
  );
}
