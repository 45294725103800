import { Button, Card, CardBody, Image, Tooltip } from "@heroui/react";
import { ClassOfferedFragmentFragment } from '@/__generated__/graphql.ts';
import Text from '@/lib/ui/text.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import Title from '@/lib/ui/title.tsx';

export default function ClassCard({
  name,
  short_summary,
  schedules,
  image,
  onEdit
}: ClassOfferedFragmentFragment & { onEdit?: () => void }) {
  const { t } = useTranslation();

  return (
    <Card className="relative bg-default-50 dark:bg-default p-0 h-full">
      <CardBody className="gap-2 p-0 flex flex-col justify-between">
        <div>
          {image && (
            <div className="mb-2">
              <Image
                className="rounded-none w-full h-[200px] object-cover"
                classNames={{
                  wrapper: 'max-w-[100%!important]'
                }}
                src={image?.url}
              />
            </div>
          )}

          <div className="p-5 pt-2">
            <Title size="xs" className="mb-1">
              {name}
            </Title>
            {short_summary && (
              <Tooltip
                content={
                  <div className="max-w-[250px] p-2">{short_summary}</div>
                }
              >
                <Text className="text-sm line-clamp-2">{short_summary}</Text>
              </Tooltip>
            )}
          </div>

          <div className="px-5 py-0 flex flex-col gap-2">
            {schedules?.map?.(({ day, time }) => {
              return (
                <div key={day} className="flex justify-between items-center">
                  <Text className="text-sm text-gray-600 dark:text-gray-400">
                    {t(`days.${day}` as LanguageKeys, day)}
                  </Text>
                  <Text className="text-sm text-gray-600 dark:text-gray-400">
                    {time}
                  </Text>
                </div>
              );
            })}
          </div>
        </div>

        <div className="p-3">
          <Button
            className="mx-auto block"
            onClick={onEdit}
            color="primary"
            variant="light"
          >
            {t('edit')}
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}
