import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { LoadingLogin } from '@/app/auth/login/_loading.tsx';
import { DASHBOARD_ROUTES } from '@/app/app/dashboard/common/routes.ts';
import { useEffect } from 'react';
import SelectSpaceList from '@/app/auth/_components/SelectSpaceList';
import Title from '@/lib/ui/title.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { SELECT_SPACE_KEY } from '@/config/constants.ts';
import useToggle from '@/hooks/useToggle.ts';

export default function SelectSpacePage() {
  const { t } = useTranslation();
  const [loadingVerification, toggleVerification] = useToggle(true);
  const { user, isInitialLoading, isLoginLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isInitialLoading && !isLoginLoading && !user) {
      window.location.href = DASHBOARD_ROUTES.DASHBOARD;
    }
  }, [isInitialLoading, isLoginLoading, navigate, user]);

  useEffect(() => {
    if (user && !!user?.scope?.length) {
      try {
        const selectSpace = JSON.parse(
          localStorage.getItem(SELECT_SPACE_KEY) || ''
        );

        const isExistSpace = user.scope.find(
          (item) => item?.id === selectSpace?.id
        );

        if (isExistSpace) {
          navigate(DASHBOARD_ROUTES.DASHBOARD);
        } else {
          toggleVerification.onHidden();
        }
      } catch (e) {
        console.log(e);
        toggleVerification.onHidden();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, toggleVerification.onHidden, navigate]);

  return (
    <main className="w-full flex h-screen p-5 flex-col justify-center items-center gap-10">
      <LoadingLogin />

      {(!isLoginLoading && !isInitialLoading && !user) ||
      loadingVerification ? null : (
        <>
          <Title size="md">{t('select_space_to_admin')}</Title>
          <SelectSpaceList scope={user?.scope || []} />
        </>
      )}
    </main>
  );
}
