import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY_INVOICES } from '@/config/env.ts';
import { useAuth } from '@/context/AuthContext.tsx';
import {
  useGetBillingMethodQuery,
  useGetPaymentHistoryQuery
} from '@/app/app/billing/store/query/billing.query.ts';
import {
  InvoiceResponseDto,
  PaymentMethodResponseDto
} from '@jogolabs/billing-sdk';
import { Elements } from '@stripe/react-stripe-js';
import useToggle from '@/hooks/useToggle.ts';
import dayjs from 'dayjs';

export type BillingContextType = {
  stripePromise: Promise<Stripe | null>;
  plans: {
    planId: string | undefined | null;
    setPlanId: Dispatch<SetStateAction<string | null | undefined>>;
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
  trialFinished: boolean;
  paymentMethod: {
    isLoading: boolean;
    data: PaymentMethodResponseDto | null;
    openEdit: boolean;
    onOpenEdit: () => void;
    onCloseEdit: () => void;
  };
  invoices: {
    isLoading: boolean;
    data: InvoiceResponseDto[] | null;
    firstInvoice: InvoiceResponseDto | null;
  };
};

const BillingContext = createContext<BillingContextType | null>(null);

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY_INVOICES);

export const BillingProvider = ({ children }: { children: ReactNode }) => {
  const { activeSpace } = useAuth();
  const [openPlan, openPlanActions] = useToggle();
  const [openEdit, openEditActions] = useToggle();
  const [planId, setPlanId] = useState<string | undefined | null>(
    activeSpace?.space?.plan
  );

  const paymentMethod = useGetBillingMethodQuery();
  const invoicesQuery = useGetPaymentHistoryQuery();

  const firstInvoice = useMemo(
    () => invoicesQuery?.data?.[0] || null,
    [invoicesQuery?.data]
  );

  return (
    <Elements stripe={stripePromise}>
      <BillingContext.Provider
        value={useMemo(
          () => ({
            plans: {
              planId,
              setPlanId,
              open: openPlan,
              onClose: openPlanActions.onHidden,
              onOpen: openPlanActions.onVisible
            },
            stripePromise,
            paymentMethod: {
              data: paymentMethod?.data || null,
              isLoading: paymentMethod?.isLoading,
              openEdit: openEdit,
              onCloseEdit: openEditActions.onHidden,
              onOpenEdit: openEditActions.onVisible
            },
            invoices: {
              isLoading: invoicesQuery?.isLoading,
              data: invoicesQuery?.data || null,
              firstInvoice
            },
            trialFinished: dayjs(activeSpace?.space?.trialEndAt).isBefore(
              dayjs()
            )
          }),
          [
            planId,
            openPlan,
            openPlanActions.onHidden,
            openPlanActions.onVisible,
            paymentMethod?.data,
            paymentMethod?.isLoading,
            openEdit,
            openEditActions.onHidden,
            openEditActions.onVisible,
            invoicesQuery?.isLoading,
            invoicesQuery?.data,
            firstInvoice,
            activeSpace?.space?.trialEndAt
          ]
        )}
      >
        {children}
      </BillingContext.Provider>
    </Elements>
  );
};

export function useBilling() {
  const context = useContext(BillingContext);
  if (!context) {
    throw new Error('useBilling must be used within a BillingProvider');
  }
  return context;
}
