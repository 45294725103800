import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Switch } from "@heroui/react";
import Form, { FormItem } from 'reactivity-hook-form';

import Modal, { ModalActions } from '@/lib/ui/modal.tsx';
import {
  useNewUserBackoffice,
  useUpdateUserBackoffice
} from '@/app/app/settings/store/mutation/users.mutation.ts';
import Input from '@/lib/ui/input.tsx';
import GridColumn from '@/lib/ui/grid-column';
import { isEmail } from '@/utils/constants.ts';
import { UserBackOffice } from '@/app/app/settings/components/Tabs/AdminsTab/types.ts';
import EmailInputFetch from '@/lib/ui/email-input-fetch';
import { UserFragmentFragment } from '@/__generated__/graphql.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

const FORM_NAME = 'new-user-admin';

type NewInvitationProps = {
  isOpen: boolean;
  onHidden: () => void;
  item?: UserBackOffice | null;
};

export default function NewInvitation({
  item,
  isOpen,
  onHidden
}: NewInvitationProps) {
  const { t } = useTranslation();
  const form = useForm<UserBackOffice>({
    mode: 'onChange',
    defaultValues: item || {
      isOwner: false,
      isAdmin: true
    }
  });
  const { onUpdateAdminUser, loading: uLoading } = useUpdateUserBackoffice();
  const { onCreateAdminUser, loading: cLoading } = useNewUserBackoffice();
  const loading = uLoading || cLoading;

  const onSubmit = (values: UserBackOffice) => {
    if (item) {
      return onUpdateAdminUser(item.id, values).then(onHidden);
    }
    return onCreateAdminUser(values).then(onHidden);
  };

  const existUser = form.watch('user');

  const onLinkUser = useCallback(
    (user: UserFragmentFragment) =>
      form.setValue('user', user, { shouldValidate: true }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form.setValue]
  );

  const onCleanUser = useCallback(
    (userId: string) =>
      form.setValue('user.id', userId, { shouldValidate: true }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form.setValue]
  );

  return (
    <Modal hideCloseButton isOpen={isOpen} title={t('settings.new_invitation')}>
      <Form<UserBackOffice>
        id={FORM_NAME}
        formContext={form}
        onSubmit={onSubmit}
        validations={{
          'user.email': {
            required: true,
            ...isEmail
          },
          'user.firstName': { required: t('validations.required') },
          'user.lastName': { required: t('validations.required') }
        }}
      >
        <FormItem<UserBackOffice>
          name="user.firstName"
          label={t('form.first_name')}
        >
          <Input isDisabled={!!existUser?.id} />
        </FormItem>
        <FormItem<UserBackOffice>
          name="user.lastName"
          label={t('form.last_name')}
        >
          <Input isDisabled={!!existUser?.id} />
        </FormItem>
        <FormItem<UserBackOffice> name="user.email" label={t('form.email')}>
          <EmailInputFetch
            removeOnEmpty
            user={existUser}
            onLinkUser={onLinkUser}
            onCleanUser={onCleanUser}
          />
        </FormItem>
        <GridColumn xs={2}>
          <FormItem<UserBackOffice> name="isOwner" label={t('super_admin')}>
            <Switch className="block" />
          </FormItem>
          <FormItem<UserBackOffice> name="isAdmin" label={t('admin')}>
            <Switch checked defaultSelected isDisabled className="block" />
          </FormItem>
        </GridColumn>
      </Form>

      <ModalActions
        variant="save"
        onCancel={onHidden}
        formName={FORM_NAME}
        isLoading={loading}
      />
    </Modal>
  );
}
