import { Card, CardBody, CardHeader } from "@heroui/react";
import { cn } from '@/lib/utils.ts';
import Text from '@/lib/ui/text.tsx';
import { useGetTotalCustomers } from '@/app/app/dashboard/store/query/dashboard.query.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import PieChart from '@/lib/ui/charts/pie-chart.tsx';
import { PieChartProps } from 'react-minimal-pie-chart';
import { TitleWithFilter } from '@/app/app/dashboard/components/TitleWithFilter.tsx';

export function CustomersGraph({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { data, loading } = useGetTotalCustomers();

  const customers: PieChartProps['data'] = [
    {
      key: 'active',
      value: data?.active || 0,
      title: t('active'),
      color: '#5AD1A5'
    },
    {
      key: 'inactive',
      value: data?.inactive || 0,
      title: t('inactive'),
      color: '#B0BEC5'
    }
  ];

  return (
    <Card className={cn('relative block', className)}>
      <CardBody>
        <CardHeader className="flex-col items-start">
          <TitleWithFilter>{t('total_customers')}</TitleWithFilter>
          <Text className="mb-4">{t('activity.sub_title')}</Text>
        </CardHeader>

        <PieChart
          loading={loading}
          formatValue={(value) => value}
          chartWrapperClass="max-w-[300px]"
          data={customers}
        />
      </CardBody>
    </Card>
  );
}
