import { Card, CardBody, CardHeader } from "@heroui/react";

import Text from '@/lib/ui/text.tsx';
import { cn, formatMoney } from '@/lib/utils.ts';
import PieChart from '@/lib/ui/charts/pie-chart.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { TitleWithFilter } from '@/app/app/dashboard/components/TitleWithFilter.tsx';
import { useGetPaymentMethod } from '@/app/app/dashboard/store/query/dashboard.query.tsx';

export function PaymentMethodGraph({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { options, loading } = useGetPaymentMethod();

  return (
    <Card className={cn('relative block', className)}>
      <CardBody>
        <CardHeader className="flex-col items-start">
          <TitleWithFilter>{t('income')}</TitleWithFilter>
          <Text className="mb-4">{t('activity.sub_title')}</Text>
        </CardHeader>

        <PieChart
          formatValue={formatMoney}
          chartWrapperClass="max-w-[300px]"
          loading={loading}
          data={options}
        />
      </CardBody>
    </Card>
  );
}
