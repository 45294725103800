import { useForm } from 'react-hook-form';
import Form, { FormItem } from 'reactivity-hook-form';
import { Button, ModalProps as NextUIModalProps } from "@heroui/react";

import { cn } from '@/lib/utils.ts';
import Modal from '@/lib/ui/modal.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { SessionSubscriptionUpdateArgs } from '@/__generated__/graphql.ts';
import SubscriptionStatusSelect from '@/app/app/sessions/components/SubscriptionStatusSelect';
import { useUpdateSubscriptions } from '@/app/app/sessions/store/mutation/subscription.mutation.ts';
import { SessionSubscriptionBase } from '@/app/app/sessions/components/popover-event/types.ts';
import { SUBSCRIPTION_STATUS_ENUM } from '@/app/app/sessions/components/SubscriptionStatusSelect/SubscriptionStatusSelect.tsx';

type DataValuesForm = Pick<SessionSubscriptionUpdateArgs['data'], 'status'>;

const FORM_ID = 'add-customer-form';

export default function EditCustomer({
  onClose,
  sessions,
  onOk,
  isExpiredClass,
  isOpen
}: Pick<NextUIModalProps, 'onClose' | 'isOpen'> & {
  onOk: () => void;
  isExpiredClass?: boolean;
  sessions: Array<SessionSubscriptionBase>;
}) {
  const { t } = useTranslation();
  const context = useForm<DataValuesForm>({
    defaultValues: {
      status: isExpiredClass
        ? SUBSCRIPTION_STATUS_ENUM.CONFIRMED
        : SUBSCRIPTION_STATUS_ENUM.PENDING
    }
  });
  const { onUpdateSubscription, loading } = useUpdateSubscriptions();

  const internalClose = () => {
    onClose?.();
    context.reset();
  };

  const onSubmit = async (values: DataValuesForm) => {
    const { errors } = await onUpdateSubscription(
      sessions.map((item) => ({
        data: {
          status: values.status
        },
        where: {
          id: item.id
        }
      }))
    );
    if (!errors) {
      onOk();
      return internalClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('edit')}>
      <div className={cn('w-full flex flex-col gap-5 pt-3 pb-5')}>
        <Form<DataValuesForm>
          id={FORM_ID}
          className="w-full"
          onSubmit={onSubmit}
          formContext={context}
        >
          <FormItem name="status">
            <SubscriptionStatusSelect />
          </FormItem>

          <Button
            type="submit"
            variant="flat"
            color="primary"
            isLoading={loading}
          >
            {t('accept')}
          </Button>
        </Form>
      </div>
    </Modal>
  );
}
