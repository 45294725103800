import { FormItem } from 'reactivity-hook-form';

import { NewSessionFormType } from '@/app/app/sessions/common/types.ts';
import SelectSessionCategories from '@/app/app/_shared/components/SelectSessionCategories.tsx';

export function CategoryField() {
  return (
    <FormItem<NewSessionFormType> name="categories">
      <SelectSessionCategories />
    </FormItem>
  );
}
