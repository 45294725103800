import { Button } from "@heroui/react";
import { CircleAlert } from 'lucide-react';

import { useTranslation } from '@/hooks/useTranslation';
import {
  useCreateStripeAccount,
  useLinkStripeAccount,
  useLoginStripeAccount
} from '../../../store/mutation/stripe.mutation';
import { useAuth } from '../../../../../../context/AuthContext';
import Title from '@/lib/ui/title.tsx';
import Text from '@/lib/ui/text.tsx';
import Container from '@/lib/ui/container.tsx';
import Alert from '@/lib/ui/alert.tsx';
import { useEffect, useState } from 'react';
import {
  loadConnectAndInitialize,
  StripeConnectInstance
} from '@stripe/connect-js';
import { STRIPE_PUBLISHABLE_KEY } from '@/config/env.ts';
import {
  ConnectBalances,
  ConnectComponentsProvider,
  ConnectPayments
} from '@stripe/react-connect-js';
import { useTheme } from '@/context/theme-provider.tsx';

export default function StripeTab() {
  const { activeSpace } = useAuth();
  const { t } = useTranslation();
  const { theme } = useTheme();

  const createAccountMutation = useCreateStripeAccount();
  const getLinkAccountMutation = useLinkStripeAccount();
  const loginAccountMutation = useLoginStripeAccount();

  const [stripeConnectInstance, setStripeConnectInstance] = useState<{
    instance: StripeConnectInstance;
    theme: 'light' | 'dark';
  } | null>(null);

  const handleCreate = async () => {
    const paymentProvider = activeSpace?.space?.paymentsProvider?.find(
      (item) => item?.status?.toLowerCase?.() === 'pending'
    );

    try {
      let linkStripe: string | undefined;

      if (paymentProvider?.referenceId) {
        linkStripe = await getLinkAccountMutation.onLinkAccount(
          paymentProvider.referenceId
        );
      } else {
        linkStripe = await createAccountMutation.onCreateAccount();
      }
      if (linkStripe) {
        window.location.assign(linkStripe);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      setStripeConnectInstance((prev) => {
        if (!prev || prev.theme !== theme) {
          const fetchClientSecret = async () => {
            const res = await loginAccountMutation.onMutation();
            if (!res) {
              throw new Error('Failed to fetch client secret');
            }
            return res;
          };
          const instance = loadConnectAndInitialize({
            publishableKey: STRIPE_PUBLISHABLE_KEY,
            fetchClientSecret: fetchClientSecret,
            appearance: {
              variables: {
                colorBackground: theme === 'dark' ? '#161A1D' : '#f9f9f9'
              }
            }
          });

          return { instance, theme };
        }

        return prev;
      });
    })();
  }, [loginAccountMutation, theme]);

  return (
    <Container>
      {!activeSpace?.space?.paymentsProviderCount ? (
        <div className="flex flex-col items-center gap-5 w-[450px] max-w-full m-auto">
          <div className="text-center">
            <Title>Stripe</Title>
            <Text className="w-[450px] max-w-full">
              {t('stripe.description')}
            </Text>
          </div>

          <img src="/images/credit-card.svg" width={300} />

          {!!activeSpace?.space?.paymentsProvider?.length && (
            <Alert className="flex items-center gap-2">
              <CircleAlert className="w-[25px] h-[25px]" />
              <Text className="text-xs">{t('stripe.alert')}</Text>
            </Alert>
          )}
          <Button
            onClick={handleCreate}
            className="bg-[#635bff] text-white"
            isDisabled={loginAccountMutation.loading}
            isLoading={
              createAccountMutation.loading ||
              getLinkAccountMutation.loading ||
              !!createAccountMutation.data ||
              !!getLinkAccountMutation.data
            }
          >
            {activeSpace?.space?.paymentsProvider?.length
              ? t('continue_stripe')
              : t('connect_stripe')}
          </Button>
        </div>
      ) : (
        <div className="flex flex-col gap-7">
          {!!stripeConnectInstance?.instance && (
            <ConnectComponentsProvider
              key={stripeConnectInstance.theme}
              connectInstance={stripeConnectInstance.instance}
            >
              <ConnectBalances />
              <ConnectPayments />
            </ConnectComponentsProvider>
          )}
        </div>
      )}
    </Container>
  );
}
