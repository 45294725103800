import { toast } from 'sonner';
import { Button } from '@heroui/react';
import { BadgeCheck } from 'lucide-react';

import { getApiErrorText } from '@/lib/utils.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { useBilling } from '@/app/app/billing/context/billing.context.tsx';
import { useSubscribeToPlan } from '@/app/app/billing/store/mutation/billing.mutation.ts';
import { useAuth } from '@/context/AuthContext.tsx';

export default function SubscriptionReNew() {
  const { t } = useTranslation();
  const { activeSpace } = useAuth();
  const { paymentMethod, plans } = useBilling();
  const { mutateAsync, isPending } = useSubscribeToPlan();

  const handleRetryPayment = async () => {
    if (!plans?.planId) {
      toast.error(t('errors_message.unexpected_error'), {
        description: t('errors_message.user_not_have_plan_selected')
      });
      return;
    }

    if (!paymentMethod?.data?.id) {
      toast.error(t('errors_message.unexpected_error'), {
        description: t('errors_message.user_not_have_payment_methods')
      });
      return;
    }

    if (!activeSpace?.space?.billingRef) {
      toast.error(t('errors_message.unexpected_error'), {
        description: t('errors_message.user_not_have_stripe_settings')
      });
      return;
    }

    try {
      await mutateAsync({
        customerId: activeSpace?.space?.billingRef as string,
        priceId: plans?.planId as string,
        jogoId: activeSpace?.space?.id as string
      });
    } catch (e) {
      toast.error(t('errors_message.unexpected_error'), {
        description: t('errors_message.original_error', null, {
          error: getApiErrorText(e)
        })
      });
    }
  };

  return (
    <Button
      fullWidth
      color="primary"
      variant="flat"
      isLoading={isPending}
      startContent={<BadgeCheck size={18} />}
      onPress={handleRetryPayment}
    >
      {t('replay_subscription')}
    </Button>
  );
}
