import { useNavigate } from 'react-router-dom';
import { PropsWithChildren, useEffect } from 'react';

import { useAuth } from '@/context/AuthContext.tsx';
import { AUTH_ROUTES } from '@/app/auth/_common/routes.ts';
import ScreenLoading from '@/lib/ui/screen-loading.tsx';

export default function PrivateRouter({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const { user, isInitialLoading, isLoginLoading } = useAuth();

  useEffect(() => {
    if (!isInitialLoading && !user) {
      console.log('NOT LOGGED IN');
      navigate(AUTH_ROUTES.LOGIN);
    } else if (!isInitialLoading && user) {
      console.log('LOGGED IN');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoading, user]);

  if (isInitialLoading || isLoginLoading) {
    return <ScreenLoading />;
  }

  return <>{children}</>;
}
