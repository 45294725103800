import Title from '@/lib/ui/title.tsx';
import { Chip } from "@heroui/react";
import { useTranslation } from '@/hooks/useTranslation.ts';
import { useDashboardFilters } from '@/app/app/dashboard/context/filters.ts';
import { PropsWithChildren } from 'react';

export function TitleWithFilter({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const { dateRange } = useDashboardFilters();

  return (
    <div className="flex gap-1 justify-between items-center">
      <Title size="lg" className="mb-0">
        {children}
      </Title>

      <Chip size="sm" radius="sm" color="warning" variant="flat">
        {t(`filter_dashboard.${dateRange}`, dateRange)}
      </Chip>
    </div>
  );
}
