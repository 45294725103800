import { useCallback, useMemo } from 'react';
import { Button, Switch } from "@heroui/react";
import { Pencil, Plus } from 'lucide-react';

import { useAuth } from '../../../../../../context/AuthContext';
import TabContainer from '@/app/app/settings/components/TabContainer';
import { useUsersQuery } from '@/app/app/settings/store/query/users.query.ts';
import { Table, TableColumn } from '@/lib/ui/table.tsx';
import {
  UserBackofficeFragmentFragment,
  UserFragmentFragment
} from '@/__generated__/graphql.ts';
import { useUpdateUserBackoffice } from '@/app/app/settings/store/mutation/users.mutation.ts';
import NewInvitation from '@/app/app/settings/components/Tabs/AdminsTab/NewInvitation.tsx';
import ShortId from '@/lib/ui/short-id.tsx';
import useSelectEditItem from '@/hooks/useSelectEditItem.ts';
import useToggle from '@/hooks/useToggle.ts';
import { UserBackOffice } from './types.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export default function AdminsTab() {
  const { t } = useTranslation();
  const { activeSpace } = useAuth();
  const [isOpen, actionsOpen] = useToggle();
  const [editItem, onEdit] = useSelectEditItem<UserBackOffice>(actionsOpen);
  const { onUpdateAdminUser, loading: loadingUpdate } =
    useUpdateUserBackoffice();
  const { data, loading: loadingQuery } = useUsersQuery();

  const onChangeState = useCallback(
    (
      key: 'isOwner' | 'isAdmin' | 'isBlocked',
      id: string,
      newValue: boolean
    ) => {
      return onUpdateAdminUser(id, {
        [key]: newValue
      });
    },
    [onUpdateAdminUser]
  );

  const loading = loadingQuery || loadingUpdate;

  const columns = useMemo<TableColumn<UserBackofficeFragmentFragment>>(() => {
    return [
      {
        field: 'id',
        headerName: 'ID',
        renderCell({ row }) {
          return <ShortId>{row?.id}</ShortId>;
        }
      },
      {
        field: 'firstName',
        headerName: t('form.first_name'),
        renderCell({ row }) {
          const user = row?.user as UserFragmentFragment;
          return user?.firstName;
        }
      },
      {
        field: 'lastName',
        headerName: t('form.last_name'),
        renderCell({ row }) {
          const user = row?.user as UserFragmentFragment;
          return user?.lastName;
        }
      },
      {
        field: 'email',
        headerName: t('form.email'),
        renderCell({ row }) {
          const user = row?.user as UserFragmentFragment;
          return user?.email;
        }
      },
      {
        field: 'isOwner',
        headerName: t('super_admin'),
        renderCell({ row }) {
          const isIM = activeSpace?.id === row?.id;
          return (
            <Switch
              size="sm"
              isDisabled={isIM}
              defaultSelected={Boolean(row?.isOwner)}
              onValueChange={(newVal) =>
                onChangeState('isOwner', row?.id, newVal)
              }
            />
          );
        }
      },
      {
        field: 'isAdmin',
        headerName: t('admin'),
        renderCell({ row }) {
          const isIM = activeSpace?.id === row?.id;
          return (
            <Switch
              size="sm"
              isDisabled={isIM}
              defaultSelected={Boolean(row?.isAdmin)}
              onValueChange={(newVal) =>
                onChangeState('isAdmin', row?.id, newVal)
              }
            />
          );
        }
      },
      {
        field: 'isBlocked',
        headerName: t('is_blocked'),
        renderCell({ row }) {
          const isIM = activeSpace?.id === row?.id;
          return (
            <Switch
              size="sm"
              isDisabled={isIM}
              defaultSelected={Boolean(row?.isBlocked)}
              onValueChange={(newVal) =>
                onChangeState('isBlocked', row?.id, newVal)
              }
            />
          );
        }
      },
      {
        field: 'actions',
        headerName: t('actions'),
        width: 80,
        renderCell({ row }) {
          return (
            <Button
              size="sm"
              variant="light"
              className="w-fit min-w-fit"
              startContent={<Pencil size={16} />}
              onClick={() => onEdit(row as UserBackOffice)}
            />
          );
        }
      }
    ];
  }, [activeSpace?.id, onChangeState, onEdit, t]);

  return (
    <TabContainer
      title={t('settings.admins')}
      action={
        <Button
          color="primary"
          startContent={<Plus size={18} />}
          onClick={actionsOpen.onVisible}
        >
          {t('invite')}
        </Button>
      }
    >
      <Table
        isLoading={loading}
        columns={columns}
        rows={(data?.userBackoffices as UserBackofficeFragmentFragment[]) || []}
      />

      <NewInvitation
        key={isOpen.toString()}
        isOpen={isOpen}
        item={editItem}
        onHidden={() => {
          actionsOpen.onHidden();
          onEdit(null);
        }}
      />
    </TabContainer>
  );
}
