import { Checkbox, CheckboxGroup } from "@heroui/react";
import useWeekDays from '@/app/app/sessions/hooks/useWeekDays.ts';
import { cn } from '@/lib/utils.ts';

export type WeekDaysProps = {
  label?: string;
  value?: string[];
  isDisabled?: boolean;
  onChange?: (value: string[]) => void;
};

export default function WeekDays({ isDisabled, ...props }: WeekDaysProps) {
  const weekdays = useWeekDays();

  return (
    <CheckboxGroup
      {...props}
      orientation="horizontal"
      className={cn(isDisabled && 'opacity-60 pointer-events-none', 'ml-2')}
      classNames={{
        wrapper: 'flex flex-wrap gap-5',
        label: 'text-sm mb-2'
      }}
    >
      {weekdays.map((day) => (
        <CheckboxDay key={day.value} {...day} />
      ))}
    </CheckboxGroup>
  );
}

export type SingleWeekDaysProps = {
  label?: string;
  value?: string;
  isDisabled?: boolean;
  onChange?: (value?: string) => void;
};

export function SingleWeekDays({
  isDisabled,
  onChange,
  value,
  ...props
}: SingleWeekDaysProps) {
  const weekdays = useWeekDays();

  return (
    <CheckboxGroup
      {...props}
      orientation="horizontal"
      value={value ? [value] : []}
      onChange={(valueProp) => {
        const firstValue = valueProp?.[0];
        const secondValue = valueProp?.[1];

        if (firstValue === value) onChange?.(secondValue);
        else onChange?.(firstValue);
      }}
      className={cn(isDisabled && 'opacity-60 pointer-events-none', 'ml-2')}
      classNames={{
        wrapper: 'flex flex-wrap gap-5',
        label: 'text-sm mb-2'
      }}
    >
      {weekdays.map((day) => (
        <CheckboxDay key={day.value} {...day} />
      ))}
    </CheckboxGroup>
  );
}

function CheckboxDay({ label, value }: { value: string; label: string }) {
  return (
    <Checkbox
      value={value}
      classNames={{
        wrapper: 'relative',
        icon: 'hidden',
        base: cn(
          'border-none before:border-none z-30',
          'w-[35px] h-[35px] p-3 rounded-full',
          'data-[selected=true]:bg-primary bg-gray-300 dark:bg-gray-500 dark:data-[selected=true]:bg-primary'
        ),
        label: cn(
          'absolute top-0 left-0 flex items-center justify-center z-30 bg-inherit',
          'w-full h-full rounded-full text-white text-sm font-bold'
        )
      }}
    >
      {label.slice(0, 1).toUpperCase()}
    </Checkbox>
  );
}
