import { GET_ALL_SESSIONS } from '@/app/app/sessions/store/query/graphql/session.graphql';
import { useAuth } from '../../../../../context/AuthContext';
import { useCalendar } from '@/context/calendar-provider.tsx';
import useApolloInterval from '@/hooks/useApolloInterval.ts';

export function useSessionList() {
  const { activeSpace } = useAuth();
  const { time, date } = useCalendar();
  const query = useApolloInterval(
    GET_ALL_SESSIONS,
    {
      fetchPolicy: 'cache-and-network',
      skip: !date || !time || !activeSpace?.space?.id,
      variables: {
        time: time,
        day: date,
        spaceId: activeSpace?.space?.id as string
      }
    },
    1000 * 15 // 15 seconds
  );

  return {
    ...query,
    fetching: query?.loading,
    loading: query?.loading && !query?.data
  };
}
