import { Table } from '@/lib/ui/table.tsx';
import useColumns from './settings/useColumns.tsx';
import { NotificationResponseDto } from '@jogolabs/notification-sdk';
import { FrontPagination } from '@/hooks/usePagination.ts';

export default function NotificationList({
  data,
  isLoading,
  pagination
}: {
  isLoading?: boolean;
  data: NotificationResponseDto[];
  pagination: FrontPagination;
}) {
  const columns = useColumns();

  return (
    <Table
      rows={data}
      columns={columns}
      isLoading={isLoading}
      getRowId={(_, index) => index}
      pagination={pagination}
    />
  );
}
