import { useNavigate, useParams } from 'react-router-dom';

import Tabs from '@/lib/ui/tabs.tsx';
import AppContainer from '@/app/app/_shared/components/AppContainer.tsx';
import {
  SETTINGS_ROUTES,
  SETTINGS_TAB_ENUM
} from '@/app/app/settings/common/routes.ts';
import PlansTab from '@/app/app/settings/components/Tabs/PlansTab';
import GeneralTab from '@/app/app/settings/components/Tabs/GeneralTab';
import BadgeTab from '@/app/app/settings/components/Tabs/BadgeTab';
import AdminsTab from '@/app/app/settings/components/Tabs/AdminsTab';
import StripeTab from './components/Tabs/Stripe/StripeTab';
import ClassesOfferedTab from '@/app/app/settings/components/Tabs/ClassesOfferedTab';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { useAuth } from '../../../context/AuthContext';
import { ReactNode } from 'react';
import useMediaQuery, { MEDIA_QUERY } from '@/hooks/useMediaQuery.ts';
import { cn } from '@/lib/utils.ts';

const components: Record<SETTINGS_TAB_ENUM, ReactNode> = {
  [SETTINGS_TAB_ENUM.generals]: <GeneralTab />,
  [SETTINGS_TAB_ENUM.class_offered]: <ClassesOfferedTab />,
  [SETTINGS_TAB_ENUM.plans]: <PlansTab />,
  [SETTINGS_TAB_ENUM.categories]: <BadgeTab />,
  [SETTINGS_TAB_ENUM.admins]: <AdminsTab />,
  [SETTINGS_TAB_ENUM.stripe]: <StripeTab />
};

export default function SettingsPage() {
  const navigate = useNavigate();
  const isSmallDevices = useMediaQuery(MEDIA_QUERY.small);

  const { t } = useTranslation();
  const { activeSpace } = useAuth();
  const { tab } = useParams<{ tab: SETTINGS_TAB_ENUM }>();

  const onSelect = (key: string | number | null) => {
    navigate(SETTINGS_ROUTES.TAB(key as SETTINGS_TAB_ENUM));
  };

  return (
    <AppContainer showBreadcrumbs={false} layoutClassName="flex flex-col gap-5">
      <Tabs
        selectedKey={tab}
        variant="underlined"
        isVertical={isSmallDevices}
        onSelectionChange={onSelect}
        classNames={{
          base: cn({
            // 'w-[250px] pr-10': !isSmallDevices,
            'w-full': isSmallDevices
          }),
          tabList: 'w-full'
        }}
        items={[
          {
            key: SETTINGS_TAB_ENUM.generals,
            label: t('settings.generals')
            // children: <GeneralTab />,
          },
          {
            key: SETTINGS_TAB_ENUM.class_offered,
            label: t('settings.class_offered')
            // children: <ClassesOfferedTab />
          },
          {
            key: SETTINGS_TAB_ENUM.plans,
            label: t('settings.plans')
            // children: <PlansTab />
          },
          {
            key: SETTINGS_TAB_ENUM.categories,
            label: t('settings.tags')
            // children: <BadgeTab />
          },
          {
            key: SETTINGS_TAB_ENUM.admins,
            label: t('settings.admins'),
            // children: <AdminsTab />,
            disabled: !activeSpace?.isOwner
          },
          {
            key: SETTINGS_TAB_ENUM.stripe,
            label: 'Stripe',
            // children: <StripeTab />,
            disabled: !activeSpace?.isOwner
          }
        ]}
      />

      <section className="px-3">{components[tab as SETTINGS_TAB_ENUM]}</section>
    </AppContainer>
  );
}
