import { useQuery } from '@apollo/client';
import { useAuth } from '../../../../../context/AuthContext';
import { GET_PLANS_BY_SPACE } from '@/app/app/settings/store/query/graphql/queries.graphql.ts';
import { useEffect } from 'react';

export function useGetPlans(key?: number) {
  const { activeSpace } = useAuth();

  const query = useQuery(GET_PLANS_BY_SPACE, {
    skip: !activeSpace?.id,
    variables: {
      spaceId: activeSpace?.space?.id as string
    }
  });

  useEffect(() => {
    if (key) {
      query.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return query;
}
