import {
  Input as NextUInput,
  InputProps as NextUInputProps
} from "@heroui/react";
import { forwardRef, useState } from 'react';
import { cn } from '@/lib/utils.ts';
import { Eye, EyeOff } from 'lucide-react';

export type InputProps = NextUInputProps & {
  error?: boolean;
  helperText?: string;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ error, helperText, type, endContent, ...props }, ref) => {
    const [isVisible, setIsVisible] = useState(false);

    const onToggleVisibility = () => {
      setIsVisible((prev) => !prev);
    };

    return (
      <NextUInput
        ref={ref}
        {...props}
        isInvalid={error}
        errorMessage={helperText}
        type={type === 'password' ? (!isVisible ? 'password' : 'text') : type}
        className={cn('rounded-xl', props.className)}
        endContent={
          type === 'password' ? (
            <div className="flex gap-1 items-center">
              {endContent}

              <button
                className="focus:outline-none"
                type="button"
                onClick={onToggleVisibility}
                aria-label="toggle password visibility"
              >
                {isVisible ? (
                  <Eye size={18} className="pointer-events-none" />
                ) : (
                  <EyeOff size={18} className="pointer-events-none" />
                )}
              </button>
            </div>
          ) : (
            endContent
          )
        }
      />
    );
  }
);

export default Input;
