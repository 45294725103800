import { useQuery } from '@apollo/client';
import { useAuth } from '../../../../../context/AuthContext';
import {
  GET_CLIENT_NOTIFICATIONS_BASE_QUERY,
  GET_CLIENT_NOTIFICATIONS_ACTIVE_QUERY,
  GET_CLIENT_QUERY,
  GET_CLIENT_SESSIONS_INACTIVE_QUERY,
  GET_CLIENT_SESSIONS_QUERY,
  GET_CLIENTS_QUERY,
  GET_SUBSCRIPTION_BY_CLIENT_QUERY
} from '@/app/app/clients/store/query/graphql/clients.graphql.ts';
import {
  ClientCompleteFragmentFragment,
  ClientsAssociatedFragmentFragment,
  SendUserNotificationFilterEnum,
  UserFragmentFragment
} from '@/__generated__/graphql.ts';
import { OptionItemProps } from '@/lib/ui/select.tsx';
import { ServerPagination } from '@/hooks/usePagination.ts';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

export function useGetClientsList() {
  const { activeSpace } = useAuth();
  const spaceId = activeSpace?.space?.id as string;

  const query = useQuery(GET_CLIENTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !spaceId,
    variables: {
      spaceId: spaceId
    }
  });

  return {
    ...query,
    data: (query?.data?.space?.clients ||
      []) as DeepNonNullable<ClientsAssociatedFragmentFragment>[]
  };
}

export const startMonthDate = dayjs().startOf('month').startOf('day').toDate();

export function useGetClientsForNotifications({
  skip,
  take,
  filter,
  search
}: ServerPagination & {
  search?: string;
  filter?: SendUserNotificationFilterEnum | null;
}) {
  const { activeSpace } = useAuth();
  const [all, setAll] = useState<Record<string, UserFragmentFragment>>({});
  const spaceId = activeSpace?.space?.id as string;

  const clientQuery = useQuery(
    filter === SendUserNotificationFilterEnum.ActiveUsers
      ? GET_CLIENT_NOTIFICATIONS_ACTIVE_QUERY
      : filter === SendUserNotificationFilterEnum.InactiveUsers
        ? GET_CLIENT_SESSIONS_INACTIVE_QUERY
        : GET_CLIENT_NOTIFICATIONS_BASE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      skip: !spaceId,
      variables: {
        spaceId,
        skip,
        take,
        search,
        date: filter ? startMonthDate : undefined
      }
    }
  );

  const clients = useMemo(
    () =>
      (clientQuery?.data?.space?.clients ||
        []) as DeepNonNullable<ClientsAssociatedFragmentFragment>[],
    [clientQuery?.data]
  );

  useEffect(() => {
    if (!clientQuery?.loading) {
      setAll((prev) => {
        clients?.forEach?.((_client) => {
          const client = _client?.user;
          prev[client.id] = client;
        });

        return prev;
      });
    }
  }, [clientQuery?.loading, clients]);

  return {
    ...clientQuery,
    all,
    loading: clientQuery?.loading,
    serverTotalCount: clientQuery?.data?.space?.clientsCount || 0,
    data: clients?.map?.((item) => item?.user) || []
  };
}

export function useGetClient({
  clientId,
  skip,
  take
}: ServerPagination & { clientId?: string }) {
  const clientQuery = useQuery(GET_CLIENT_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !clientId,
    variables: {
      clientId: clientId as string
    }
  });

  const sessionsQuery = useQuery(GET_CLIENT_SESSIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !clientId,
    variables: {
      clientId: clientId as string,
      take,
      skip
    }
  });

  return {
    ...clientQuery,
    loadingClient: clientQuery?.loading,
    loadingSessions: sessionsQuery?.loading,
    data: {
      client: clientQuery?.data
        ?.user as DeepNonNullable<ClientCompleteFragmentFragment>,
      sessions: sessionsQuery?.data
    }
  };
}

export function useGetSubscriptions(clientId?: string) {
  return useQuery(GET_SUBSCRIPTION_BY_CLIENT_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !clientId,
    variables: {
      clientId: clientId as string
    }
  });
}

export function useGetClientsToSelect() {
  const { activeSpace } = useAuth();
  const spaceId = activeSpace?.space?.id as string;

  const query = useQuery(GET_CLIENTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !spaceId,
    variables: {
      spaceId: spaceId
    }
  });

  const clients = useMemo(
    () =>
      (query?.data?.space?.clients ||
        []) as DeepNonNullable<ClientsAssociatedFragmentFragment>[],
    [query?.data]
  );

  return {
    ...query,
    options: (clients?.map?.((_client) => {
      const client = _client?.user;
      return {
        key: client.id,
        value: client.id,
        label: client.fullName,
        description: client?.email,
        iconUrl: client?.avatar?.url,
        iconName: client?.fullName
      };
    }) ?? []) as OptionItemProps[],
    isFetching: query.loading,
    loading: query.loading && !query.data?.space?.clients
  };
}
