import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Avatar } from "@heroui/react";
import {
  GET_COACH_BY_ID_QUERY,
  GET_COACHES_QUERY,
  GET_SESSION_COACH_BY_ID_QUERY
} from '@/app/app/coaches/store/query/graphql/coaches.graphql';
import { useAuth } from '../../../../../context/AuthContext';
import { toast } from 'sonner';
import {
  CoachFragmentFragment,
  SessionFragmentFragment
} from '@/__generated__/graphql.ts';
import { ServerPagination } from '@/hooks/usePagination.ts';

export function useGetCoachList() {
  const { activeSpace } = useAuth();
  const spaceId = activeSpace?.space?.id;

  return useQuery(GET_COACHES_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !spaceId,
    variables: {
      spaceId: spaceId as string
    }
  });
}

const now = new Date();

export function useGetCoachById({
  coachId,
  take,
  skip
}: ServerPagination & {
  coachId: string;
}) {
  const queryCoach = useQuery(GET_COACH_BY_ID_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !coachId,
    onError(error) {
      console.error(error);
      toast.error(error?.message ?? 'Error to get coach data');
    },
    variables: {
      coachId: coachId
    }
  });
  const querySession = useQuery(GET_SESSION_COACH_BY_ID_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !coachId,
    onError(error) {
      console.error(error);
      toast.error(error?.message ?? 'Error to get coach data');
    },
    variables: {
      coachId: coachId,
      skip,
      take,
      date: now
    }
  });

  return {
    coach: queryCoach?.data?.coach as CoachFragmentFragment,
    totalSessionCount: querySession?.data?.totalCount || 0,
    sessions: (querySession?.data?.sessions || []) as SessionFragmentFragment[],
    loadingCoach: queryCoach.loading,
    loadingSession: querySession.loading,
    error: queryCoach.error || querySession.error
  };
}

export function useGetCoachForSelect() {
  const { activeSpace } = useAuth();
  const spaceId = activeSpace?.space?.id;

  const { data, ...rest } = useQuery(GET_COACHES_QUERY, {
    fetchPolicy: 'cache-and-network',
    skip: !spaceId,
    variables: {
      spaceId: spaceId as string
    }
  });

  return {
    data,
    options: useMemo(() => {
      const coaches = data?.coaches as CoachFragmentFragment[];
      return (
        coaches
          ?.filter((coach) => coach?.status === 'active')
          ?.map?.((coach) => ({
            label: (
              <div className="flex gap-2 items-center">
                <Avatar
                  className="w-[25px] h-[25px]"
                  src={coach?.avatar?.url}
                  name={coach?.fullName ?? ''}
                />
                <span>{coach.fullName}</span>
              </div>
            ),
            value: coach.id
          })) ?? []
      );
    }, [data?.coaches]),
    ...rest
  };
}
