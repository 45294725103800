import 'reactivity-hook-form/lib/esm/style.css';
import { Outlet } from 'react-router-dom';

import ExtendLayout from '@/app/layout.tsx';
import PrivateRouter from '@/app/app/_shared/private_router';
import Navbar from '@/app/app/_shared/components/navbar';
import { cn } from '@/lib/utils.ts';
import { useAuth } from '@/context/AuthContext.tsx';
import { ReactNode } from 'react';

export default function AppLayout({ children }: { children?: ReactNode }) {
  return (
    <ExtendLayout>
      <PrivateRouter>
        <Navbar />
        <RenderBody children={children} />
      </PrivateRouter>
    </ExtendLayout>
  );
}

function RenderBody({ children }: { children?: ReactNode }) {
  const { stateAlert } = useAuth();
  return (
    <div
      id="body"
      className={cn('w-full flex flex-1', {
        'pt-[65px]': !stateAlert?.open,
        'pt-[105px]': stateAlert?.open
      })}
    >
      {/*<Aside />*/}

      {/* md:pl-[350px] */}
      <div id="content" className="w-full">
        {children || <Outlet />}
      </div>
    </div>
  );
}
