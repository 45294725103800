import {
  DatePicker as NextUIDatePicker,
  DatePickerProps as NextUIDatePickerProps
} from "@heroui/react";
import dayjs from 'dayjs';
import { parseDate } from '@internationalized/date';

export type DatePickerProps = Omit<
  NextUIDatePickerProps,
  'value' | 'onChange'
> & {
  error?: boolean;
  helperText?: string;
  value?: dayjs.ConfigType;
  onChange?: (value: string) => void;
};

export function DatePicker({
  error,
  helperText,
  onChange,
  value,
  ...rest
}: DatePickerProps) {
  return (
    <NextUIDatePicker
      {...rest}
      isInvalid={error}
      errorMessage={helperText}
      // @ts-ignore
      value={value ? parseDate(dayjs(value).format('YYYY-MM-DD')) : null}
      onChange={(_val) => {
        // @ts-ignore
        const date = dayjs(_val?.toDate?.(dayjs.tz.guess())).toISOString();
        onChange?.(date);
      }}
    />
  );
}
