import { Palette, X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { BlockPicker, HuePicker } from 'react-color';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@heroui/react";

import Input, { InputProps } from '@/lib/ui/input.tsx';
import { cn } from '@/lib/utils.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export type ColorPickerProps = Omit<InputProps, 'onChange'> & {
  onChange?: (value?: string) => void;
};

const JOGO_COLOR = '#017BD9';

export function ColorPicker({
  onChange,
  value,
  placeholder,
  ...rest
}: ColorPickerProps) {
  const popoverRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [completeColor, setCompleteColor] = useState<string>();
  const [color, setColor] = useState<string>(JOGO_COLOR);

  const onCancel = () => {
    popoverRef.current?.click();
  };

  const onReset = () => {
    setColor(JOGO_COLOR);
    setCompleteColor('');
    onChange?.(undefined);
  };

  const onAccept = () => {
    onChange?.(color || undefined);
    popoverRef.current?.click();
  };

  useEffect(() => {
    if (typeof value !== 'undefined') {
      setColor(value);
      setCompleteColor(value);
    }
  }, [value]);

  return (
    <Popover placement="bottom" offset={20} triggerRef={popoverRef}>
      <PopoverTrigger>
        <Input
          {...rest}
          value={completeColor}
          startContent={
            <div
              onClick={rest?.onClick}
              className="absolute flex gap-2 flex-shrink-0 items-center z-10"
            >
              {completeColor ? (
                <>
                  <div>
                    <Palette size="20px" />
                  </div>
                  <span
                    className="block w-[20px] h-[20px] rounded"
                    style={{ backgroundColor: completeColor }}
                  />
                </>
              ) : (
                <>
                  <div>
                    <Palette size="20px" />
                  </div>

                  <span className="text-foreground-500 font-normal w-full text-left text-small truncate">
                    {placeholder}
                  </span>
                </>
              )}
            </div>
          }
          endContent={
            completeColor && (
              <X
                size="20px"
                className="cursor-pointer"
                onClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  onReset();
                }}
              />
            )
          }
          classNames={{
            input: cn(
              'text-left z-20 h-full pt-5',
              color ? 'ps-[55px!important]' : 'ps-[30px!important]'
            ),
            innerWrapper: 'flex'
          }}
        />
      </PopoverTrigger>
      <PopoverContent className="w-auto bg-popover flex flex-col items-center mr-2 p-5 gap-5">
        <BlockPicker
          color={color}
          triangle="hide"
          width="100%"
          className="color-input"
          onChange={(_val) => {
            setColor(_val.hex);
          }}
        />
        <HuePicker
          color={color}
          onChange={(_val) => {
            setColor(_val.hex);
          }}
        />

        <div className="w-full flex justify-between gap-2 items-center">
          <Button color="danger" variant="light" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button
            variant="solid"
            onClick={onAccept}
            color={!color ? 'default' : 'primary'}
            className={!color ? 'opacity-60' : ''}
          >
            {t('ok')}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default ColorPicker;
