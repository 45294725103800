import { useMemo } from 'react';
import { Progress, Tooltip, Avatar, Chip } from "@heroui/react";

import Text from '@/lib/ui/text.tsx';
import { cn, formatTime } from '@/lib/utils.ts';
import PopoverEvent from '../popover-event';
import { EventSchedule } from '@/app/app/sessions/components/sessions-list/types.ts';
import {
  createCardEventStyle,
  isDisabledSession
} from '@/app/app/sessions/common/helper.ts';
import useSessionStatus from '@/hooks/useSessionStatus.ts';
import { CLASSES_STATUS_ENUM } from '@/types/classes.ts';

export default function SessionCard(
  props: EventSchedule & { isDisabled?: boolean; className?: string }
) {
  const {
    className,
    isBacklog,
    coach,
    name,
    color,
    startedAt,
    finishedAt,
    limitQuote,
    subscriptions,
    status,
    subscriptionsCount
  } = props;

  const statusResult = useSessionStatus();

  const utilities = createCardEventStyle({
    color: color,
    type: 'BOARD'
  });

  const valueProgress = useMemo(() => {
    if (!limitQuote || !subscriptionsCount) return 0;
    return (limitQuote / subscriptionsCount) * 100;
  }, [limitQuote, subscriptionsCount]);

  const isDisabled = isDisabledSession(startedAt, isBacklog);

  return (
    <PopoverEvent {...props}>
      <div
        style={utilities.style}
        className={cn(
          'p-2 bg-card border rounded-lg flex flex-col',
          'gap-4 w-64 h-full justify-between relative',
          utilities.className,
          className,
          isDisabled && 'opacity-40'
        )}
      >
        <div className="flex gap-2">
          <div className="w-min flex flex-col justify-center items-center gap-2">
            <Tooltip content={coach?.fullName}>
              <Avatar
                src={coach?.avatar?.url}
                name={coach?.fullName}
                size="sm"
              />
            </Tooltip>
          </div>
          <div className="flex-1">
            <div className="flex justify-between items-center">
              <Text className="text-sm leading-[14px] line-clamp-2">
                {name}
              </Text>

              {status !== CLASSES_STATUS_ENUM.OPEN && (
                <Chip
                  size="sm"
                  variant="flat"
                  color={statusResult.get(status).color}
                >
                  {statusResult.get(status).label}
                </Chip>
              )}
            </div>
            <Text className="text-xs text-gray-500">
              {formatTime(startedAt)} - {formatTime(finishedAt)}
            </Text>
          </div>
        </div>

        <div>
          <div className="flex">
            {subscriptions?.slice(0, 5)?.map?.((subscription) => (
              <Tooltip
                key={subscription?.id}
                content={subscription?.user?.fullName ?? ''}
              >
                <Avatar
                  className="w-5 h-5 mr-[-5px]"
                  src={subscription?.user?.avatar?.url}
                  name={subscription?.user?.fullName?.at(0) ?? ''}
                />
              </Tooltip>
            ))}
            {(subscriptions?.length || 0) > 5 && (
              <Tooltip
                trigger="focus"
                content={
                  <div>
                    {subscriptions?.map?.((sub) => (
                      <Text key={sub?.id} className="text-xs">
                        {sub?.user?.fullName}
                      </Text>
                    ))}
                  </div>
                }
              >
                <Avatar className="w-5 h-5 mr-[-5px]" name="+5" />
              </Tooltip>
            )}
          </div>

          <div className="flex items-center gap-3">
            <Progress value={valueProgress} className="w-full h-[7px]" />

            <Text className="text-xs">
              {subscriptionsCount ?? 0}/{limitQuote ?? 0}
            </Text>
          </div>
        </div>
      </div>
    </PopoverEvent>
  );
}
