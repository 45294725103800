import { toast } from 'sonner';
import { Button } from '@heroui/react';
import { BadgeCheck } from 'lucide-react';

import { getApiErrorText } from '@/lib/utils.ts';
import { InvoiceResponseDto } from '@jogolabs/billing-sdk';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { useBilling } from '@/app/app/billing/context/billing.context.tsx';
import { useRetryPayment } from '@/app/app/billing/store/mutation/billing.mutation.ts';

export default function RetryPayLastPayment() {
  const { t } = useTranslation();
  const { paymentMethod, invoices } = useBilling();
  const { mutateAsync, isPending } = useRetryPayment();

  const handleRetryPayment = async () => {
    const pastDueInvoice = invoices?.data?.find?.(
      (row) => row.status === InvoiceResponseDto.status.OPEN && row?.due_date
    );

    if (!pastDueInvoice) {
      toast.error(t('errors_message.unexpected_error'), {
        description: t('errors_message.user_not_have_stripe_settings')
      });
      return;
    }

    if (!paymentMethod?.data?.id) {
      toast.error(t('errors_message.unexpected_error'), {
        description: t('errors_message.user_not_have_payment_methods')
      });
      return;
    }

    try {
      await mutateAsync({
        paymentMethodId: paymentMethod?.data?.id as string,
        invoiceId: pastDueInvoice?.id as string
      });
    } catch (e) {
      toast.error(t('errors_message.unexpected_error'), {
        description: t('errors_message.original_error', null, {
          error: getApiErrorText(e)
        })
      });
    }
  };

  return (
    <Button
      fullWidth
      color="primary"
      variant="flat"
      isLoading={isPending}
      startContent={<BadgeCheck size={18} />}
      onPress={handleRetryPayment}
    >
      {t('replay_subscription')}
    </Button>
  );
}
