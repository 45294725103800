import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';

import { useTranslation } from '@/hooks/useTranslation';
import {
  useNewClient,
  useUpdateClient
} from '@/app/app/clients/store/mutation/clients.mutation.ts';
import Modal, { ModalActions } from '@/lib/ui/modal.tsx';
import {
  CreateClientMutationVariables,
  UserFragmentFragment
} from '@/__generated__/graphql.ts';
import FormNewOrEditClient from '@/app/app/clients/components/NewOrEditClient/form.tsx';
import { useUpdateSpaceMutation } from '@/app/auth/_store/mutation/auth.mutation.ts';
import { useAuth } from '../../../../../context/AuthContext';

const newClientFormId = 'new-client';

export type ClientFormType = Pick<
  CreateClientMutationVariables['data'],
  'firstName' | 'lastName' | 'email' | 'avatar' | 'paymentPreference'
> & {
  id?: string;
  user?: UserFragmentFragment;
  file?: File | string | null;
};

export default function NewOrEditClient({
  onClose,
  isOpen,
  client
}: {
  client?: ClientFormType;
  isOpen?: boolean;
  onClose?: () => void;
}) {
  const form = useForm<ClientFormType>({
    mode: 'onChange',
    defaultValues: client
  });
  const { t } = useTranslation();
  const { activeSpace } = useAuth();
  const { onNewClient, ...restNewMutation } = useNewClient();
  const { onUpdateUser, ...restUpdateMutation } = useUpdateClient(
    client?.id as string
  );
  const [onUpdateSpace, restUpdateSpace] = useUpdateSpaceMutation();

  const fullLoading =
    restUpdateMutation?.loading ||
    restNewMutation?.loading ||
    restUpdateSpace?.loading;
  const error =
    restUpdateMutation?.error ||
    restNewMutation?.error ||
    restUpdateSpace?.error;

  const onSubmit = async (values: ClientFormType) => {
    let file: File | undefined = undefined;

    if (typeof values?.file === 'string') {
      file = undefined;
    } else if (values?.file instanceof File) {
      file = values?.file;
    }

    delete values.id;
    delete values.file;

    const payload = {
      ...values,
      avatar: file
        ? {
            upload: file
          }
        : undefined
    };

    if (client?.id) {
      await onUpdateUser({
        data: payload
      });
    } else if (values?.email === values?.user?.email) {
      await onUpdateSpace({
        variables: {
          spaceId: activeSpace?.space?.id as string,
          data: {
            clients: {
              create: [
                {
                  user: {
                    connect: {
                      id: values?.user?.id
                    }
                  },
                  space: {
                    connect: {
                      id: activeSpace?.space?.id as string
                    }
                  }
                }
              ]
            }
          }
        }
      });
    } else {
      await onNewClient({
        data: payload
      });
    }
    onClose?.();
  };

  useEffect(() => {
    if (error) {
      toast.error(error.message);
      if (
        error.message.includes(
          'Unique constraint failed on the fields: (`email`)'
        )
      ) {
        form.setError('email', {
          message: error.message
        });
      }
    }
  }, [error, form]);

  return (
    <>
      <Modal
        size="2xl"
        isOpen={isOpen}
        onClose={onClose}
        maskClosable={false}
        title={t(client ? 'clients.update' : 'clients.new')}
      >
        <FormNewOrEditClient
          id={newClientFormId}
          formContext={form}
          onSubmit={onSubmit}
        />

        <ModalActions
          onCancel={onClose}
          isLoading={fullLoading}
          formName={newClientFormId}
          variant={client ? 'edit' : 'save'}
        />
      </Modal>
    </>
  );
}
