import { useQuery } from '@apollo/client';
import { useAuth } from '../../../../../context/AuthContext';
import { GET_USERS_ADMIN_BY_SPACE } from '@/app/app/settings/store/query/graphql/queries.graphql.ts';

export function useUsersQuery() {
  const { activeSpace } = useAuth();

  return useQuery(GET_USERS_ADMIN_BY_SPACE, {
    skip: !activeSpace?.isOwner || !activeSpace?.space?.id,
    variables: {
      spaceId: activeSpace?.space?.id as string
    }
  });
}
