import { Checkbox, Avatar, Button, Chip, Tooltip } from "@heroui/react";

import Text from '@/lib/ui/text.tsx';
import { EventSchedule } from '@/app/app/sessions/components/sessions-list/types.ts';
import Modal from '@/lib/ui/modal.tsx';
import EmptyState from '@/lib/ui/empty-state.tsx';
import { Plus, ExternalLink } from 'lucide-react';
import AddCustomer from '@/app/app/sessions/components/popover-event/add-customer.tsx';
import EditCustomer from '@/app/app/sessions/components/popover-event/edit-customer.tsx';
import DeleteCustomer from '@/app/app/sessions/components/popover-event/delete-customer.tsx';
import useToggle from '@/hooks/useToggle.ts';
import { formatDate } from '@/lib/utils.ts';
import dayjs from 'dayjs';
import useManySelect from '@/hooks/useManySelect.ts';
import Title from '@/lib/ui/title.tsx';
import {
  STATUS_SUBSCRIPTION_OPTIONS,
  StatusItem
} from '@/app/app/sessions/common/constant/status-subscriptions-options-labels.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { SessionSubscriptionBase } from '@/app/app/sessions/components/popover-event/types.ts';
import { ClassEnum } from '@/app/app/sessions/common/enum.ts';
import { CLIENT_ROUTES } from '@/app/app/clients/common/routes.ts';

export default function BookingUsers({
  open,
  onClose,
  name,
  subscriptions,
  startedAt,
  finishedAt,
  id,
  status
}: EventSchedule & {
  open: boolean;
  onClose: () => void;
}) {
  const { selection, onSelect, onSelectAll, onRemoveAll } =
    useManySelect<SessionSubscriptionBase>();
  const { t } = useTranslation();
  const [isNewScreen, actionsIsNewScreen] = useToggle();
  const [isEditScreen, actionsIsEditScreen] = useToggle();
  const [isDeleteScreen, actionsIsDeleteScreen] = useToggle();
  const isExistSubscriptions = !!subscriptions?.length;
  const duration = dayjs(finishedAt).diff(dayjs(startedAt), 'minute');

  // const showList = !isEditScreen && !isDeleteScreen && !isNewScreen;
  const showList = true;

  const isExpiredClass = [
    ClassEnum.CANCELED,
    ClassEnum.IN_PROGRESS,
    ClassEnum.FINISHED
  ].includes(status as ClassEnum);

  return (
    <Modal
      size="full"
      title={name}
      isOpen={open}
      onClose={onClose}
      description={
        <span>
          {formatDate(startedAt)} - {formatDate(finishedAt)} - {duration} min
        </span>
      }
    >
      <div className="mt-5 flex-1 flex flex-col gap-2 max-w-[850px] w-full mx-auto">
        <AddCustomer
          sessionId={id}
          isOpen={isNewScreen}
          onClose={actionsIsNewScreen.onHidden}
          isExpiredClass={isExpiredClass}
          disabledUserIds={
            subscriptions
              ?.filter((item) => !!item?.user?.id)
              ?.map?.((item) => item?.user?.id as string) ?? []
          }
        />

        <EditCustomer
          onOk={onRemoveAll}
          sessions={selection}
          isOpen={isEditScreen}
          isExpiredClass={isExpiredClass}
          onClose={actionsIsEditScreen.onHidden}
        />

        <DeleteCustomer
          onOk={onRemoveAll}
          sessions={selection}
          isOpen={isDeleteScreen}
          onClose={actionsIsDeleteScreen.onHidden}
        />

        <div className={!showList ? 'hidden' : ''}>
          <div className="flex gap-2 items-center justify-between mb-5">
            <div className="gap-2 flex">
              {!selection.length ? (
                <Title size="xs" className="mb-0">
                  {t('clients.title')}
                </Title>
              ) : (
                <>
                  <Button
                    size="sm"
                    color="primary"
                    variant="bordered"
                    onClick={actionsIsEditScreen.onVisible}
                    // startContent={<Plus size="16px" />}
                  >
                    {t('change_state')}
                  </Button>

                  <Button
                    size="sm"
                    color="danger"
                    variant="bordered"
                    onClick={actionsIsDeleteScreen.onVisible}
                    // startContent={<Plus size="16px" />}
                  >
                    {t('delete')}
                  </Button>
                </>
              )}
            </div>

            <div className="text-xs flex gap-2 text-gray-500">
              <Button
                size="sm"
                color="primary"
                variant="bordered"
                onClick={actionsIsNewScreen.onVisible}
                startContent={<Plus size="16px" />}
              >
                {t('add_customer')}
              </Button>
            </div>
          </div>

          {isExistSubscriptions && (
            <Checkbox
              className="mb-1"
              isSelected={subscriptions?.length === selection.length}
              onChange={(event) => {
                event.target.checked
                  ? onSelectAll(subscriptions as Array<SessionSubscriptionBase>)
                  : onRemoveAll();
              }}
            >
              <span className="text-gray-500 text-sm">{t('select_all')}</span>
            </Checkbox>
          )}

          {!isExistSubscriptions ? (
            <EmptyState
              className="mt-20"
              title={t('clients.empty')}
              footer={
                <Button
                  color="primary"
                  variant="bordered"
                  onClick={actionsIsNewScreen.onVisible}
                  startContent={<Plus size="16px" />}
                >
                  {t('add_customer')}
                </Button>
              }
            />
          ) : (
            subscriptions.map((subscription) => {
              const status =
                STATUS_SUBSCRIPTION_OPTIONS?.[
                  subscription?.status as StatusItem
                ];

              return (
                <div
                  key={subscription?.id}
                  className="flex gap-2 items-center justify-between"
                >
                  <div className="flex gap-2 items-center">
                    <Checkbox
                      onChange={() =>
                        onSelect(subscription as SessionSubscriptionBase)
                      }
                      isSelected={
                        !!selection?.find?.(
                          (item) => item?.id === subscription?.id
                        )
                      }
                    />

                    <div className="flex gap-3 items-center">
                      <Avatar
                        src={subscription?.user?.avatar?.url}
                        name={subscription?.user?.fullName ?? ''}
                      />

                      <Text>{subscription?.user?.fullName}</Text>
                    </div>
                  </div>

                  <div className="flex items-center gap-1">
                    <Chip size="sm" color={status?.color} variant="flat">
                      {t(status?.label)}
                    </Chip>

                    <Tooltip content={t('open_in_new_tab')}>
                      <Button
                        isIconOnly
                        variant="light"
                        startContent={<ExternalLink size={18} />}
                        onClick={() =>
                          window.open(
                            CLIENT_ROUTES.CLIENT_DETAILS(
                              subscription?.user?.id as string
                            )
                          )
                        }
                      />
                    </Tooltip>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </Modal>
  );
}
