import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import { AxiosError } from 'axios';
import { ApolloError } from '@apollo/client';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDateComplete(
  date: dayjs.ConfigType,
  opts?: {
    showEmptyDate?: boolean;
    format?: 'date' | 'datetime';
  }
) {
  const { showEmptyDate, format } = {
    showEmptyDate: true,
    format: 'date',
    ...opts
  };

  if (!date && !showEmptyDate) {
    return '-';
  }
  return dayjs(date).format(
    format === 'date' ? 'DD MMMM YYYY' : 'DD MMMM YYYY hh:mm A'
  );
}

export function formatDate(date: dayjs.ConfigType, showEmptyDate = true) {
  if (!date && !showEmptyDate) {
    return '-';
  }
  return dayjs(date).format('DD/MM/YYYY');
}

export function formatTime(date: dayjs.ConfigType) {
  return dayjs(date).format('hh:mm A');
}

export type FormatMoneyOptions = {
  symbol?: '$' | 'usd' | 'none';
  forceTwoDecimals?: boolean;
  withSign?: boolean;
};

export function formatMoney(
  value: BigNumber.Value | undefined,
  options?: FormatMoneyOptions
) {
  const opts: Required<FormatMoneyOptions> = {
    symbol: '$',
    withSign: false,
    forceTwoDecimals: true,
    ...options
  };
  const amount = new BigNumber(value ?? '').dp(2);
  if (amount.isNaN()) return '-';

  const hasDecimals = !amount.isInteger();
  const formattedAmount =
    hasDecimals || opts.forceTwoDecimals
      ? amount.toFixed(2)
      : amount.toString();

  if (opts.symbol === 'none') {
    if (opts?.withSign) {
      return amount.isPositive() ? `+${formattedAmount}` : formattedAmount;
    }
    return formattedAmount;
  }
  return [
    opts?.withSign ? (amount?.isPositive() ? '+' : '-') : '',
    opts.symbol.toUpperCase(),
    formattedAmount.replace('-', '')
  ]
    .filter(Boolean)
    .join(opts.symbol === '$' ? '' : ' ');
}

type ServerResponse = {
  body: {
    error?: any;
    message: string;
  };
};

const isServerResponse = (e: any): e is ServerResponse => !!e.body;

export function getApiErrorText(e: any) {
  if (e instanceof ApolloError) {
    console.log('ApolloError', e?.message);
    return (
      e?.message ||
      e?.graphQLErrors?.map?.((item) => item.message)?.join?.(', ') ||
      e?.clientErrors?.map?.((item) => item.message)?.join?.(', ') ||
      e
    );
  }
  if (isServerResponse(e)) {
    if (typeof e.body?.error === 'string') {
      return e.body?.error;
    }
    return e.body?.message || e?.body || e;
  }
  if (e instanceof AxiosError) {
    if (typeof e.response?.data?.error === 'string') {
      return e.response?.data?.error;
    }
    return e.response?.data?.message || e?.message || e;
  }
  if (e instanceof Error) {
    return e?.message || e;
  }
  return e;
}
