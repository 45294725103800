import { Card, CardBody } from "@heroui/react";

import Text from '@/lib/ui/text.tsx';
import { Table } from '@/lib/ui/table.tsx';
import ShortId from '@/lib/ui/short-id.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { useGetTotalRevenue } from '@/app/app/dashboard/store/query/dashboard.query.tsx';
import Title from '@/lib/ui/title.tsx';
import DateCell from '@/lib/ui/date-cell.tsx';
import { formatMoney } from '@/lib/utils.ts';

export default function LastPayments({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { data, loading } = useGetTotalRevenue();

  const payments = data?.currentPayments;

  return (
    <Card className={className}>
      <CardBody>
        <div className="flex flex-col gap-4 p-4">
          <Title size="lg" className="mb-0">
            {t('last_payments')}
          </Title>
          <Table
            isLoading={loading}
            rows={[...(payments || [])]?.splice?.(0, 20)}
            columns={[
              {
                field: 'id',
                headerName: 'ID',
                renderCell({ row }) {
                  return <ShortId slice={3}>{row?.id || ''}</ShortId>;
                }
              },
              {
                field: 'name',
                headerName: t('user'),
                renderCell({ row }) {
                  return (
                    <div className="flex gap-2 items-center">
                      <Text>{row.user?.fullName}</Text>
                    </div>
                  );
                }
              },
              {
                field: 'createdAt',
                headerName: t('date'),
                renderCell({ row }) {
                  return <DateCell format="datetime" date={row?.createdAt} />;
                }
              },
              {
                field: 'amount',
                headerName: 'Monto',
                renderCell({ row }) {
                  return <Text>{formatMoney(row.amount)}</Text>;
                }
              },
              {
                field: 'method',
                headerName: t('payment_methods'),
                renderCell({ row }) {
                  return t(`methods_payment.${row?.method}` as LanguageKeys);
                }
              }
            ]}
          />
        </div>
      </CardBody>
    </Card>
  );
}
