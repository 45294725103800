import { ReactNode, useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { Radio, RadioGroup } from "@heroui/react";

type RadioButtonProps<TValue = string> = {
  label: ReactNode;
  value: TValue;
  checked?: boolean;
  onChange?: (value: TValue) => void;
};

export default function RadioGroupButton<TValue = string>({
  options,
  onChange,
  className,
  orientation,
  value: currentValue
}: {
  className?: string;
  orientation?: 'horizontal' | 'vertical';
  value?: RadioButtonProps<TValue>['value'];
  onChange?: RadioButtonProps<TValue>['onChange'];
  options: RadioButtonProps<TValue>[];
}) {
  const [internalValue, setInternalValue] = useState(currentValue);

  useEffect(() => {
    setInternalValue(currentValue);
  }, [currentValue]);

  return (
    <RadioGroup
      className={cn(className)}
      orientation={orientation}
      value={internalValue as string}
      onValueChange={(value) => {
        setInternalValue(value as TValue);
        onChange?.(value as TValue);
      }}
    >
      {options.map(({ value, label }, index) => {
        const key = `${value?.toString?.()}-${index}`;

        return (
          <Radio key={key} value={value as string}>
            {label}
          </Radio>
        );
      })}
    </RadioGroup>
  );
}
