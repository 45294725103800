import './style.css';
import { Button, Drawer as HeroDrawer, DrawerContent, DrawerBody, DrawerHeader, DrawerProps } from "@heroui/react";
import { PropsWithChildren } from 'react';
import Text from '@/lib/ui/text.tsx';
import { X } from 'lucide-react';

export default function Drawer({
  isOpen,
  onClose,
  children,
  title,
  description,
  closable = true,
  ...restProps
}: PropsWithChildren<
  Pick<DrawerProps, 'onClose'> & {
    isOpen: boolean;
    closable?: boolean;
    title?: string;
    description?: string;
  }
>) {
  return (
    <HeroDrawer
      hideCloseButton
      isOpen={isOpen}
      onOpenChange={(open) => !open && onClose?.()}
      {...restProps}
      className={isOpen ? 'animate-slide-right-left' : ''}
    >
      <DrawerContent>
        {(onInternalClose) => {
          return <>
            {closable && (
              <Button
                isIconOnly
                content="Close"
                variant="light"
                onPress={onInternalClose}
                className="absolute top-2 right-2 z-50"
              >
                <X />
              </Button>
            )}
            {(title || description) && (
              <DrawerHeader className="sticky top-0 left-0 flex flex-col gap-1 bg-background shadow-sm z-40">
                {title}
                <Text className="text-xs">{description}</Text>
              </DrawerHeader>
            )}
            <DrawerBody>{children}</DrawerBody>
          </>
        }}
      </DrawerContent>
    </HeroDrawer>
  );
}
