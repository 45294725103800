import {
  Avatar,
  Autocomplete as AutocompletePrimitive,
  AutocompleteItem,
  AutocompleteProps as AutocompleteNextProps
} from "@heroui/react";
import { ReactNode } from 'react';

export type Key = string | number;

export type OptionItemProps = {
  label: ReactNode;
  value: Key;
  iconUrl?: string;
  iconName?: string;
  startAdornment?: ReactNode;
  description?: ReactNode;
};

export type AutocompleteProps = Pick<
  AutocompleteNextProps,
  | 'disabledKeys'
  | 'placeholder'
  | 'key'
  | 'className'
  | 'onClick'
  | 'label'
  | 'name'
  | 'startContent'
  | 'endContent'
  | 'size'
  | 'fullWidth'
  | 'isLoading'
  | 'isDisabled'
  | 'variant'
> & {
  options: OptionItemProps[];
  error?: boolean;
  value?: Key;
  onChange?: (value: Key) => void;
  helperText?: string;
};

export default function Autocomplete({
  options,
  error,
  helperText,
  onChange,
  disabledKeys,
  ...props
}: AutocompleteProps) {
  const onInternalChange = (opt: string | number | null) => {
    return onChange?.(opt as Key);
  };

  return (
    <AutocompletePrimitive<any>
      {...props}
      defaultItems={options}
      isInvalid={error}
      errorMessage={helperText}
      disabledKeys={disabledKeys}
      selectedKey={props?.value}
      onSelectionChange={onInternalChange}
    >
      {(option: OptionItemProps) => {
        return (
          <AutocompleteItem
            key={option?.value}
            value={option?.value}
            title={option?.label}
            description={option?.description}
            startContent={
              option?.startAdornment ||
              ((option?.iconUrl || option?.iconName) && (
                <Avatar
                  size="sm"
                  radius="sm"
                  className="flex-shrink-0"
                  name={option?.iconName}
                  src={option.iconUrl}
                />
              ))
            }
          />
        );
      }}
    </AutocompletePrimitive>
  );
}
