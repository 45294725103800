import Container from '@/lib/ui/container.tsx';
import TotalRevenue from '@/app/app/dashboard/components/TotalRevenue/TotalRevenue.tsx';
import {
  ActivityGraph,
  PaymentMethodGraph
} from '@/app/app/dashboard/components/Graphs';
import Grid from '@/lib/ui/grid';
import Tabs from '@/lib/ui/tabs.tsx';
import { useAuth } from '@/context/AuthContext.tsx';
import TodaySessions from '@/app/app/dashboard/components/TodaySessions';
import CustomerService from '@/app/app/dashboard/components/CustomerService';
import UpcomingRenewals from '@/app/app/dashboard/components/UpcomingRenewals';
import TotalRevenueByClient from '@/app/app/dashboard/components/TotalRevenueByClient';
import TotalActiveCustomers from '@/app/app/dashboard/components/TotalActiveCustomers';
import Title from '@/lib/ui/title.tsx';
import Select from '@/lib/ui/select.tsx';
import { Button, Chip, DateRangePicker } from "@heroui/react";
import {
  DateRangeEnum,
  useDashboardFilters,
  useGetFilterOptions
} from '@/app/app/dashboard/context/filters.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { ListFilter } from 'lucide-react';
import { CustomersGraph } from '@/app/app/dashboard/components/Graphs/CustomersGraph.tsx';
import { ActivityByWeekGraph } from '@/app/app/dashboard/components/Graphs/ActivityByWeekGraph.tsx';
import { PlanGraph } from '@/app/app/dashboard/components/Graphs/PlanGraph.tsx';
import LastPayments from '@/app/app/dashboard/components/LastPayments';
import { ActivityByCoachGraph } from '@/app/app/dashboard/components/Graphs/ActivityByCoachGraph.tsx';
import dayjs from 'dayjs';

export default function Dashboard() {
  const { activeSpace } = useAuth();
  const { t, lang } = useTranslation();
  const filters = useDashboardFilters();
  const OPTIONS_FILTER = useGetFilterOptions();

  const isOwner = activeSpace?.isOwner;

  return (
    <Container size="xl" className="flex flex-col gap-12">
      <Grid gutter={[24, 24]}>
        <Grid.Item xs={24}>
          <div className="flex flex-col gap-2 md:flex-row md:justify-between md:items-center">
            <div>
              <Title size="lg">Dashboard</Title>
              <div className="flex flex-row gap-1 items-center">
                <Chip size="sm" radius="sm" color="warning" variant="flat">
                  {t(
                    `filter_dashboard.${filters?.dateRange}`,
                    filters?.dateRange
                  )}
                </Chip>

                <Chip size="sm" radius="sm" color="primary" variant="flat">
                  {[
                    filters.startDate.locale(lang).format('DD MMM YYYY'),
                    filters.endDate.locale(lang).format('DD MMM YYYY')
                  ].join(' - ')}
                </Chip>
              </div>
            </div>

            <div className="flex justify-between gap-2 md:justify-start">
              <div className="w-[180px]">
                <Select
                  startContent={<ListFilter />}
                  options={OPTIONS_FILTER}
                  value={filters.dateRange}
                  onChange={(key) =>
                    filters.onChangeDateRange(key as DateRangeEnum)
                  }
                />
              </div>

              {filters?.dateRange === DateRangeEnum.CUSTOM && (
                <DateRangePicker
                  size="md"
                  className="max-w-xs"
                  onChange={(date) => {
                    return useDashboardFilters.setState({
                      // @ts-expect-error
                      startDate: dayjs(date?.start?.toDate?.(dayjs.tz.guess())),
                      // @ts-expect-error
                      endDate: dayjs(date?.end?.toDate?.(dayjs.tz.guess()))
                    });
                  }}
                />
              )}

              <Button
                variant="light"
                color="primary"
                className="flex-shrink-0"
                onClick={() => filters.onChangeDateRange(null)}
              >
                {t('clear_all')}
              </Button>
            </div>
          </div>
        </Grid.Item>

        {isOwner && (
          <Grid.Item xs={24} sm={12} md={8}>
            <TotalRevenue />
          </Grid.Item>
        )}
        {isOwner && (
          <Grid.Item xs={24} sm={12} md={8}>
            <TotalRevenueByClient />
          </Grid.Item>
        )}
        <Grid.Item xs={24} sm={12} md={8}>
          <TotalActiveCustomers />
        </Grid.Item>
        {/*<Grid.Item xs={24} sm={12} md={6}>*/}
        {/*  <TotalInactiveCustomers />*/}
        {/*</Grid.Item>*/}
      </Grid>

      {/*<Greetings className="w-full hidden lg:block" />*/}

      <div>
        <Grid gutter={[24, 24]} className="mt-3">
          {/* left column or mobile second column */}
          <Grid.Item xs={24} lg={16} className="order-1">
            <Tabs
              classNames={{
                base: 'w-full'
              }}
              items={[
                {
                  key: 'general',
                  label: t('overview'),
                  children: (
                    <div className="flex flex-col gap-6">
                      <ActivityByWeekGraph />
                      <ActivityGraph className="w-full" />
                    </div>
                  )
                },
                {
                  key: 'memberships',
                  label: t('memberships'),
                  children: (
                    <div className="flex flex-col gap-6">
                      <CustomersGraph />
                      <PlanGraph />
                      <UpcomingRenewals />
                    </div>
                  )
                },
                {
                  key: 'coaches',
                  label: t('coach.title'),
                  disabled: !isOwner,
                  children: (
                    <div className="flex flex-col gap-6">
                      <ActivityByCoachGraph />
                    </div>
                  )
                },
                {
                  key: 'finance',
                  label: t('finances'),
                  children: (
                    <div className="flex flex-col gap-6">
                      {isOwner && <PaymentMethodGraph />}
                      <LastPayments />
                    </div>
                  )
                }
              ]}
            />
            <div className="md:h-[100px]" />
          </Grid.Item>

          {/* right column or mobile first column */}
          <Grid.Item xs={24} lg={8} className="order-2">
            <div className="flex flex-col gap-6 mt-[53px]">
              {/*<Greetings className="w-full block lg:hidden" />*/}
              <CustomerService className="lg:hidden" />
              <TodaySessions key="today-sessions" />
              <CustomerService className="hidden lg:block" />
            </div>
          </Grid.Item>
        </Grid>
      </div>
    </Container>
  );
}
