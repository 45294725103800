import { ModalProps as NextUIModalProps } from "@heroui/react";

import { useTranslation } from '@/hooks/useTranslation.ts';
import Text from '@/lib/ui/text.tsx';
import Modal, { ModalActions } from '@/lib/ui/modal.tsx';
import { useCancelSubscription } from '@/app/app/sessions/store/mutation/subscription.mutation.ts';
import { SessionSubscriptionBase } from '@/app/app/sessions/components/popover-event/types.ts';

export default function DeleteCustomer({
  onClose,
  sessions,
  onOk,
  isOpen
}: Pick<NextUIModalProps, 'onClose' | 'isOpen'> & {
  onOk: () => void;
  sessions: Array<SessionSubscriptionBase>;
}) {
  const { t } = useTranslation();
  const { onCancelSubscription, loading } = useCancelSubscription();

  const onSubmit = async () => {
    for (const session of sessions) {
      try {
        const { errors } = await onCancelSubscription({
          sessionId: session.id,
          userId: session.user.id
        });
        if (errors) {
          const errorText =
            errors?.[0]?.message ||
            (errors as unknown as Error)?.message ||
            'Something went wrong';

          throw new Error(errorText);
        }
        onOk();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="pt-7">
      <div>
        <Text className="text-center font-bold mb-0">
          {t(
            sessions?.length > 1
              ? 'subscriptions_delete.message_title_plural'
              : 'subscriptions_delete.message_title_single'
          )}
        </Text>
        <Text className="text-center text-sm mt-2">
          {t('subscriptions_delete.message_summary')}
        </Text>
      </div>

      <ModalActions
        variant="delete"
        onCancel={onClose}
        onOk={onSubmit}
        isLoading={loading}
      />
    </Modal>
  );
}
