import './style.css';
import { useEffect } from 'react';
import { toast } from 'sonner';
import { Button } from "@heroui/react";
import { CheckCircle } from 'lucide-react';

import Text from '@/lib/ui/text.tsx';
import { useAuth } from '../../../context/AuthContext';
import { useTranslation } from '@/hooks/useTranslation.ts';
import Input from '@/lib/ui/input.tsx';
import Form, { FormItem, FormValidations } from 'reactivity-hook-form';
import { resetPasswordInFirebase } from '@/lib/firebase.ts';
import useToggle from '@/hooks/useToggle.ts';
import { useNavigate } from 'react-router-dom';
import { AUTH_ROUTES } from '@/app/auth/_common/routes.ts';

type ResendEmailFormType = {
  email: string;
};

const useValidations = (): FormValidations<ResendEmailFormType> => {
  const { t } = useTranslation();

  return {
    email: {
      required: 'required',
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: t('validations.email')
      }
    }
  };
};

export default function ResetPasswordPage() {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [isSuccess, toggleActions] = useToggle();
  const validations = useValidations();

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: ResendEmailFormType) => {
    try {
      await resetPasswordInFirebase(values?.email);
      toggleActions.onToggle();
    } catch (error) {
      const e = error as { code?: string; message?: string };
      toast.error(t(`errors_message.${e?.code}` as LanguageKeys, e?.code));
    }
  };

  return (
    <main className="w-full flex h-screen p-5 flex-col items-center">
      <div className="md:w-[50%] md:h-full flex justify-center items-center py-10">
        <div className="text-left w-full lg:max-w-[450px] border p-10 rounded-md">
          {isSuccess ? (
            <>
              <CheckCircle size={90} className="text-success mx-auto mb-5" />
              <Text className="text-center">
                {t('reset_password_email_sent')}
              </Text>
              <Button
                onClick={() => navigate(AUTH_ROUTES.LOGIN)}
                fullWidth
                variant="flat"
                color="success"
                className="mt-5"
              >
                {t('go_to_home')}
              </Button>
            </>
          ) : (
            <>
              <img
                src="/icon.png"
                width={120}
                className="mb-10 hidden md:block"
              />

              <Text className="mb-5">{t('reset_password_message')}</Text>

              <Form<ResendEmailFormType>
                validations={validations}
                onSubmit={onSubmit}
              >
                <FormItem<ResendEmailFormType> name="email">
                  <Input label="Email" isRequired />
                </FormItem>

                <FormItem<ResendEmailFormType>>
                  {({ formState }) => (
                    <Button
                      type="submit"
                      color="primary"
                      fullWidth
                      isDisabled={!formState?.isValid}
                      isLoading={formState?.isSubmitting}
                    >
                      {t('reset_password')}
                    </Button>
                  )}
                </FormItem>
              </Form>
            </>
          )}
        </div>
      </div>
    </main>
  );
}
