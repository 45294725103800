import {
  Avatar,
  Select as SelectPrimitive,
  SelectItem,
  SelectProps as SelectNextProps
} from "@heroui/react";
import { ChangeEvent, isValidElement, ReactNode, useMemo } from 'react';
import EmptyState from '@/lib/ui/empty-state.tsx';

export type Key = string | number;

export type OptionItemProps = {
  label: ReactNode;
  value: Key;
  iconUrl?: string;
  iconName?: string;
  startAdornment?: ReactNode;
  description?: ReactNode;
};

export type SelectProps = Omit<
  SelectNextProps,
  'children' | 'onChange' | 'value' | 'isRequired' | 'multiple'
> & {
  options: OptionItemProps[];
  error?: boolean;
  helperText?: string;
};

export type SelectSingleProps = SelectProps & {
  value?: Key;
  multiple?: false;
  onChange?: (value: Key) => void;
};

export type SelectMultipleProps = SelectProps & {
  value?: Key[];
  multiple: true;
  onChange?: (value: Key[]) => void;
};

function Select({
  options,
  error,
  multiple,
  helperText,
  onChange,
  disabledKeys,
  ...props
}: SelectSingleProps | SelectMultipleProps) {
  const selectedKeys = useMemo(() => {
    if (!props.value) return [];
    if (Array.isArray(props.value)) return props.value;
    return [props.value];
  }, [props.value]);

  const onInternalChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event?.target?.value;
    if (multiple) {
      return onChange?.(value?.split?.(',')?.filter?.(Boolean) || []);
    }
    return onChange?.(value);
  };

  return (
    <SelectPrimitive
      {...props}
      items={options}
      isInvalid={error}
      disabledKeys={disabledKeys}
      errorMessage={helperText}
      selectedKeys={selectedKeys}
      onChange={onInternalChange}
      value={props?.value as string}
      selectionMode={multiple ? 'multiple' : 'single'}
      renderValue={(items) => {
        const isBasicItems = items.every((item) => {
          return (
            !item?.data?.iconUrl &&
            !item?.data?.startAdornment &&
            !item?.data?.description &&
            !isValidElement(item?.data?.label)
          );
        });

        if (isBasicItems) {
          return items.map((item) => item.data?.label).join(', ');
        }

        return (
          <div className="flex flex-wrap gap-2">
            {items.map((item) => {
              return (
                <div key={item.key} className="flex items-center gap-2">
                  {item?.data?.startAdornment ||
                    ((item?.data?.iconUrl || item?.data?.iconName) && (
                      <Avatar
                        size="sm"
                        radius="sm"
                        className="flex-shrink-0"
                        name={item.data?.iconName}
                        src={item.data?.iconUrl}
                      />
                    ))}
                  <div className="flex flex-col">
                    <span>{item.data?.label}</span>
                    {item?.data?.description && (
                      <span className="text-default-500 text-tiny">
                        {item.data.description}
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }}
    >
      {!options?.length ? (
        <SelectItem
          key="empty"
          classNames={{
            base: 'bg-[transparent!important] cursor-default'
          }}
          onClick={(e) => e?.stopPropagation()}
        >
          <EmptyState className="max-w-[150px]" title="Not data." />
        </SelectItem>
      ) : (
        (option) => (
          <SelectItem
            key={option?.value}
            value={option?.value}
            title={option?.label}
            description={option?.description}
            startContent={
              option?.startAdornment ||
              ((option?.iconUrl || option?.iconName) && (
                <Avatar
                  size="sm"
                  radius="sm"
                  className="flex-shrink-0"
                  name={option?.iconName}
                  src={option.iconUrl}
                />
              ))
            }
          />
        )
      )}
    </SelectPrimitive>
  );
}

export default Select;
