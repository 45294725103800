import { Outlet } from 'react-router-dom';

import RootLayout from '@/app/layout.tsx';
import { Button } from "@heroui/react";
import { useTheme } from '@/context/theme-provider.tsx';
import { Moon } from '@/lib/ui/icons';
import { ChangeLocale } from '@/lib/ui/change-locale.tsx';

export default function AuthLayout() {
  return (
    <RootLayout>
      <div className="relative">
        <div className="absolute top-0 left-0 w-full p-4 flex justify-end items-center gap-2">
          <ChangeLocale />
          <ChangeTheme />
        </div>
        <Outlet />
      </div>
    </RootLayout>
  );
}

function ChangeTheme() {
  const { setTheme, theme } = useTheme();

  return (
    <Button
      isIconOnly
      onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
    >
      <Moon />
    </Button>
  );
}
