import { X } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Button, Spinner } from "@heroui/react";

import Input, { InputProps } from '@/lib/ui/input.tsx';
import { useVerifyEmail } from '@/lib/ui/email-input-fetch/query.ts';
import Text from '@/lib/ui/text.tsx';
import { useDebounce } from '@/hooks/useDebounce.ts';
import { UserFragmentFragment } from '@/__generated__/graphql.ts';
import { isEmail } from '@/utils';
import { cn } from '@/lib/utils.ts';

const defaultUser: UserFragmentFragment = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  fullName: '',
  state: '',
  avatar: null
};

export default function EmailInputFetch({
  user,
  onLinkUser,
  onCleanUser,
  removeOnEmpty = false,
  ...props
}: InputProps & {
  removeOnEmpty?: boolean;
  user: UserFragmentFragment | null;
  onLinkUser: (user: UserFragmentFragment) => void;
  onCleanUser?: (userId: string) => void;
}) {
  const [text, setText] = useState<string>('');
  const { onVerifyEmail, data, loading } = useVerifyEmail();

  const onChange = useDebounce(
    useCallback(
      async (value: string) => {
        const isValidEmail = isEmail(value);
        if (!isValidEmail || !value) return;
        const isExistUser = await onVerifyEmail(value);
        if (!isExistUser && !!user?.id && removeOnEmpty) {
          onCleanUser?.('');
        }
      },
      [onCleanUser, onVerifyEmail, user?.id, removeOnEmpty]
    )
  );

  useEffect(() => {
    if (props?.value !== undefined) {
      setText((val) => (props?.value !== val ? props?.value : val) as string);
    }
  }, [props?.value]);

  const isSelected = useMemo(
    () => !!user && !!data && user?.id === data?.id,
    [user, data]
  );

  return (
    <>
      <Input
        {...props}
        value={text}
        onChange={(e) => {
          props?.onChange?.(e);
          setText(e.target.value);
          onChange(e.target.value);
        }}
        endContent={
          isSelected ? (
            <div
              className="cursor-pointer"
              onClick={() => {
                onLinkUser(defaultUser);
                return onVerifyEmail('');
              }}
            >
              <X size={18} />
            </div>
          ) : (
            loading && <Spinner size="sm" />
          )
        }
      />
      {data && (
        <div
          className={cn(
            'flex gap-4 mt-8 mb-4',
            isSelected && 'opacity-50 pointer-events-none'
          )}
        >
          <Avatar src={data?.avatar?.url} name={data?.fullName as string} />
          <div className="flex-1">
            <Text className="text-sm font-bold">{data?.fullName}</Text>
            <Text className="text-xs">{data?.email}</Text>
          </div>
          <Button
            variant="light"
            color="primary"
            onClick={() => onLinkUser(data)}
          >
            Use profile
          </Button>
        </div>
      )}
    </>
  );
}
