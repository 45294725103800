export const API_URL = import.meta.env.VITE_API_URL || '';

export const NOTIFICATION_SERVICE_URL = import.meta.env.VITE_NOTIFICATION_SERVICE_URL || '';

export const API_URL_GRAPHQL = new URL('api/graphql', API_URL).toString();

export const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '';

export const STRIPE_PUBLISHABLE_KEY_INVOICES = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY_INVOICES || '';

export const BILLING_SERVICE_URL = import.meta.env.VITE_BILLING_SERVICE_URL || '';
