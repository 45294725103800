import Select, { SelectMultipleProps } from '@/lib/ui/select.tsx';
import { useGetSessionsCategories } from '@/app/app/settings/store/query/session_categories.query.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import {
  Button,
  Chip,
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@heroui/react";
import { useMemo } from 'react';
import Text from '@/lib/ui/text.tsx';
import { useCreateOrEditSession } from '@/app/app/sessions/components/create-edit-session/context';
import { useNavigate } from 'react-router-dom';
import {
  SETTINGS_ROUTES,
  SETTINGS_TAB_ENUM
} from '@/app/app/settings/common/routes.ts';

export default function SelectSessionCategories(
  props: Omit<SelectMultipleProps, 'options' | 'multiple'>
) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onClose } = useCreateOrEditSession();
  const categoriesQuery = useGetSessionsCategories(1);

  const options = useMemo(() => {
    return (categoriesQuery?.data?.sessionCategories || []).map((item) => ({
      label: item.name,
      value: item.id
    }));
  }, [categoriesQuery?.data?.sessionCategories]);

  if (!categoriesQuery.loading && !options.length) {
    return (
      <Popover showArrow offset={10} placement="bottom">
        <PopoverTrigger>
          <div>
            <Select
              {...props}
              multiple
              options={options}
              className="pointer-events-none"
              label={t('settings.categories')}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </PopoverTrigger>

        <PopoverContent className="w-[240px]">
          <div className="px-1 py-2">
            <Text className="font-bold text-md">
              {t('categories_not_found')}
            </Text>
            <Text>{t('categories_not_found_desc')}</Text>
            <div className="flex justify-end mt-5">
              <Button
                color="primary"
                onClick={() => {
                  navigate(SETTINGS_ROUTES.TAB(SETTINGS_TAB_ENUM.plans));
                  onClose();
                }}
              >
                {t('add')}
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <>
      {props?.value?.length !== 0 && (
        <div className="gap-2 flex flex-wrap mb-2">
          {!!props.value?.length &&
            props?.value?.map?.((_category) => {
              const category = categoriesQuery.data?.sessionCategories?.find(
                (c) => c.id === _category
              );

              if (!category || !props?.value) return null;
              return (
                <Chip
                  key={category.id}
                  onClose={() => {
                    props?.onChange?.(
                      props?.value?.filter?.((v) => v !== category?.id) || []
                    );
                  }}
                >
                  {category.name}
                </Chip>
              );
            })}
        </div>
      )}

      <Select
        {...props}
        label={t('settings.categories')}
        isLoading={categoriesQuery?.loading}
        multiple
        options={options}
      />

      <Text className="text-xs text-default-400">You can select as many as you need</Text>
    </>
  );
}
