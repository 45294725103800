import { useQuery } from '@tanstack/react-query';
import { billingApiClient } from '@/sdks/clients/billing-client.ts';

export function useGetPlansQuery() {
  return useQuery({
    queryKey: ['plans'],
    queryFn() {
      return billingApiClient.plans.getPlansGetAllPlans();
    }
  });
}
