import { Image } from "@heroui/react";
import Text from '@/lib/ui/text.tsx';
import { cn } from '@/lib/utils.ts';
import { ReactNode } from 'react';

export default function EmptyState({
  className,
  title,
  footer,
  withImage = true,
  ...rest
}: CommonComponentProps & {
  withImage?: boolean;
  title?: ReactNode;
  footer?: ReactNode;
}) {
  return (
    <div
      className={cn(
        'flex flex-col gap-4 items-center mx-auto max-w-[300px]',
        className
      )}
      {...rest}
    >
      {withImage && <Image width={160} className="ml-[-12px]" src="/images/empty-state.png" />}
      {typeof title === 'string' ? (
        <Text className="text-xs font-medium text-default-500">{title}</Text>
      ) : (
        title
      )}
      {footer}
    </div>
  );
}
