import { useMemo } from 'react';
import { BadgeCheck } from 'lucide-react';
import { Alert, Card, CardBody } from '@heroui/react';

import { cn } from '@/lib/utils.ts';
import Text from '@/lib/ui/text.tsx';
import Title from '@/lib/ui/title.tsx';
import Loading from '@/lib/ui/loading.tsx';
import { useAuth } from '@/context/AuthContext.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { StatusSpaceEnum } from '@/app/auth/_store/mutation/graphql/mutation.graphql.ts';
import { useBilling } from '@/app/app/billing/context/billing.context.tsx';
import RetryPayLastPayment from '@/app/app/billing/components/CurrentPlanActions/RetryPayLastPayment.tsx';
import ViewPlans from '@/app/app/billing/components/CurrentPlanActions/ViewPlans.tsx';
import { useGetPlansQuery } from '@/app/auth/_store/query/plan.query.ts';
import CancelSubscription from '@/app/app/billing/components/CurrentPlanActions/CancelSubscription.tsx';
import SubscriptionReNew from '@/app/app/billing/components/CurrentPlanActions/SubscriptionReNew.tsx';
import { useGetCurrentSubscriptionQuery } from '@/app/app/billing/store/query/billing.query.ts';
import { SubscriptionResponseDto } from '@jogolabs/billing-sdk';
import AddPaymentMethod from '@/app/app/billing/components/CurrentPlanActions/AddPaymentMethod.tsx';

export default function CurrentPlan({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { activeSpace } = useAuth();
  const { invoices, trialFinished, paymentMethod, plans } = useBilling();
  const { data: plansData } = useGetPlansQuery();
  const { data: currentSubscription } = useGetCurrentSubscriptionQuery();

  const renderContent = useMemo(() => {
    const plan = plansData?.find?.(
      (item) => item?.default_price?.id === plans?.planId
    );

    const isCancelSub = [SubscriptionResponseDto.status.CANCELED].includes(
      currentSubscription?.subscription
        ?.status as SubscriptionResponseDto.status
    );

    const isPastDueSub = [SubscriptionResponseDto.status.PAST_DUE].includes(
      currentSubscription?.subscription
        ?.status as SubscriptionResponseDto.status
    );

    if (
      [StatusSpaceEnum.SUSPENDED].includes(
        activeSpace?.space?.status as StatusSpaceEnum
      )
    ) {
      const notHaveInvoices = !invoices?.data?.length;

      return (
        <>
          <div>
            <Title>
              {t(notHaveInvoices ? 'banner_trial_end' : 'banner_suspended')}
            </Title>
            <Text className="text-sm mb-5">{t('banner_suspended_desc')}</Text>
            {notHaveInvoices ? (
              <AddPaymentMethod />
            ) : !isCancelSub ? (
              <RetryPayLastPayment />
            ) : (
              <SubscriptionReNew />
            )}
          </div>
        </>
      );
    }

    // if the first invoice is draft, return success subscription
    if (
      [
        StatusSpaceEnum.TRIAL,
        StatusSpaceEnum.ACTIVE,
        StatusSpaceEnum.UNCOMPLETED,
        StatusSpaceEnum.PAST_DUE
      ].includes(activeSpace?.space?.status as StatusSpaceEnum) &&
      plan
    ) {
      return (
        <>
          <div>
            <Title>
              {t('you_are_on_plan', null, {
                plan: plan
                  ? t(`plans_names.${plan?.name}` as LanguageKeys)
                  : '-'
              })}
            </Title>
          </div>

          <div>
            <Text className="text-default-600 mb-1 text-sm">
              {t('you_are_on_plan_desc')}
            </Text>

            <div className="flex flex-col gap-1">
              {plan?.marketing_features?.map?.(({ name }) => {
                return (
                  <Text
                    key={name?.trim?.()}
                    className="flex items-center text-sm gap-1 text-default-600 p-2 bg-[rgba(255,255,255,0.4)] dark:bg-[rgba(0,0,0,0.2)] rounded-md"
                  >
                    <BadgeCheck size={18} />{' '}
                    {t(`plans_features.${name}` as LanguageKeys, name)}
                  </Text>
                );
              })}
            </div>
          </div>

          <div className="flex flex-col gap-2">
            {isPastDueSub && (
              <>
                <Alert color="warning">
                  <span className="text-sm">
                    {t('cannon_last_payment_desc')}
                  </span>
                </Alert>
                <RetryPayLastPayment />
              </>
            )}

            {((trialFinished && !!paymentMethod?.data && isCancelSub) ||
              (trialFinished &&
                !!paymentMethod?.data &&
                !currentSubscription)) && <SubscriptionReNew />}

            {trialFinished && !paymentMethod?.data ? (
              <AddPaymentMethod />
            ) : (
              !paymentMethod?.data && (
                <Alert color="warning">
                  <span className="text-sm">
                    {t('no_have_payment_methods')}
                  </span>
                </Alert>
              )
            )}
          </div>
        </>
      );
    }
    if (!plan) {
      return (
        <>
          <div>
            <Title>{t('not_subscription_found')}</Title>
            <Text>{t('not_subscription_found_desc')}</Text>
            <ViewPlans />
          </div>
        </>
      );
    }

    return (
      <div>
        <Title>{t('not_subscription_found')}</Title>
        <Text>{t('not_subscription_found_desc')}</Text>
      </div>
    );
  }, [
    currentSubscription,
    activeSpace?.space?.status,
    invoices?.data?.length,
    t,
    plansData,
    trialFinished,
    paymentMethod?.data,
    plans?.planId
  ]);

  return (
    <div className={cn(className, 'h-full')}>
      <Card
        className={cn(
          'md:h-full flex flex-col items-center bg-gradient-to-t from-background bg-primary/80 relative p-4 py-20 md:min-h-[170px]',
          {
            'to-green-100 dark:to-green-950': [StatusSpaceEnum.ACTIVE].includes(
              activeSpace?.space?.status as StatusSpaceEnum
            ),
            'to-warning-100 dark:to-warning-950': [
              StatusSpaceEnum.TRIAL,
              StatusSpaceEnum.PAST_DUE,
              StatusSpaceEnum.UNCOMPLETED
            ].includes(activeSpace?.space?.status as StatusSpaceEnum),
            'to-danger-100 dark:to-danger-950': [
              StatusSpaceEnum.SUSPENDED
            ].includes(activeSpace?.space?.status as StatusSpaceEnum)
          }
        )}
      >
        <CardBody className="relative flex flex-col gap-5 max-w-[500px]">
          {invoices.isLoading ? (
            <Loading />
          ) : (
            <>
              {renderContent}
              {!!paymentMethod?.data &&
                activeSpace?.space?.billingScheduleId &&
                activeSpace?.space?.billingRef &&
                ![StatusSpaceEnum.TRIAL, StatusSpaceEnum.SUSPENDED].includes(
                  activeSpace?.space?.status as StatusSpaceEnum
                ) && <CancelSubscription />}
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
}
