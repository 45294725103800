import { useEffect } from 'react';
import { auth } from '@/lib/firebase.ts';
import { useAuth } from '../../context/AuthContext';

export default function TriggerFirebase() {
  const { login, onEndInitialLoading } = useAuth();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const firebaseToken = await user.getIdToken();
        login(firebaseToken);
      } else {
        onEndInitialLoading();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
