import {
  Modal as NextUIModal,
  ModalProps as NextUIModalProps,
  ButtonProps,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button
} from "@heroui/react";
import { ReactNode } from 'react';
import Text from '@/lib/ui/text.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { cn } from '@/lib/utils.ts';
import { X } from 'lucide-react';
import { useKeyboardDetector } from '@/hooks/useKeyboardDetector.ts';
import { useDetectMobile } from '@/hooks/useDetectMobile.ts';
import useToggle from '@/hooks/useToggle.ts';

export type ModalActionProps = Omit<ButtonProps, 'children'> & {
  label: string;
};

export type ModalProps = Omit<NextUIModalProps, 'title'> & {
  title?: ReactNode;
  description?: ReactNode;
  actions?: ModalActionProps[];
  maskClosable?: boolean;
  contentClass?: string;
};

export default function Modal({
  title,
  children,
  actions,
  description,
  isDismissable,
  contentClass,
  maskClosable = isDismissable,
  backdrop = 'blur',
  scrollBehavior = 'normal',
  ...restProps
}: ModalProps) {
  const isMobile = useDetectMobile();
  const keyboardOpenH = useKeyboardDetector();
  const keyboardOpen = keyboardOpenH && isMobile;

  return (
    <NextUIModal
      {...restProps}
      backdrop={backdrop}
      isDismissable={maskClosable}
      scrollBehavior={scrollBehavior}
      classNames={{
        closeButton: 'none opacity-0',
        wrapper: cn('modal-wrapper', restProps?.size === 'full' && 'fullscreen')
      }}
    >
      <ModalContent
        className={cn('relative max-h-[100vh] overflow-y-auto', contentClass)}
      >
        {(title || description) && !keyboardOpen && (
          <ModalHeader
            className={cn(
              'sticky top-0 left-0 flex flex-col gap-1 bg-background shadow-sm z-40'
            )}
          >
            <div className="relative">
              {title}
              <Text className="text-xs">{description}</Text>

              <Button
                isIconOnly
                size="sm"
                onClick={restProps?.onClose}
                variant="light"
                endContent={<X size={16} />}
                className="absolute right-0 top-0"
              />
            </div>
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>
        {!!actions?.length && !keyboardOpen && (
          <ModalFooter
            className={cn(
              restProps?.size === 'full' &&
                'sticky bottom-0 left-0 bg-background z-[1000] shadow-inner'
            )}
          >
            {actions.map((button, index) => (
              <ButtonAction key={index} {...button} />
            ))}
          </ModalFooter>
        )}
      </ModalContent>
    </NextUIModal>
  );
}

const ButtonAction = ({
  label,
  isLoading,
  onClick,
  ...props
}: ButtonProps & { label: ReactNode }) => {
  const [loading, toggleLoading] = useToggle();

  const onInternalClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    toggleLoading.onVisible();
    await onClick?.(e);
    toggleLoading.onHidden();
  };

  return (
    <Button
      {...props}
      onClick={onInternalClick}
      isLoading={isLoading || loading}
    >
      {label}
    </Button>
  );
};

enum ModalActionEnum {
  edit = 'edit',
  save = 'save',
  delete = 'delete'
}

export function ModalActions({
  onOk,
  onCancel,
  variant,
  formName,
  isLoading
}: {
  isLoading?: boolean;
  formName?: string;
  onOk?: ModalActionProps['onClick'];
  onCancel: ModalActionProps['onClick'];
  variant: keyof typeof ModalActionEnum;
}) {
  const { t } = useTranslation();

  const isSave = variant === ModalActionEnum.save;
  const isDelete = variant === ModalActionEnum.delete;

  return (
    <ModalFooter className="px-0">
      <Button
        variant="light"
        color="default"
        isDisabled={isLoading}
        onClick={onCancel}
      >
        {t('cancel')}
      </Button>
      <Button
        type="submit"
        variant="flat"
        onClick={onOk}
        form={formName}
        isLoading={isLoading}
        color={isDelete ? 'danger' : 'primary'}
      >
        {t(isDelete ? 'delete' : isSave ? 'save' : 'edit')}
      </Button>
    </ModalFooter>
  );
}
