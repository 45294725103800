import { Avatar, Listbox, ListboxItem } from "@heroui/react";
import { ChevronRightIcon } from 'lucide-react';
import Text from '@/lib/ui/text.tsx';
import { Maybe, UserBackoffice } from '@/__generated__/graphql.ts';
import { DASHBOARD_ROUTES } from '@/app/app/dashboard/common/routes.ts';
import { useAuth } from '../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { cn } from '@/lib/utils.ts';

export default function SelectSpaceList({
  onClose,
  className,
  scope = [],
  withRedirect = true
}: {
  withRedirect?: boolean;
  className?: string;
  onClose?: () => void;
  scope?: Maybe<UserBackoffice>[];
}) {
  const { onChangeSpace } = useAuth();
  const navigate = useNavigate();

  const onChangeInternalSpace = (key: string | number) => {
    const activeSpace = scope?.find((space) => space?.id === key);
    if (activeSpace) {
      onChangeSpace(activeSpace);
      withRedirect && navigate(DASHBOARD_ROUTES.DASHBOARD);
      onClose?.();
    }
  };

  return (
    <Listbox
      onAction={onChangeInternalSpace}
      className={cn(
        'p-0 gap-0 divide-y divide-default-300/50 dark:divide-default-100/80 bg-content1 w-full max-w-[350px] overflow-visible shadow-small rounded-medium',
        className
      )}
      itemClasses={{
        base: 'px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-16 data-[hover=true]:bg-default-100/80'
      }}
    >
      {scope.map((space, index) => {
        return (
          <ListboxItem
            key={space?.id || index}
            showDivider={index < (scope?.length || 0) - 1}
            endContent={<ChevronRightIcon className="text-xl" />}
            startContent={<Avatar src={space?.space?.thumbnail?.url} />}
          >
            <Text className="text-lg">{space?.space?.name}</Text>
          </ListboxItem>
        );
      })}
    </Listbox>
  );
}
