import { useAuth } from '../../../context/AuthContext';
import ScreenLoading from '@/lib/ui/screen-loading.tsx';

export function LoadingLogin() {
  const { isLoginLoading } = useAuth();

  if (isLoginLoading) {
    return <ScreenLoading />;
  }

  return null;
}
