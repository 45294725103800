import { toast } from 'sonner';
import { useState } from 'react';
import { Button } from '@heroui/react';

import Modal from '@/lib/ui/modal.tsx';
import { useAuth } from '@/context/AuthContext.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import PlanRadio from '@/app/auth/_components/PlanRadio.tsx';
import { useBilling } from '@/app/app/billing/context/billing.context.tsx';
import { useChangePlan } from '@/app/app/billing/store/mutation/billing.mutation.ts';

export default function AddPlanCustomerModal() {
  const { t } = useTranslation();
  const { activeSpace, onEditSpace } = useAuth();
  const { plans } = useBilling();
  const planId = plans?.planId;

  const [defaultPlanId, setDefaultPlanId] = useState(planId);
  const postChangePlanCustomer = useChangePlan();

  const onUpdatePlan = async () => {
    const newPlan = defaultPlanId;
    if (!newPlan || (newPlan === planId && !planId)) return;
    const customerId = activeSpace?.space?.billingRef || '';

    if (!customerId) {
      plans.setPlanId(newPlan)
      await onEditSpace({
        spaceId: activeSpace?.space?.id as string,
        data: {
          plan: newPlan
        }
      });

      return plans.onClose();
    }

    try {
      await postChangePlanCustomer.mutateAsync({
        customerId: customerId,
        jogoId: activeSpace?.space?.id as string,
        newPriceId: newPlan as string,
        currentPriceId: planId as string,
        scheduleSubscriptionId: activeSpace?.space?.billingScheduleId as string
      });
      plans.setPlanId(newPlan);
    } catch (e) {
      console.error(e);
      toast.error(t('errors_message.unexpected_error'), {
        description: t('errors_message.original_error', null, {
          error: (e as Error)?.message || e
        })
      });
    }
  };

  const isNewPlan = defaultPlanId !== planId;

  return (
    <Modal
      title={t('plans')}
      size="3xl"
      isOpen={plans.open}
      onClose={plans?.onClose}
    >
      <div className="flex flex-col gap-5 py-5">
        <PlanRadio
          value={defaultPlanId}
          defaultValue={activeSpace?.space?.plan}
          onChange={({ target }) => setDefaultPlanId(target?.value)}
        />

        <div className="flex justify-end items-center">
          {activeSpace?.space?.billingRef ? (
            <Button
              onPress={onUpdatePlan}
              color={isNewPlan ? 'primary' : 'default'}
              variant={isNewPlan ? 'solid' : 'bordered'}
              isLoading={postChangePlanCustomer.isPending}
            >
              {isNewPlan ? t('update') : t('replace')}
            </Button>
          ) : (
            <Button
              color="primary"
              variant="flat"
              onPress={onUpdatePlan}
              isDisabled={!defaultPlanId}
              isLoading={postChangePlanCustomer.isPending}
            >
              {t('add')}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
