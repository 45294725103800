'use client';

import { Avatar } from "@heroui/react";
import { useAuth } from '../../../../context/AuthContext';
import { cn } from '@/lib/utils.ts';

export default function AvatarUser({
  className,
  ...props
}: {
  className?: string;
}) {
  const { user } = useAuth();

  return (
    <Avatar
      className={cn('border-2 p-0.5 bg-background border-primary', className)}
      {...props}
      src={user?.item?.avatar?.url}
      name={user?.item?.fullName as string}
    />
  );
}
