import { Button } from '@heroui/react';
import { BookmarkCheck } from 'lucide-react';

import { useTranslation } from '@/hooks/useTranslation.ts';
import { useBilling } from '@/app/app/billing/context/billing.context.tsx';

export default function ViewPlans() {
  const { t } = useTranslation();
  const { plans } = useBilling();

  return (
    <Button
      fullWidth
      variant="flat"
      color="primary"
      className="mt-3"
      onPress={plans.onOpen}
      startContent={<BookmarkCheck size={18} />}
    >
      {t('view_plans')}
    </Button>
  );
}
