import { useEffect, useState } from 'react';
import { Chip } from "@heroui/react";
import { Search, X } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import { Table } from '@/lib/ui/table.tsx';
import useColumns from './settings/useColumns.tsx';
import usePagination from '@/hooks/usePagination.ts';
import { Selection } from '@react-types/shared/src/selection';
import { useGetClientsForNotifications } from '@/app/app/clients/store/query/clients.query.ts';
import {
  CreateNotificationsInput,
  SendUserNotificationEnum,
  SendUserNotificationFilterEnum
} from '@/__generated__/graphql.ts';
import Select from '@/lib/ui/select.tsx';
import { FormItem } from 'reactivity-hook-form';
import Title from '@/lib/ui/title.tsx';
import Input from '@/lib/ui/input.tsx';
import { useDebounce } from '@/hooks/useDebounce.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export default function UserList() {
  const columns = useColumns();
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<CreateNotificationsInput>();
  const [searchText, setSearchText] = useState<string>('');

  const onChange = useDebounce((value: string) => {
    setValue('users.search', value || undefined);
  });

  const users = watch('users');
  const isAllSelected = users.template === SendUserNotificationEnum.All;

  const { serverPagination, setTotalCount, frontPagination } = usePagination();
  const { data, all, serverTotalCount, loading } =
    useGetClientsForNotifications({
      ...serverPagination,
      filter: users?.filter,
      search: users?.search?.trim?.() || undefined
    });

  const onSelectionChange = (keys: Selection) => {
    if (keys === 'all') {
      setValue('users.template', SendUserNotificationEnum.All);
      setValue('users.connect', []);
    } else {
      setValue('users.template', SendUserNotificationEnum.Selection);
      setValue(
        'users.connect',
        Array.from(keys).map((key) => key.toString())
      );
    }
  };

  const onRemoveSelection = (key: string) => {
    setValue(
      'users.connect',
      users?.connect?.filter?.((select) => select !== key) || []
    );
  };

  useEffect(() => {
    if (!loading) {
      setTotalCount(serverTotalCount);
    }
  }, [serverTotalCount, loading, setTotalCount]);

  return (
    <div>
      <Title size="md">{t('notifications.select_users')}</Title>

      <div className="w-full flex justify-between gap-2 mb-5">
        <Input
          value={searchText}
          onChange={(event) => {
            const text = event.target.value;
            setSearchText(text);
            onChange(text);
          }}
          startContent={<Search size={18} />}
          placeholder={t('notifications.search_text')}
          endContent={
            users?.search && (
              <X
                size={18}
                className="cursor-pointer"
                onClick={() => {
                  onChange('');
                  setSearchText('');
                }}
              />
            )
          }
        />

        <div className="w-[170px]">
          <FormItem<CreateNotificationsInput> name="users.filter">
            <Select
              placeholder={t('notifications.filters')}
              options={[
                {
                  label: t('notifications.all'),
                  value: ''
                },
                {
                  label: t('notifications.only_active'),
                  value: SendUserNotificationFilterEnum.ActiveUsers
                },
                {
                  label: t('notifications.only_inactive'),
                  value: SendUserNotificationFilterEnum.InactiveUsers
                }
              ]}
            />
          </FormItem>
        </div>
      </div>

      {(isAllSelected || !!users?.connect?.length) && (
        <div className="flex gap-2 flex-wrap items-center mb-5">
          {isAllSelected && (
            <Chip
              onClose={() =>
                setValue('users.template', SendUserNotificationEnum.Selection)
              }
            >
              {t('notifications.all')}
            </Chip>
          )}
          {users?.connect?.map?.((select) => {
            return (
              <Chip onClose={() => onRemoveSelection(select)}>
                {all[select]?.fullName}
              </Chip>
            );
          })}
        </div>
      )}

      <Table
        rows={data}
        columns={columns}
        isLoading={loading}
        selectionMode="multiple"
        pagination={frontPagination}
        selectedKeys={isAllSelected ? 'all' : (users?.connect as Iterable<any>)}
        onSelectionChange={onSelectionChange}
        // getRowId={(_, index) => index}
      />
    </div>
  );
}
