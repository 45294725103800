import { useState } from 'react';
import { Button } from "@heroui/react";
import { X } from 'lucide-react';
import useToggle from '@/hooks/useToggle.ts';

const en = (
  <>
    <h1 className="capitalize font-bold text-2xl mb-2">
      Steps for Delete Account
    </h1>
    <p>
      At Jogo App LLC, we respect your privacy and are committed to protecting
      your personal data. This steps explains how you can delete your account
    </p>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">
      Follow the next steps for delete account:
    </h2>

    <ul className="list-disc pl-5">
      <li>
        <strong>1.</strong> Enter to Jogo App and Login with your account.
      </li>
      <li>
        <strong>2.</strong> Go to Avatar in bottom navigation.
      </li>
      <li>
        <strong>3.</strong> Go to Settings Account.
      </li>
      <li>
        <strong>4.</strong> Press in <strong>Delete Account</strong> Button.
      </li>
    </ul>
  </>
);

const es = (
  <>
    <h1 className="capitalize font-bold text-2xl mb-2">
      Pasos para eliminar una cuenta
    </h1>
    <p>
      En Jogo App LLC, respetamos su privacidad y nos comprometemos a proteger
      sus datos personales. Estos pasos explican cómo puede eliminar su cuenta
    </p>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">
      Siga los siguientes pasos para eliminar una cuenta:
    </h2>

    <ul className="list-disc pl-5">
      <li>
        <strong>1.</strong> Ingrese a Jogo App e inicie sesión con su cuenta.
      </li>
      <li>
        <strong>2.</strong> Vaya a Avatar en la navegación inferior.
      </li>
      <li>
        <strong>3.</strong> Vaya a Configuración de cuenta.
      </li>
      <li>
        <strong>4.</strong> Presione el botón <strong>Eliminar cuenta</strong>.
      </li>
    </ul>
  </>
);

const alertMessage = (setLand: (lang: 'es' | 'en') => void) => ({
  en: (
    <p>
      If you wish to see this page in <strong>spanish</strong> click{' '}
      <a
        className="text-primary cursor-pointer hover:underline"
        onClick={() => setLand('es')}
      >
        here
      </a>
    </p>
  ),
  es: (
    <p>
      Si deseas ver esta política en <strong>english</strong> haz click{' '}
      <a
        className="text-primary cursor-pointer hover:underline"
        onClick={() => setLand('en')}
      >
        aquí
      </a>
    </p>
  )
});

export default function DeleteAccountPage() {
  const [lang, setLand] = useState<'es' | 'en'>('en');
  const [closeAlert, closeActions] = useToggle(true);

  const isEnglish = lang === 'en';

  return (
    <main className="p-5 md: p-7">
      {closeAlert && (
        <div className="mb-5 bg-warning/20 p-2 rounded flex justify-between">
          {alertMessage(setLand)[lang]}

          <Button
            isIconOnly
            size="sm"
            startContent={<X size={18} />}
            onClick={closeActions.onHidden}
            className="bg-transparent hover:bg-slate-300"
          />
        </div>
      )}
      {isEnglish ? en : es}
    </main>
  );
}
