import { Button } from '@heroui/react';
import { ShieldX } from 'lucide-react';

import { useTranslation } from '@/hooks/useTranslation.ts';
import { useCancelSubscription } from '@/app/app/billing/store/mutation/billing.mutation.ts';
import { useAuth } from '@/context/AuthContext.tsx';

export default function CancelSubscription() {
  const { t } = useTranslation();
  const { activeSpace } = useAuth();
  const { mutateAsync, isPending } = useCancelSubscription();

  return (
    <Button
      fullWidth
      variant="flat"
      color="danger"
      isLoading={isPending}
      startContent={<ShieldX size={18} />}
      onPress={() =>
        mutateAsync({
          customerId: activeSpace?.space?.billingRef as string
        })
      }
    >
      {t('cancel_subscription')}
    </Button>
  );
}
