import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@/context/AuthContext.tsx';
import { billingApiClient } from '@/sdks/clients/billing-client.ts';

export const BILLING_QUERY_KEY = 'billing';

export function useGetBillingMethodQuery() {
  const { activeSpace } = useAuth();

  return useQuery({
    queryKey: [
      BILLING_QUERY_KEY,
      'getBillingMethod',
      activeSpace?.space?.id,
      activeSpace?.space?.billingRef
    ],
    enabled: !!activeSpace?.space?.billingRef,
    queryFn: () => {
      return billingApiClient.customers.getCustomersPaymentMethod({
        customerId: activeSpace?.space?.billingRef as string
      });
    }
  });
}

export function useGetPaymentHistoryQuery() {
  const { activeSpace } = useAuth();

  return useQuery({
    queryKey: [
      BILLING_QUERY_KEY,
      'customer',
      'payment-history',
      activeSpace?.space?.id,
      activeSpace?.space?.billingRef
    ],
    enabled: !!activeSpace?.space?.billingRef,
    queryFn: () => {
      return billingApiClient.invoices.getInvoices({
        customerId: activeSpace?.space?.billingRef as string
      });
    }
  });
}

export function useGetCurrentSubscriptionQuery() {
  const { activeSpace } = useAuth();

  return useQuery({
    queryKey: [
      BILLING_QUERY_KEY,
      'customer',
      'get-subscription',
      activeSpace?.space?.id,
      activeSpace?.space?.billingScheduleId
    ],
    enabled: !!activeSpace?.space?.billingScheduleId,
    queryFn: () => {
      return billingApiClient.customers.getCustomersGetSubscription({
        scheduleSubscriptionId: activeSpace?.space?.billingScheduleId as string
      });
    }
  });
}
