import { Button, Card, CardBody } from '@heroui/react';
import { useAuth } from '@/context/AuthContext.tsx';
import Text from '@/lib/ui/text.tsx';
import AddPaymentMethod, {
  PaymentForm
} from '@/app/app/billing/components/AddPaymentMethod.tsx';
import Input from '@/lib/ui/input.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { useBilling } from '@/app/app/billing/context/billing.context.tsx';
import Loading from '@/lib/ui/loading.tsx';
import { useUpdatePaymentMethod } from '@/app/app/billing/store/mutation/billing.mutation.ts';
import { toast } from 'sonner';

export default function PaymentMethod() {
  const { t } = useTranslation();
  const { activeSpace } = useAuth();
  const { paymentMethod, plans } = useBilling();
  const postUpdatePaymentMethod = useUpdatePaymentMethod();

  const planId = plans?.planId;

  const onUpdatePaymentMethod = async (paymentId: string) => {
    const paymentMethodId = paymentMethod.data?.id as string;
    if (!planId || !paymentMethodId) {
      toast.error(t('errors_message.unexpected_error'), {
        description: t('errors_message.user_not_have_payment_methods')
      });
      return;
    }
    try {
      const customerId = activeSpace?.space?.billingRef || '';
      await postUpdatePaymentMethod.mutateAsync({
        customerId: customerId,
        newPaymentMethodId: paymentId,
        beforePaymentMethodId: paymentMethodId
      });
      paymentMethod.onCloseEdit();
    } catch (e) {
      console.error(e);
      toast.error(t('errors_message.unexpected_error'), {
        description: t('errors_message.original_error', null, {
          error: (e as Error)?.message || e
        })
      });
    }
  };

  return (
    <Card className="w-full md:w-[50%]" id="payment-method">
      <CardBody className="gap-2">
        <div className="flex justify-between items-center gap-2">
          <Text className="font-bold">{t('payment_method')}</Text>
          {activeSpace?.space?.billingRef && (
            <Button
              variant="bordered"
              onPress={
                paymentMethod.openEdit
                  ? paymentMethod.onCloseEdit
                  : paymentMethod.onOpenEdit
              }
            >
              {t(paymentMethod.openEdit ? 'cancel' : 'replace')}
            </Button>
          )}
        </div>

        <Text className="text-default-500">
          {t('plans_schema.plan_payment_method')}
        </Text>
        {paymentMethod?.isLoading ? (
          <Loading />
        ) : !activeSpace?.space?.billingRef ? (
          <AddPaymentMethod />
        ) : paymentMethod.openEdit ? (
          <PaymentForm
            onFinish={onUpdatePaymentMethod}
            loadingServer={postUpdatePaymentMethod.isPending}
          />
        ) : (
          <Input
            isDisabled
            placeholder={`**** **** **** ${paymentMethod?.data?.card?.last4}`}
          />
        )}
      </CardBody>
    </Card>
  );
}
