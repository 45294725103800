import { toast } from 'sonner';
import { useState } from 'react';
import { Button } from '@heroui/react';
import { CreditCard } from 'lucide-react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import {
  useAddPaymentMethod,
  useGetCustomer,
  useSubscribeToPlan
} from '@/app/app/billing/store/mutation/billing.mutation.ts';
import { useAuth } from '@/context/AuthContext.tsx';
import { useTheme } from '@/context/theme-provider.tsx';
import Modal from '@/lib/ui/modal.tsx';
import { useBilling } from '@/app/app/billing/context/billing.context.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { getApiErrorText } from '@/lib/utils.ts';

export default function AddPaymentMethod() {
  const { t } = useTranslation();
  const { plans, paymentMethod } = useBilling();
  const { activeSpace } = useAuth();
  const getCustomer = useGetCustomer();
  const subscribeToPlan = useSubscribeToPlan();
  const postAddPaymentMethod = useAddPaymentMethod();

  const planId = plans?.planId;

  const onAddPaymentMethod = async (paymentId: string) => {
    if (!planId) {
      toast.error(t('errors_message.unexpected_error'), {
        description: t('errors_message.user_not_have_plan_selected')
      });
      return;
    }

    let customerId: string = activeSpace?.space?.billingRef || '';
    if (!customerId) {
      try {
        const { id } = await getCustomer.mutateAsync({
          id: activeSpace?.space?.id as string,
          name: activeSpace?.space?.name as string,
          email: activeSpace?.space?.email as string
        });
        customerId = id || '';
      } catch (e) {
        console.error('Error to get customer:', e);
        toast.error(t('errors_message.unexpected_error'), {
          description: t('errors_message.original_error', null, {
            error: getApiErrorText(e)
          })
        });
      }
    }

    if (customerId) {
      try {
        await postAddPaymentMethod.mutateAsync({
          customerId: customerId,
          paymentMethodId: paymentId
        });
        await subscribeToPlan.mutateAsync({
          priceId: planId,
          jogoId: activeSpace?.space?.id as string,
          customerId: customerId
        });
      } catch (e) {
        console.error('Error to add payment method or subscribe to plan:', e);
        toast.error(t('errors_message.unexpected_error'), {
          description: t('errors_message.original_error', null, {
            error: getApiErrorText(e)
          })
        });
      }
    }

    paymentMethod.onCloseEdit();
  };

  const loadingServer =
    postAddPaymentMethod.isPending ||
    subscribeToPlan.isPending ||
    getCustomer.isPending;

  if (!planId) return null;

  return (
    <>
      <Button
        variant="flat"
        color="primary"
        onPress={paymentMethod.onOpenEdit}
        startContent={<CreditCard size={18} />}
      >
        {t('add_payment_method')}
      </Button>

      <Modal
        isOpen={paymentMethod.openEdit}
        onClose={paymentMethod.onCloseEdit}
        title={t('add_payment_method')}
      >
        <div className="p-0">
          <PaymentForm
            loadingServer={loadingServer}
            onFinish={onAddPaymentMethod}
            onCancel={paymentMethod.onCloseEdit}
          />
        </div>
      </Modal>
    </>
  );
}

export const PaymentForm = ({
  onFinish,
  onCancel,
  loadingServer
}: {
  loadingServer?: boolean;
  onCancel?: () => void;
  onFinish: (paymentId: string) => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { theme } = useTheme();

  const onSubmitMethod = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error('Stripe.js has not loaded yet.');
      toast.warning(t('errors_message.unexpected_error'));
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      console.error('CardElement not found');
      toast.warning(t('errors_message.unexpected_error'));
      return;
    }

    setLoading(true);

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });

    setLoading(false);

    if (error) {
      console.error('Payment Method Error:', error);
      toast.error(t('errors_message.unexpected_error'), {
        description: t('errors_message.original_error', null, {
          error: getApiErrorText(error)
        })
      });
      return;
    }
    onFinish(paymentMethod.id);
  };

  const isLoading = loadingServer || loading;

  return (
    <form
      className="flex flex-col gap-5 mt-2"
      method="POST"
      onSubmit={onSubmitMethod}
    >
      <div
        className="p-3 rounded"
        style={{
          backgroundColor:
            theme === 'dark'
              ? 'rgba(255, 255, 255, .1)'
              : 'rgba(100, 100, 100, .1)'
        }}
      >
        <CardElement
          options={{
            hidePostalCode: true,
            disableLink: true,
            iconStyle: 'solid',
            style: {
              base: {
                color: theme === 'dark' ? '#fff' : '#000'
              }
            }
          }}
        />
      </div>
      <div className="flex justify-end gap-3">
        {onCancel && (
          <Button onPress={onCancel} disabled={!stripe || isLoading}>
            {t('cancel')}
          </Button>
        )}
        <Button
          fullWidth
          variant="flat"
          type="submit"
          color="primary"
          isLoading={isLoading}
          disabled={!stripe || isLoading}
        >
          {isLoading ? `${t('processing')}...` : t('update')}
        </Button>
      </div>
    </form>
  );
};
