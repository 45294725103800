import Title from '@/lib/ui/title.tsx';
import { Button } from "@heroui/react";

export type HeaderPageProps = {
  title: string;
  primaryAction?: {
    text: string;
    onClick: () => void;
  };
};

export default function HeaderPage({ primaryAction, title }: HeaderPageProps) {
  return (
    <div className="flex justify-between items-center mb-4">
      <Title>{title}</Title>

      {primaryAction && (
        <Button
          color="primary"
          variant="bordered"
          onClick={primaryAction.onClick}
        >
          {primaryAction.text}
        </Button>
      )}
    </div>
  );
}
