import { useState } from 'react';
import { PaginationProps } from "@heroui/react";

export type ServerPagination = {
  take: number;
  skip: number;
};

export type ServerQueryPagination = {
  page: number;
  limit: number;
};

export type FrontPagination = Pick<
  PaginationProps,
  'page' | 'total' | 'onChange' | 'showShadow' | 'showControls' | 'key'
>;

// export type PaginationProps = {
//   totalCount?: number
// }

export function getTotalPages(totalCount: number = 0, limit: number = 0) {
  return Math.ceil(totalCount / limit);
}

export default function usePagination() {
  const [totalCount, setTotalCount] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  return {
    limit,
    setLimit,
    setTotalCount: (total: number) => {
      setTotalCount(getTotalPages(total, limit));
    },
    frontPagination: {
      page,
      total: totalCount,
      onChange: setPage,
      showShadow: true,
      showControls: true,
      key: totalCount <= 0 ? 'initial' : 'pagination'
    } as FrontPagination,
    serverQueryPagination: {
      page,
      limit
    } as ServerQueryPagination,
    serverPagination: {
      take: limit,
      skip: limit * (page - 1)
    } as ServerPagination
  };
}
