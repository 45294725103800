import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router/dist/lib/context';

import { cleanUrl } from '@/routes/utils.ts';
import { PrivateRotes } from '@/routes/private-rotes.ts';
import { DASHBOARD_ROUTES } from '@/app/app/dashboard/common/routes.ts';
import Dashboard from './dashboard/page.tsx';
import { SESSION_ROUTES } from '@/app/app/sessions/common/routes.ts';
import Session from './sessions/page.tsx';
import { COACHES_ROUTES } from '@/app/app/coaches/common/routes.ts';
import CoachesPages from './coaches';
import { CLIENT_ROUTES } from '@/app/app/clients/common/routes.ts';
import ClientsPages from './clients';
import { SETTINGS_ROUTES } from '@/app/app/settings/common/routes.ts';
import Settings from './settings/[tab].tsx';
import layout from '@/app/app/_shared/layout.tsx';
import { PROFILE_ROUTES } from '@/app/app/profile/common/routes.ts';
import ProfilePages from '@/app/app/profile';
import { NOTIFICATIONS_ROUTES } from '@/app/app/notifications/common/routes.ts';
import NotificationsPages from '@/app/app/notifications';
import RefreshPage from '@/app/app/settings/components/Tabs/Stripe/callbacks/Refresh.tsx';
import ReturnPage from '@/app/app/settings/components/Tabs/Stripe/callbacks/Return.tsx';
import { BILLING_ROUTES, BillingPage } from '@/app/app/billing';
import { StatusSpaceEnum } from '@/app/auth/_store/mutation/graphql/mutation.graphql.ts';
import { useAuth } from '@/context/AuthContext.tsx';

const useAppRoutes: () => RouteObject[] = () => {
  const { activeSpace } = useAuth();

  const isSuspended = activeSpace?.space?.status === StatusSpaceEnum.SUSPENDED;

  if (isSuspended) {
    return [
      {
        path: cleanUrl(BILLING_ROUTES.HOME, PrivateRotes.APP),
        element: <BillingPage />
      },
      {
        path: cleanUrl(PROFILE_ROUTES.BASE, PrivateRotes.APP),
        children: ProfilePages
      }
    ];
  }

  return [
    {
      path: cleanUrl(PrivateRotes.APP, PrivateRotes.APP),
      element: <Navigate to={DASHBOARD_ROUTES.DASHBOARD} />
    },
    {
      path: cleanUrl(DASHBOARD_ROUTES.DASHBOARD, PrivateRotes.APP),
      element: <Dashboard />
    },
    {
      path: cleanUrl(SESSION_ROUTES.HOME, PrivateRotes.APP),
      element: <Session />
    },
    {
      path: cleanUrl(COACHES_ROUTES.HOME, PrivateRotes.APP),
      children: CoachesPages
    },
    {
      path: cleanUrl(BILLING_ROUTES.HOME, PrivateRotes.APP),
      element: <BillingPage />
    },
    {
      path: cleanUrl(CLIENT_ROUTES.HOME, PrivateRotes.APP),
      children: ClientsPages
    },
    {
      path: cleanUrl(NOTIFICATIONS_ROUTES.HOME, PrivateRotes.APP),
      children: NotificationsPages
    },
    {
      path: cleanUrl(SETTINGS_ROUTES.BASE, PrivateRotes.APP),
      element: <Settings />
    },
    {
      path: cleanUrl(SETTINGS_ROUTES.RETURN_STRIPE_CALLBACK, PrivateRotes.APP),
      element: <ReturnPage />
    },
    {
      path: cleanUrl(SETTINGS_ROUTES.REFRESH_STRIPE_CALLBACK, PrivateRotes.APP),
      element: <RefreshPage />
    },
    {
      path: cleanUrl(PROFILE_ROUTES.BASE, PrivateRotes.APP),
      children: ProfilePages
    }
  ];
};

export { useAppRoutes, layout };
