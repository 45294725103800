import {
  createContext,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { auth } from '@/lib/firebase.ts';
import { AUTH_ROUTES } from '@/app/auth/_common/routes.ts';
import { DASHBOARD_ROUTES } from '@/app/app/dashboard/common/routes.ts';
import {
  useLoginMutation,
  useUpdateSpaceMutation
} from '@/app/auth/_store/mutation/auth.mutation.ts';
import {
  UpdateSpaceMutationVariables,
  UserAuthenticationWithPasswordSuccess,
  UserBackoffice
} from '@/__generated__/graphql.ts';
import { SELECT_SPACE_KEY, SESSION_KEY } from '@/config/constants.ts';
import { getLocalStorage, setLocalStorage } from '@/utils/localstorage.ts';
import { StatusSpaceEnum } from '@/app/auth/_store/mutation/graphql/mutation.graphql.ts';
import dayjs from 'dayjs';

export type UserType = UserAuthenticationWithPasswordSuccess;

export type AuthContextProps = {
  isAuthenticated: boolean;
  isInitialLoading: boolean;
  isLoginLoading: boolean;
  loadingCms: boolean;
  loadingUpdateSpace: boolean;
  user: UserAuthenticationWithPasswordSuccess | null;
  activeSpace: (UserBackoffice & { isSuspended: boolean }) | null;
  onEditSpace: (props: UpdateSpaceMutationVariables) => Promise<void>;
  onChangeSpace: (space: UserBackoffice | null) => void;
  login: (firebaseToken: string) => void;
  logout: () => Promise<void>;
  onEndInitialLoading: () => void;
  stateAlert: {
    open: boolean;
    diffDaysTrial: number;
    onClose: () => void;
    type: 'danger' | 'warning';
  };
};

const AuthContext = createContext<AuthContextProps | null>(null);

const onUpdateSpace = (): UserBackoffice | null => {
  const selectSpace = window?.localStorage?.getItem(SELECT_SPACE_KEY);
  if (selectSpace) {
    try {
      return JSON.parse(selectSpace);
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const AuthProvider = memo(({ children }: PropsWithChildren) => {
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isUpdateSpaceLoading, setIsUpdateSpaceLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<UserType | null>(null);
  const [activeSpace, setActiveSpace] = useState(onUpdateSpace);

  const [onLoginCms, { loading }] = useLoginMutation();
  const [onUpdateSpaceCms, { loading: isUpdateSpaceLoadingMutation }] =
    useUpdateSpaceMutation();

  const login = useCallback(
    async (firebaseToken: string) => {
      const pathname = window?.location?.pathname;
      setIsLoginLoading(true);
      try {
        // login in server Backend
        const loginData = await onLoginCms({
          variables: { firebaseToken }
        });

        const data = loginData?.data as {
          authenticateBackofficeUserWithFirebase: UserAuthenticationWithPasswordSuccess;
        };

        // get scope from user data
        const scope =
          data?.authenticateBackofficeUserWithFirebase?.scope?.filter?.(
            (item) => !item?.isBlocked
          ) || [];

        // check if user has scope and token
        if (scope.length && data?.authenticateBackofficeUserWithFirebase) {
          // save token in localstorage
          setLocalStorage(
            SESSION_KEY,
            data?.authenticateBackofficeUserWithFirebase?.sessionToken
          );

          // get active space from localstorage
          const activeSpaceLocalStorage = getLocalStorage<{ id: string }>(
            SELECT_SPACE_KEY
          );

          setUser(data?.authenticateBackofficeUserWithFirebase);
          setIsAuthenticated(true);
          setIsInitialLoading(false);

          // verify if user has more than one space
          if (pathname.includes(AUTH_ROUTES.LOGIN) && scope.length > 1) {
            return (window.location.href = AUTH_ROUTES.SELECT_SPACE);
          }
          // verify if user has only one space
          else if (scope.length === 1) {
            const space = scope[0];
            setActiveSpace(space);
            setLocalStorage(SELECT_SPACE_KEY, space);
          }
          // verify if user has active space in localstorage
          else if (activeSpaceLocalStorage) {
            const findSpace = scope.find(
              (item) => item?.id === activeSpaceLocalStorage.id
            );
            if (findSpace) {
              setActiveSpace(findSpace);
            }
          }

          // redirect to dashboard
          if (pathname.includes(AUTH_ROUTES.LOGIN)) {
            return (window.location.href = DASHBOARD_ROUTES.DASHBOARD);
          }
          return null;
        }
      } catch (error) {
        console.log({ error });
        setIsAuthenticated(true);
        setIsInitialLoading(false);
        if (pathname === AUTH_ROUTES.LOGIN) {
          return (window.location.href = AUTH_ROUTES.SIGNUP);
        }
      } finally {
        setTimeout(() => {
          setIsLoginLoading(false);
        }, 1000);
      }
    },
    [onLoginCms]
  );

  const logout = useCallback(async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.log({ error });
    } finally {
      window?.localStorage?.removeItem(SESSION_KEY);
      window?.localStorage?.removeItem(SELECT_SPACE_KEY);
      setIsAuthenticated(false);
      setIsInitialLoading(false);
    }
  }, []);

  const onChangeSpace = useCallback((space: UserBackoffice | null) => {
    window?.localStorage?.setItem(SELECT_SPACE_KEY, JSON.stringify(space));
    setActiveSpace(space);
  }, []);

  const onEditSpace = useCallback(
    async ({ spaceId, data: payload }: UpdateSpaceMutationVariables) => {
      setIsUpdateSpaceLoading(true);
      try {
        const { data } = await onUpdateSpaceCms({
          variables: {
            spaceId: spaceId,
            data: payload
          }
        });
        if (data?.updateSpace) {
          const newSpace = data.updateSpace as NonNullable<
            UserBackoffice['space']
          >;

          setActiveSpace((prev) => {
            const newState = !prev ? prev : { ...prev, space: newSpace };
            window?.localStorage?.setItem(
              SELECT_SPACE_KEY,
              JSON.stringify(newState)
            );
            return newState;
          });
          setUser((prev) => {
            return !prev
              ? prev
              : {
                  ...prev,
                  scope: prev?.scope?.map((item) => {
                    if (item?.space?.id === newSpace?.id) {
                      return {
                        ...item,
                        space: newSpace
                      };
                    }
                    return item;
                  })
                };
          });
        } else {
          throw new Error('Error updating space');
        }
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        setIsUpdateSpaceLoading(false);
      }
    },
    [onUpdateSpaceCms]
  );

  useEffect(() => {
    setShowAlert(
      [
        StatusSpaceEnum.TRIAL,
        StatusSpaceEnum.PAST_DUE,
        StatusSpaceEnum.SUSPENDED
      ].includes(activeSpace?.space?.status as StatusSpaceEnum)
    );
  }, [activeSpace]);

  const diffDaysTrial = useMemo(() => {
    return dayjs(activeSpace?.space?.trialEndAt).diff(dayjs(), 'day');
  }, [activeSpace]);

  const isSuspended = useMemo(
    () => activeSpace?.space?.status === StatusSpaceEnum.SUSPENDED,
    [activeSpace?.space]
  );

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        activeSpace: activeSpace
          ? {
              ...activeSpace,
              isSuspended
            }
          : null,
        onChangeSpace,
        onEditSpace,
        isLoginLoading,
        isAuthenticated,
        isInitialLoading,
        loadingCms: loading,
        stateAlert: {
          open: showAlert,
          diffDaysTrial,
          onClose: () => setShowAlert(false),
          type: diffDaysTrial <= 0 || isSuspended ? 'danger' : 'warning'
        },
        loadingUpdateSpace:
          isUpdateSpaceLoading || isUpdateSpaceLoadingMutation,
        onEndInitialLoading: () => {
          setIsInitialLoading(false);
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
