import dayjs from 'dayjs';
import { Button } from "@heroui/react";
import { ChevronLeft, ChevronRight } from 'lucide-react';

import Text from '@/lib/ui/text';
import { useTranslation } from '@/hooks/useTranslation';
import { FILTER_TIME_OPTIONS } from '@/app/app/sessions/common/constant/options-labels.ts';
import { useCalendar } from '@/context/calendar-provider.tsx';
import ToggleButton from '@/lib/ui/toggle-button.tsx';
import { FilterTimeEnum } from '@/__generated__/graphql.ts';

export default function HeaderSchedule() {
  const { lang, t } = useTranslation();
  const { time, setTime, onNext, onPrev, onToday, date } = useCalendar();

  return (
    <div className="flex flex-col md:flex-row justify-between items-center gap-5 mb-8">
      <ToggleButton<FilterTimeEnum>
        value={time}
        onChange={setTime}
        className="w-fit m-auto md:m-0"
        options={FILTER_TIME_OPTIONS.map((item) => {
          return {
            ...item,
            label: t(item.label)
          };
        })}
      />

      <div>
        <Text className="font-black text-sm capitalize">
          {dayjs(date).locale(lang).format('MMMM, YYYY')}
        </Text>
      </div>

      <div className="flex gap-2">
        <Button variant="ghost" isIconOnly onClick={onPrev}>
          <ChevronLeft size={20} />
        </Button>
        <Button
          isIconOnly
          variant="ghost"
          className="px-7"
          onClick={onToday}
        >
          {t('sessions.today')}
        </Button>
        <Button variant="ghost" isIconOnly onClick={onNext}>
          <ChevronRight size={20} />
        </Button>
      </div>
    </div>
  );
}
