import { Button, Card, CardBody } from '@heroui/react';
import { useAuth } from '@/context/AuthContext.tsx';
import Text from '@/lib/ui/text.tsx';
import Input from '@/lib/ui/input.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import Loading from '@/lib/ui/loading.tsx';
import { useUpdateEmailCustomer } from '@/app/app/billing/store/mutation/billing.mutation.ts';
import { toast } from 'sonner';
import useToggle from '@/hooks/useToggle.ts';
import Form, { FormItem } from 'reactivity-hook-form';
import { getApiErrorText } from '@/lib/utils.ts';

export default function EmailBilling() {
  const { t } = useTranslation();
  const { activeSpace } = useAuth();
  const [openEmail, openEmailActions] = useToggle();
  const postUpdateEmailCustomer = useUpdateEmailCustomer();

  const onUpdateEmail = async (email: string) => {
    try {
      const customerId = activeSpace?.space?.billingRef || '';
      await postUpdateEmailCustomer.mutateAsync({
        customerId: customerId,
        email
      });
    } catch (e) {
      console.error(e);
      toast.error(t('errors_message.unexpected_error'), {
        description: t('errors_message.original_error', null, {
          error: getApiErrorText(e)
        })
      });
    }
    openEmailActions.onHidden();
  };

  return (
    <Card className="w-full md:w-[50%]">
      <CardBody className="gap-2">
        <div className="flex justify-between items-center gap-2">
          <Text className="font-bold">{t('billing_email')}</Text>
          {activeSpace?.space?.billingRef && (
            <Button variant="bordered" onPress={openEmailActions.onToggle}>
              {t(openEmail ? 'cancel' : 'replace')}
            </Button>
          )}
        </div>

        <Text className="text-default-500">
          {t('plans_schema.plan_payment_email')}
        </Text>
        {postUpdateEmailCustomer?.isPending ? (
          <Loading />
        ) : !activeSpace?.space?.billingRef || !openEmail ? (
          <Input
            isDisabled
            defaultValue={activeSpace?.space?.billingEmail || ''}
            placeholder={activeSpace?.space?.billingEmail || ''}
          />
        ) : (
          <UpdateEmail onFinish={onUpdateEmail} />
        )}
      </CardBody>
    </Card>
  );
}

type FormValues = {
  email: string;
};

function UpdateEmail({ onFinish }: { onFinish(email: string): void }) {
  const { activeSpace } = useAuth();
  const { t } = useTranslation();

  return (
    <Form<FormValues>
      onSubmit={(values) => onFinish(values.email)}
      defaultValues={{
        email: activeSpace?.space?.billingEmail || ''
      }}
    >
      <FormItem<FormValues> name="email">
        <Input placeholder={activeSpace?.space?.billingEmail || ''} />
      </FormItem>

      <FormItem>
        {({ formState }) => {
          return (
            <Button
              color="primary"
              type="submit"
              isDisabled={!Object.keys(formState?.dirtyFields).length}
            >
              {t('update')}
            </Button>
          );
        }}
      </FormItem>
    </Form>
  );
}
