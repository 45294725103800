import { Smartphone } from 'lucide-react';
import { Avatar, Tooltip } from "@heroui/react";

import Text from '@/lib/ui/text.tsx';
import { TableColumn } from '@/lib/ui/table.tsx';
import { UserFragmentFragment } from '@/__generated__/graphql.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export default function useColumns(): TableColumn<UserFragmentFragment> {
  const { t } = useTranslation();

  return [
    {
      field: 'user',
      headerName: t('notifications.user'),
      renderCell({ row }) {
        return (
          <div className="flex items-center gap-2">
            <Avatar
              size="sm"
              src={row?.avatar?.url}
              name={row?.fullName || ''}
            />
            <div>
              <Text className="text-xs font-bold">{row?.fullName}</Text>
              <Text className="text-xs text-foreground/60">{row?.email}</Text>
            </div>
          </div>
        );
      }
    },
    {
      field: 'device',
      headerName: t('notifications.devices'),
      renderCell({ row }) {
        return row?.devices?.length ? (
          <div className="flex gap-2">
            <Tooltip
              content={
                <div className="flex flex-col gap-1">
                  {row?.devices.map((device) => (
                    <div key={device.id} className="flex items-center gap-2">
                      <span className="w-[5px] h-[5px] bg-foreground/80 rounded-xl" />

                      <div className="">
                        <Text>{device.device_name}</Text>
                        <Text className="text-xs">
                          <strong>{device.device_platform}</strong>
                        </Text>
                      </div>
                    </div>
                  ))}
                </div>
              }
            >
              <div className="relative flex items-center">
                <Smartphone size={18} />
              </div>
            </Tooltip>
          </div>
        ) : (
          '-'
        );
      }
    }
  ];
}
