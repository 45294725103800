import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@/context/AuthContext.tsx';
import { billingApiClient } from '@/sdks/clients/billing-client.ts';
import { BILLING_QUERY_KEY } from '@/app/app/billing/store/query/billing.query.ts';
import dayjs from 'dayjs';

export function useGetCustomer() {
  return useMutation({
    mutationKey: ['createCustomer'],
    mutationFn: async (payload: {
      id: string;
      email: string;
      name: string;
    }) => {
      return billingApiClient.customers.postCustomersCreate({
        requestBody: payload
      });
    }
  });
}

export function useUpdateEmailCustomer() {
  const { onEditSpace, activeSpace } = useAuth();

  return useMutation({
    mutationKey: ['update-email-customer'],
    mutationFn: async (payload: { customerId: string; email: string }) => {
      await billingApiClient.customers.postCustomersUpdateEmailBilling({
        customerId: payload.customerId,
        requestBody: {
          email: payload.email
        }
      });

      return onEditSpace({
        spaceId: activeSpace?.space?.id as string,
        data: {
          billingEmail: payload.email
        }
      });
    }
  });
}

export function useGetSetupIntent() {
  const { activeSpace } = useAuth();

  return useMutation({
    mutationKey: ['setupIntent', activeSpace?.space?.id],
    mutationFn: async (args: { customerId: string }) => {
      return billingApiClient.customers.postCustomersSetupIntent(args);
    }
  });
}

export function useAddPaymentMethod() {
  const { activeSpace } = useAuth();
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['add-payment-method', activeSpace?.space?.id],
    mutationFn: async (args: {
      customerId: string;
      paymentMethodId: string;
    }) => {
      return billingApiClient.customers.postCustomersPaymentMethod({
        customerId: args.customerId,
        requestBody: {
          paymentMethodId: args.paymentMethodId
        }
      });
    },
    onSuccess() {
      return client.invalidateQueries({
        queryKey: [BILLING_QUERY_KEY]
      });
    }
  });
}

export function useUpdatePaymentMethod() {
  const { activeSpace } = useAuth();
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['update-payment-method', activeSpace?.space?.id],
    mutationFn: async (args: {
      customerId: string;
      newPaymentMethodId: string;
      beforePaymentMethodId: string;
    }) => {
      return billingApiClient.customers.putCustomersPaymentMethod({
        customerId: args.customerId,
        requestBody: {
          newPaymentMethodId: args.newPaymentMethodId,
          beforePaymentMethodId: args.beforePaymentMethodId
        }
      });
    },
    onSuccess() {
      return client.invalidateQueries({
        queryKey: [BILLING_QUERY_KEY]
      });
    }
  });
}

export function useSubscribeToPlan() {
  const { activeSpace, onEditSpace } = useAuth();
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['subscribe-to-plan', activeSpace?.space?.id],
    mutationFn: async (args: {
      customerId: string;
      jogoId: string;
      priceId: string;
    }) => {
      const trialDays = dayjs(activeSpace?.space?.trialEndAt).diff(
        dayjs(),
        'days'
      );

      const responseDto =
        await billingApiClient.customers.postCustomersSubscribeToPlan({
          customerId: args.customerId,
          requestBody: {
            priceId: args.priceId,
            jogoId: args?.jogoId,
            trialDays: trialDays < 0 ? 0 : trialDays
          }
        });

      if (args?.customerId) {
        await onEditSpace({
          spaceId: activeSpace?.space?.id as string,
          data: {
            billingRef: args.customerId,
            plan: args.priceId,
            billingScheduleId: responseDto.scheduleSubscriptionId
          }
        });
      }
    },
    onSuccess() {
      return client.invalidateQueries({
        queryKey: [BILLING_QUERY_KEY]
      });
    }
  });
}

export function useRetryPayment() {
  const { activeSpace } = useAuth();
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['subscribe-to-plan', activeSpace?.space?.id],
    mutationFn: async (args: {
      paymentMethodId: string;
      invoiceId: string;
    }) => {
      return billingApiClient.invoices.postInvoicesRetry({
        invoiceId: args.invoiceId,
        requestBody: {
          paymentMethodId: args.paymentMethodId
        }
      });
    },
    onSuccess() {
      return client.invalidateQueries({
        queryKey: [BILLING_QUERY_KEY]
      });
    }
  });
}

export function useChangePlan() {
  const { activeSpace, onEditSpace } = useAuth();
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['change-plan', activeSpace?.space?.id],
    mutationFn: async (args: {
      newPriceId: string;
      currentPriceId: string;
      scheduleSubscriptionId: string;
      jogoId: string;
      customerId: string;
    }) => {
      const responseDto =
        await billingApiClient.customers.postCustomersChangePlan({
          customerId: args.customerId,
          requestBody: {
            newPriceId: args.newPriceId,
            currentPriceId: args.currentPriceId,
            scheduleSubscriptionId: args.scheduleSubscriptionId,
            jogoId: args.jogoId
          }
        });

      if (args.newPriceId) {
        await onEditSpace({
          spaceId: activeSpace?.space?.id as string,
          data: {
            plan: args.newPriceId
          }
        });
      }

      return responseDto;
    },
    onSuccess() {
      return client.invalidateQueries({
        queryKey: [BILLING_QUERY_KEY]
      });
    }
  });
}

export function useCancelSubscription() {
  const { activeSpace, onEditSpace } = useAuth();
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['cancel-subscription', activeSpace?.space?.id],
    mutationFn: async (args: { customerId: string }) => {
      await billingApiClient.customers.postCustomersCancelSubscriptions({
        customerId: args.customerId
      });

      if (args?.customerId) {
        await onEditSpace({
          spaceId: activeSpace?.space?.id as string,
          data: {
            billingScheduleId: ''
          }
        });
      }
    },
    onSuccess() {
      return client.invalidateQueries({
        queryKey: [BILLING_QUERY_KEY]
      });
    }
  });
}
