import { Eye, MoreVertical, ShieldCheck } from 'lucide-react';
import { Button, Avatar } from "@heroui/react";
import { Link } from 'react-router-dom';

import Text from '@/lib/ui/text';
import ShortId from '@/lib/ui/short-id';
import { useTranslation } from '@/hooks/useTranslation';
import { useEnabledOrDisabledCoach } from '@/app/app/coaches/store/mutation/coaches.mutation';
import { COACHES_ROUTES } from '@/app/app/coaches/common/routes.ts';
import { CoachFragmentFragment } from '@/__generated__/graphql.ts';
import { TableColumn } from '@/lib/ui/table.tsx';
import Dropdown from '@/lib/ui/dropdown.tsx';

export const useColumnSettings = (): TableColumn<CoachFragmentFragment> => {
  const { t } = useTranslation();
  const { onChangeStatusCoach, loading } = useEnabledOrDisabledCoach();

  return [
    {
      field: 'id',
      headerName: 'ID',
      renderCell({ row }) {
        return <ShortId>{row.id}</ShortId>;
      }
    },
    {
      field: 'email',
      headerName: t('form.email')
    },
    {
      field: 'avatar',
      headerName: t('form.avatar'),
      renderCell({ row }) {
        return <Avatar src={row?.avatar?.url} name={row?.fullName ?? ''} />;
      }
    },
    {
      field: 'firstName',
      headerName: t('form.first_name'),
      renderCell({ row }) {
        return <Text className="capitalize">{row.firstName}</Text>;
      }
    },
    {
      field: 'lastName',
      headerName: t('form.last_name'),
      renderCell({ row }) {
        return <Text className="capitalize">{row.lastName}</Text>;
      }
    },
    {
      field: 'status',
      headerName: t('status'),
      renderCell({ row }) {
        return (
          <Text
            className={`capitalize px-2 w-fit rounded-lg ${
              row?.status === 'active'
                ? 'bg-green-100 dark:bg-green-950'
                : 'bg-red-100 dark:bg-red-950'
            } ${
              row?.status === 'active'
                ? 'text-green-800 dark:text-green-100'
                : 'text-red-800 dark:text-red-100'
            }`}
          >
            {t(row?.status as LanguageKeys)}
          </Text>
        );
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      renderCell({ row }) {
        return (
          <Dropdown
            disabledKeys={loading ? ['edit', 'change-status'] : undefined}
            items={[
              {
                sectionName: t('actions'),
                items: [
                  {
                    key: 'view-details',
                    showDivider: true,
                    startContent: (
                      <Eye size="18px" className="text-default-500" />
                    ),
                    children: (
                      <Link to={COACHES_ROUTES.COACH_DETAILS(row?.id)}>
                        {t('coach.go_to_details')}
                      </Link>
                    )
                  },
                  {
                    key: 'disabled-enabled',
                    startContent: (
                      <ShieldCheck size="18px" className="text-default-500" />
                    ),
                    onClick() {
                      return onChangeStatusCoach(
                        row.id,
                        row.status === 'active' ? 'inactive' : 'active'
                      );
                    },
                    children: t(
                      row.status === 'active' ? 'disabled' : 'enabled'
                    )
                  }
                ]
              }
            ]}
          >
            <Button
              variant="light"
              disabled={loading}
              className="h-8 w-8 p-0 text-current"
            >
              <MoreVertical className="h-4 w-4" />
            </Button>
          </Dropdown>
        );
      }
    }
  ];
};
