import Form, { FormItem } from 'reactivity-hook-form';

import Input from '@/lib/ui/input.tsx';
import Modal from '@/lib/ui/modal.tsx';
import {
  ClassOfferedCreateInput,
  ClassOfferedFragmentFragment,
  ClassOfferedUpdateInput
} from '@/__generated__/graphql.ts';
import {
  useCreateClass,
  useUpdateClass
} from '@/app/app/settings/store/mutation/class.mutation.ts';
import { Textarea } from '@/lib/ui/textarea.tsx';
import { SingleWeekDays } from '@/lib/ui/week-days.tsx';
import { TimePicker } from '@/lib/ui/time-picker.tsx';
import Upload from '@/lib/ui/upload.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Button } from "@heroui/react";
import { Plus, Trash } from 'lucide-react';
import Alert from '@/lib/ui/alert.tsx';

const FORM_NAME = 'new-class-form';

type ScheduleValues = {
  id?: string | null;
  day: string | null;
  startTime: string | null;
  endTime: string | null;
};

type FormValues = Pick<ClassOfferedCreateInput, 'name' | 'short_summary'> & {
  image: File | string | null;
  schedules: ScheduleValues[];
};

export default function NewOrUpdateClassModal({
  item,
  open,
  onClose
}: {
  item?: ClassOfferedFragmentFragment | null;
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { onCreateClass, loading: cLoading } = useCreateClass();
  const { onUpdateClass, loading: uLoading } = useUpdateClass();

  const loading = cLoading || uLoading;

  const onSubmit = async (_payload: FormValues) => {
    if (!_payload?.schedules?.length) return null;

    const image =
      _payload.image instanceof File ? { upload: _payload.image } : undefined;

    if (item) {
      const newSchedules = _payload.schedules.filter((item) => !item.id);
      const setSchedules = _payload.schedules.filter((item) => !!item.id);

      const payload: ClassOfferedUpdateInput = {
        image,
        name: _payload.name,
        short_summary: _payload.short_summary,
        schedules: {
          set: setSchedules.map((schedule) => ({
            id: schedule.id,
            day: schedule.day,
            startTime: schedule.startTime,
            endTime: schedule.endTime
          })),
          create: newSchedules.map((schedule) => ({
            day: schedule.day,
            startTime: schedule.startTime,
            endTime: schedule.endTime
          }))
        }
      };

      await onUpdateClass(item.id, payload);
    } else {
      const payload: ClassOfferedCreateInput = {
        image,
        name: _payload.name,
        short_summary: _payload.short_summary,
        schedules: {
          create: _payload.schedules.map((schedule) => ({
            day: schedule.day,
            startTime: schedule.startTime,
            endTime: schedule.endTime
          }))
        }
      };

      await onCreateClass(payload);
      onClose();
    }
  };

  return (
    <Modal
      size="lg"
      isOpen={open}
      onClose={onClose}
      maskClosable={false}
      contentClass="max-h-[87vh]"
      title={t(item ? 'settings.update_class' : 'settings.new_class')}
      actions={[
        {
          label: t('cancel'),
          color: 'danger',
          variant: 'light',
          onClick: onClose
        },
        {
          label: t('save'),
          color: 'primary',
          form: FORM_NAME,
          type: 'submit',
          isLoading: loading
        }
      ]}
    >
      <Form<FormValues>
        id={FORM_NAME}
        key={item?.id || 'default'}
        onSubmit={onSubmit}
        defaultValues={{
          ...item,
          schedules: item?.schedules ?? [],
          image: item?.image?.url ?? null
        }}
        validations={{
          name: {
            required: t('validations.required')
          },
          short_summary: {
            required: t('validations.required')
          },
          'schedules.[number].day': {
            required: t('validations.required')
          },
          'schedules.[number].startTime': {
            required: t('validations.required')
          },
          'schedules.[number].endTime': {
            required: t('validations.required')
          }
        }}
      >
        <FormItem<FormValues>
          name="image"
          className="flex flex-row justify-center"
        >
          <Upload className="mx-auto block" />
        </FormItem>
        <FormItem<FormValues> name="name">
          <Input label={t('form.class_name')} />
        </FormItem>
        <FormItem<FormValues> name="short_summary">
          <Textarea label={t('form.short_summary')} />
        </FormItem>

        <Schedules />
      </Form>
    </Modal>
  );
}

export function Schedules() {
  const { t } = useTranslation();
  const { control, watch } = useFormContext<FormValues>();
  const { append, remove } = useFieldArray<FormValues>({
    control,
    name: 'schedules'
  });

  const schedules = watch('schedules');

  return (
    <div className="flex flex-col gap-5">
      {!schedules.length && (
        <Alert severity="warning">{t('settings.alert_added_one_schedule')}</Alert>
      )}
      {schedules.map((_item, index) => {
        return (
          <div className="flex flex-col gap-5 bg-input p-4 rounded-md relative">
            <FormItem<FormValues> name={`schedules.${index}.day`}>
              <SingleWeekDays label={t('day')} />
            </FormItem>
            <FormItem<FormValues> name={`schedules.${index}.startTime`}>
              <TimePicker label={t('form.start_time')} />
            </FormItem>
            <FormItem<FormValues> name={`schedules.${index}.endTime`}>
              <TimePicker label={t('form.end_time')} />
            </FormItem>

            <Button
              isIconOnly
              variant="flat"
              color="danger"
              startContent={<Trash size={18} />}
              className="absolute top-5 right-5"
              onClick={() => remove(index)}
            />
          </div>
        );
      })}

      <Button
        variant="flat"
        color="primary"
        startContent={<Plus size={18} />}
        onClick={() =>
          append({
            day: null,
            startTime: null,
            endTime: null
          })
        }
      >
        {t('add')}
      </Button>
    </div>
  );
}
